import styled from 'styled-components';

export const StyledRegistrationContent = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding-bottom: 60px;
    padding-right: 20px;

    @media only screen and (max-width: 767px) {
        height: auto;
        padding-right: 0;
    }
`;
