import React, { useRef, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import AnnouncementElement from '../AnnouncementElement/AnnouncementElement';

import { StyledUserPanelAnnouncements, StyledScrollWrapper } from './StyledUserPanelAnnouncements';
import { StyledText } from '../../atoms/Text/StyledText';

import { getString } from '../../../strings';
import { listAnnouncements } from '../../../logic/requests/announcements';
import CreateAnnouncement from './CreateAnnouncement';

const UserPanelAnnouncements = ({ isAdmin }) => {
    const scrollElement = useRef(null);
    const [isTrigger, setIsTrigger] = useState(false);
    const [isScrollBar, setIsScrollbar] = useState(false);
    const { data: announcements } = useQuery(['announcements'], listAnnouncements);

    useEffect(() => {
        const element = scrollElement?.current;
        if (element?.clientHeight < element?.scrollHeight) {
            setIsScrollbar(true);
        } else {
            setIsScrollbar(false);
        }
    }, [isTrigger]);

    return (
        <StyledUserPanelAnnouncements>
            <StyledText
                hasdeclaredpadding="20px 20px 30px 0"
                hasdeclaredfontsize="36px"
                hasdeclaredfontweight="700"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString('pages_userPanel_ogloszenia')}
            </StyledText>
            <StyledScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                {isAdmin && <CreateAnnouncement />}
                {announcements?.map((announcement) => (
                    <AnnouncementElement
                        key={announcement.id}
                        id={announcement.id}
                        setIsTrigger={() => setIsTrigger(!isTrigger)}
                        isRead={announcement.isRead}
                        title={announcement.title}
                        text={announcement.text}
                        date={announcement.date}
                        isAdmin={isAdmin}
                    />
                ))}
            </StyledScrollWrapper>
        </StyledUserPanelAnnouncements>
    );
};

export default UserPanelAnnouncements;
