import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledTournamentTimetableContentWrapper = styled.div`
    width: 100%;
    max-height: ${({ ismaxheight }) => (ismaxheight ? '92%' : '80%')};
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 10px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        overflow-y: visible;
        margin-top: 40px;
        padding-right: 0;
    }
`;
