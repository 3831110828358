import React from 'react';

const AcceptIcon = () => (
    <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.6803 0L21.1312 2.72126L10.8611 13.2283L7.86879 10.2803L5.20892 7.55906L0 12.8882L2.65987 15.6094L8.20127 21.2787L10.7503 24L13.4102 21.2787L26.3401 8.05039L29 5.32913L23.6803 0Z"
            fill="white"
        />
    </svg>
);

export default AcceptIcon;
