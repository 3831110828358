import React, { useState } from 'react';

import UserPanelTournamentButton from '../../molecules/UserPanelTournament/UserPanelTournamentButton';
import CompetitionElement from '../../molecules/CompetitionElement/CompetitionElement';

import {
    StyledUserPanelReqElement,
    StyledDataWrapper,
    StyledLogoWrapper,
    StyledTextContent,
    StyledContent,
    StyledButtonsWrapper,
    StyledButtonWrapper,
} from './UserPanelReqElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledResignationWrapper } from '../../../styles/sharedStyles';
import { StyledButton } from '../../atoms/Button/StyledButton';

import { getString } from '../../../strings';
import { getUTCDateString } from '../../../logic/eventtime';

import { handleResign, download } from './UserPanelReqElement.data';

const UserPanelReqElement = ({
    number,
    format,
    competition,
    date,
    trainer,
    orderId,
    status,
    remove,
}) => {
    const [resign, setResign] = useState(false);

    const buttons = (() => {
        const ret = [];

        switch (status) {
            case 'pending':
                // może jeszcze zrezygnować
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__anuluj_zamowienie')}
                        color="#E18872"
                        onClick={() => setResign((val) => !val)}
                    />
                );
                break;
            case 'in-progress':
                // nic nie może
                break;
            case 'done':
                // może zobaczyć albo pobrać
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__zobacz_rekonstrukcje')}
                        color="#4E6BAA"
                        onClick={() =>
                            window.open(
                                `/api/trainings/analysis/get-content/${orderId}`,
                                '_blank',
                                'noopener,noreferrer'
                            )
                        }
                    />
                );
                ret.push(
                    <UserPanelTournamentButton
                        name={getString('UserPanelReq__pobierz')}
                        color="#4E6BAA"
                        onClick={() =>
                            download(
                                `/api/trainings/analysis/get-content/${orderId}?download=true`,
                                `cube4fun_analysis_${orderId}.pdf`
                            )
                        }
                    />
                );

                break;
            default:
                return;
        }

        return ret;
    })();

    return (
        <StyledUserPanelReqElement>
            <StyledContent>
                <StyledText
                    hasdeclaredfontsize="32px"
                    hasdeclaredfontweight="700"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredtexttransform="uppercase"
                    as="h2"
                >
                    {getString('UserPanelReqElement__rekonstrukcja')} #{number}
                </StyledText>
                <StyledDataWrapper>
                    <StyledLogoWrapper>
                        <StyledText
                            hasdeclaredtextalign="center"
                            hasdeclaredfontsize="32px"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                        >
                            {format}
                        </StyledText>
                        <CompetitionElement competition={competition} />
                    </StyledLogoWrapper>
                    <StyledTextContent>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__data_zamowienia')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getUTCDateString(new Date(date))}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__trener')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {trainer}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__numer_zamowienia')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {orderId}
                            </StyledText>
                        </div>
                        <div>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#4E6BAA"
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('UserPanelReqElement__status')}:
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor={status === 'done' ? '#6AB95D' : '#E18872'}
                            >
                                {getString(`UserPanelReq__analysis_status__${status}`)}
                            </StyledText>
                        </div>
                    </StyledTextContent>
                </StyledDataWrapper>
            </StyledContent>
            {buttons.length === 1 ? (
                <StyledButtonWrapper>{buttons}</StyledButtonWrapper>
            ) : buttons.length > 1 ? (
                <StyledButtonsWrapper>{buttons}</StyledButtonsWrapper>
            ) : undefined}
            {resign ? (
                <StyledResignationWrapper>
                    <StyledText
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredcursor="pointer"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontsize="18px"
                        hasdeclaredfontweight="600"
                    >
                        {getString('UserPanelReq__anuluj_tresc')}
                    </StyledText>
                    <div>
                        <StyledButton
                            hasdeclaredfontweight="bold"
                            hasdeclaredfontsize="18px"
                            hasdeclaredtextalign="center"
                            hasdeclaredbgcolor="#4E6BAA"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredborderradius="25px"
                            hasdeclaredcursor="pointer"
                            onClick={() => setResign(false)}
                        >
                            {getString('userPanel_userPanelTournament_nie')}
                        </StyledButton>
                        <StyledButton
                            hasdeclaredfontweight="bold"
                            hasdeclaredfontsize="18px"
                            hasdeclaredtextalign="center"
                            hasdeclaredbgcolor="#E18872"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredborderradius="25px"
                            hasdeclaredcursor="pointer"
                            onClick={() => handleResign(orderId, remove)}
                        >
                            {getString('userPanel_userPanelTournament_tak')}
                        </StyledButton>
                    </div>
                </StyledResignationWrapper>
            ) : undefined}
        </StyledUserPanelReqElement>
    );
};

export default UserPanelReqElement;
