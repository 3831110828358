import React from "react";
import { getString } from "../../../strings";
import ScriptIconAndText from "../../molecules/ScriptContent/ScriptIconAndText";

import { StyledScriptContent } from "../../atoms/ScriptContent/StyledScriptContent";
import { StyledScriptHeading } from "../../molecules/ScriptContent/StyledScriptHeading";
import { StyledScriptHeadingLeft } from "../../atoms/ScriptContent/StyledScriptHeadingLeft";
import { StyledScriptData } from "../../atoms/ScriptContent/StyledScriptData";
import { StyledScriptDataLeftWrapper } from "../../atoms/ScriptContent/StyledScriptDataLeftWrapper";
import { StyledScriptDataRightWrapper } from "../../atoms/ScriptContent/StyledScriptDataRightWrapper";

import { StyledText } from "../../atoms/Text/StyledText";

import useWindowSize from "../../../utils/getWindowSize";

const ScriptContent = ({ activities }) => {
    const width = useWindowSize();
    const competitions = Object.keys(activities);

    return (
        <StyledScriptContent>
            <StyledScriptHeading>
                <StyledText hasdeclaredfontsize="14px" hasdeclaredfontweight="600">
                    {getString("userPanel_scriptContent_konkurencja")}
                </StyledText>
                <StyledScriptHeadingLeft>
                    <StyledText hasdeclaredfontsize="14px" hasdeclaredfontweight="600">
                        {width < 420 ? getString("userPanel_scriptContent_ukl") : getString("userPanel_scriptContent_ukladanie")}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="14px" hasdeclaredfontweight="600">
                        {width < 420 ? getString("userPanel_scriptContent_miesz") : getString("userPanel_scriptContent_mieszanie")}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="14px" hasdeclaredfontweight="600">
                        {width < 420 ? getString("userPanel_scriptContent_bieg") : getString("userPanel_scriptContent_bieganie")}
                    </StyledText>
                    <StyledText hasdeclaredfontsize="14px" hasdeclaredfontweight="600">
                        {width < 420 ? getString("userPanel_scriptContent_sedz") : getString("userPanel_scriptContent_sedziowanie")}
                    </StyledText>
                </StyledScriptHeadingLeft>
            </StyledScriptHeading>
                {
                    competitions.map(
                        (competition, idx) => (
                            <StyledScriptData key={idx}>
                                <StyledScriptDataLeftWrapper>
                                    <ScriptIconAndText iconName={competition} />
                                </StyledScriptDataLeftWrapper>
                                <StyledScriptDataRightWrapper>
                                    <StyledText hasdeclaredfontsize="22px" hasdeclaredfontweight="600">
                                        {
                                            // układanie - 'competitor'
                                            activities[competition]["competitor"]?.join(", ")
                                        }
                                    </StyledText>
                                    <StyledText hasdeclaredfontsize="22px" hasdeclaredfontweight="600">
                                        {
                                            // mieszanie - 'staff-scrambler'
                                            activities[competition]["staff-scrambler"]?.join(", ")
                                        }
                                    </StyledText>
                                    <StyledText hasdeclaredfontsize="22px" hasdeclaredfontweight="600">
                                        {
                                            // bieganie - 'staff-runner'
                                            activities[competition]["staff-runner"]?.join(", ")
                                        }
                                    </StyledText>
                                    <StyledText hasdeclaredfontsize="22px" hasdeclaredfontweight="600">
                                        {
                                            // sędziowanie - 'staff-judge'
                                            activities[competition]["staff-judge"]?.join(", ")
                                        }
                                    </StyledText>
                                </StyledScriptDataRightWrapper>
                            </StyledScriptData>
                        )
                    )
                }
        </StyledScriptContent>
    );
};

export default ScriptContent;
