import styled from 'styled-components';
import { StyledLoaderWrapper } from '../../../styles/sharedStyles';

export const StyledTextWrapper = styled(StyledLoaderWrapper)`
    > h2 {
        margin-top: -20%;
    }

    @media only screen and (max-width: 926px) {
        > h2 {
            margin-top: 0;
        }
    }
`;
