import React, { useCallback, useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { StyledTournamentTimetableContent } from "../../atoms/Tournament/StyledTournamentTimetableContent";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButton } from "../../atoms/Button/StyledButton";
import { StyledTournamentTimetableContentWrapper } from "../../atoms/Tournament/StyledTournamentTimetableContentWrapper";
import { StyledButtonWrapper } from "../../atoms/Tournament/StyledButtonWrapper";
import { StyledButtonSvg } from "../../atoms/Login/StyledLogin";
import { StyledTimetable } from "../../atoms/Tournament/StyledTimetable";

import { StyledHeadingWrapper } from "../../atoms/Tournament/StyledHeadingWrapper";
import { StyledThreeElementRow } from "../../atoms/Tournament/StyledThreeElementRow";
import { StyledTitleWrapper } from "../../atoms/Tournament/StyledTitleWrapper";
import { StyledDataRowWrapper } from "../../atoms/Tournament/StyledDataRowWrapper";
import { StyledTimeCell } from "../../atoms/Tournament/StyledTimeCell";
import { StyledMaxWidthCell } from "../../atoms/Tournament/StyledMaxWidthCell";
import { StyledWhiteRow } from "../../atoms/Tournament/StyledWhiteRow";
import { StyledWhiteRowCell } from "../../atoms/Tournament/StyledWhiteRowCell";
import { StyledTitleCell } from "../../atoms/Tournament/StyledTitleCell";

import useWindowSize from "../../../utils/getWindowSize";
import { getString, getTimetableString } from "../../../strings";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const TournamentTimetableContent = ({ schedule }) => {
    const width = useWindowSize();
    const { scrollElement: timetableWrapper, isScrollBar } = useScrollBar();

    const timetable = useMemo(() => {
        let ret = [],
            tmp = [];
        for (let row of schedule.map((row) => row.filter((str) => str.length))) {
            if (row.length === 1) {
                // SOBOTA 26.02.2022
                if (tmp.length) {
                    ret.push(tmp);
                    tmp = [];
                }
            }
            tmp.push(row);
        }
        ret.push(tmp);

        // tłumaczenie
        ret = ret.map(
            day => (
                day.map(
                    row => (
                        row.map(
                            col => (
                                getTimetableString(col)
                            )
                        )
                    )
                )
            )
        )

        return ret;
    }, []);

    const [day, setDay] = useState(0);
    const [animateRight, setAnimateRight] = useState(false);
    const getCurrentDay = useCallback(
        () =>
            // jeden z niewielu sposóbów na głębokie kopiowanie; poniżej używamy 'shift', który *usuwa* komórki
            JSON.parse(JSON.stringify(timetable[day])),
        [timetable, day]
    );
    const setCurrentDay = useCallback(
        (day) => {
            if (day > -1 && day < timetable.length) setDay(day);
        },
        [setDay, timetable]
    );

    const handlePrev = () => {
        setAnimateRight(true);
        setCurrentDay(day - 1);
        width > 767
            ? timetableWrapper.current.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
              })
            : timetableWrapper.current.scrollIntoView({ behavior: "smooth" });
    };
    const handleNext = () => {
        setAnimateRight(false);
        setCurrentDay(day + 1);
        width > 767
            ? timetableWrapper.current.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
              })
            : timetableWrapper.current.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <StyledTournamentTimetableContent>
            <StyledTournamentTimetableContentWrapper ref={timetableWrapper} isscrollbar={isScrollBar} ismaxheight={timetable.length > 1 ? false : true}>
                <AnimatePresence
                    exitBeforeEnter
                    initial={false}
                >
                    <StyledTimetable
                        initial={
                            (width < 768) ? (
                                { x: 0, opacity: 1 }
                            ) : (
                                { x: 100 * (animateRight ? -1 : 1), opacity: 0 }
                            )
                        }
                        animate={{ x: 0, opacity: 1 }}
                        key={day}
                    >
                        {(() => {
                            let header = false;
                            return getCurrentDay().map((row) => {
                                switch (row.length) {
                                    case 1:
                                        // SOBOTA 26.02.2022
                                        header = true;
                                        return (
                                            <StyledHeadingWrapper>
                                                <StyledText
                                                    hasdeclaredfontsize="44px"
                                                    hasdeclaredfontweight="bold"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredpadding="8px 0"
                                                >
                                                    {row[0]}
                                                </StyledText>
                                            </StyledHeadingWrapper>
                                        );
                                    case 3:
                                        // 09:00 | 09:15 | Rejestracja
                                        return (
                                            <StyledThreeElementRow>
                                                <StyledTimeCell isafterelement={true}>
                                                    <StyledText
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontsize="15px"
                                                        hasdeclaredfontcolor="#fff"
                                                    >
                                                        {row[0]}
                                                    </StyledText>
                                                </StyledTimeCell>
                                                <StyledTimeCell>
                                                    <StyledText
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontsize="15px"
                                                        hasdeclaredfontcolor="#fff"
                                                    >
                                                        {row[1]}
                                                    </StyledText>
                                                </StyledTimeCell>
                                                <StyledMaxWidthCell>
                                                    <StyledText
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontsize="15px"
                                                        hasdeclaredfontcolor="#fff"
                                                    >
                                                        {row[2]}
                                                    </StyledText>
                                                </StyledMaxWidthCell>
                                            </StyledThreeElementRow>
                                        );
                                    default:
                                        if (header) {
                                            // Start | Koniec | Konkurencja | Runda | Format | Cut-off | Limit czasu | Przechodzi
                                            header = false;
                                            return (
                                                <StyledTitleWrapper>
                                                    {row.map((cell) => (
                                                        <StyledTitleCell>
                                                            <StyledText
                                                                hasdeclaredfontsize="14px"
                                                                hasdeclaredtextalign="center"
                                                                hasdeclaredfontweight="600"
                                                            >
                                                                {cell}
                                                            </StyledText>
                                                        </StyledTitleCell>
                                                    ))}
                                                </StyledTitleWrapper>
                                            );
                                        } else {
                                            // 10:00 | 11:20 | Kostka 2x2x2 | I Runda | Avg 5 | - | 1 min | 16
                                            return (
                                                <StyledDataRowWrapper>
                                                    <StyledTimeCell isafterelement={true}>
                                                        <StyledText
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredfontsize="15px"
                                                            hasdeclaredfontcolor="#fff"
                                                        >
                                                            {row.shift()}
                                                        </StyledText>
                                                    </StyledTimeCell>
                                                    <StyledTimeCell>
                                                        <StyledText
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredfontsize="15px"
                                                            hasdeclaredfontcolor="#fff"
                                                        >
                                                            {row.shift()}
                                                        </StyledText>
                                                    </StyledTimeCell>
                                                    <StyledWhiteRow
                                                        hasdeclaredbgcolor={
                                                            [
                                                                "444bf",
                                                                "555bf",
                                                                "333fm",
                                                                "333mbf",
                                                            ]
                                                            .map(
                                                                (comp) => (getString(`const_competitions_${comp}`))
                                                            )
                                                            .includes(row[0])
                                                            ? "#FFEC4C"
                                                            : undefined
                                                        }
                                                    >
                                                        {row.map((cell) => (
                                                            <StyledWhiteRowCell>
                                                                <StyledText
                                                                    hasdeclaredfontweight="600"
                                                                    hasdeclaredtextalign="center"
                                                                    hasdeclaredfontsize="15px"
                                                                >
                                                                    {cell}
                                                                </StyledText>
                                                            </StyledWhiteRowCell>
                                                        ))}
                                                    </StyledWhiteRow>
                                                </StyledDataRowWrapper>
                                            );
                                        }
                                }
                            });
                        })()}
                    </StyledTimetable>
                </AnimatePresence>
            </StyledTournamentTimetableContentWrapper>
            {
                timetable.length > 1 ? (
                    <StyledButtonWrapper>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#EE745C"
                            hasdeclaredpadding="8px 18px"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor={day > 0 ? "pointer" : "default"}
                            hasdeclaredwidth="80px"
                            hasdeclaredheight="60px"
                            hasdeclaredborderradius="25px"
                            whileTap={day > 0 ? { scale: 0.9 } : { scale: 1 }}
                            onClick={day > 0 ? handlePrev : null}
                        >
                            <StyledButtonSvg isrotate={true} />
                        </StyledButton>
                        <StyledButton
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredbgcolor="#6786be"
                            hasdeclaredpadding="8px 18px"
                            hasdeclaredtextalign="center"
                            hasdeclaredcursor={day + 1 === timetable.length ? "default" : "pointer"}
                            hasdeclaredwidth="80px"
                            hasdeclaredheight="60px"
                            hasdeclaredborderradius="25px"
                            whileTap={day + 1 === timetable.length ? { scale: 1.0 } : { scale: 0.9 }}
                            onClick={day + 1 === timetable.length ? null : handleNext}
                        >
                            <StyledButtonSvg />
                        </StyledButton>
                    </StyledButtonWrapper>
                ) : (null)
            }
        </StyledTournamentTimetableContent>
    );
};

export default TournamentTimetableContent;
