import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { getString } from '../../../strings';
import SeriesList from '../../molecules/Series/SeriesList';

import { StyledSeriesMenu } from '../../atoms/Series/StyledSeriesMenu';
import { StyledSeriesMenuWrapper } from '../../atoms/Series/StyledSeriesMenuWrapper';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledChooseTournamentWrapper } from '../../atoms/Series/StyledChooseTournamentWrapper';
import { StyledSeriesMenuMobile } from '../../atoms/Series/StyledSeriesMenuMobile';

import useWindowSize from '../../../utils/getWindowSize';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const SeriesMenu = ({ series, loadSeries, hasMore, activeResults, setActiveResults }) => {
    const width = useWindowSize();
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledSeriesMenu ismobile={width > 767 ? false : true}>
            <StyledText
                hasdeclaredfontsize="40px"
                hasdeclaredfontweight="700"
                hasdeclaredpadding="180px 20px 20px"
                hasdeclaredtextalign="center"
                hasdeclaredtexttransform="uppercase"
            >
                {getString('series_seriesMenu_serie')}
            </StyledText>
            {width > 767 ? (
                <StyledSeriesMenuWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                    <InfiniteScroll loadMore={loadSeries} hasMore={hasMore} initialLoad={false}>
                        {series.map((serie, idx) => (
                            <SeriesList
                                key={idx}
                                series={serie}
                                isMobile={true}
                                activeResults={activeResults}
                                setActiveResults={setActiveResults}
                            />
                        ))}
                    </InfiniteScroll>
                </StyledSeriesMenuWrapper>
            ) : (
                <StyledChooseTournamentWrapper>
                    <StyledSeriesMenuMobile>
                        <StyledSeriesMenuWrapper>
                            <InfiniteScroll
                                loadMore={loadSeries}
                                hasMore={hasMore}
                                initialLoad={false}
                            >
                                {series.map((serie, idx) => (
                                    <SeriesList
                                        key={idx}
                                        series={serie}
                                        isMobile={true}
                                        activeResults={activeResults}
                                        setActiveResults={setActiveResults}
                                    />
                                ))}
                            </InfiniteScroll>
                        </StyledSeriesMenuWrapper>
                    </StyledSeriesMenuMobile>
                </StyledChooseTournamentWrapper>
            )}
        </StyledSeriesMenu>
    );
};

export default SeriesMenu;
