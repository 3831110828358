import styled, { css } from 'styled-components';

export const scrollBlueStyles = css`
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? 'rgba(103, 134, 190, 0.3)' : 'transparent'};
    }

    &::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
`;

export const scrollRedStyles = css`
    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${({ isscrollbar }) =>
            isscrollbar ? 'rgba(238, 116, 92, 0.3);' : 'transparent'};
    }

    &::-webkit-scrollbar-thumb {
        background: #ee745c;
        border-radius: 6px;
    }
`;

export const StyledAboutUsElement = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-bottom: 40px;

    @media only screen and (max-width: 400px) {
        h2 {
            font-size: 30px;
        }
    }
`;

export const StyledFlag = styled.div`
    width: 30px;
    height: 17px;

    margin-left: ${({ $hasDeclaredMarginLeft }) =>
        $hasDeclaredMarginLeft ? $hasDeclaredMarginLeft : null};

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        border: 1px solid #000;
    }
`;

export const StyledEditInputElement = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '25%')};
    padding: 0 4px;
    display: ${({ $isFlex }) => ($isFlex ? 'flex' : 'block')};
    align-items: center;

    > input,
    select {
        text-align: center;
        width: 100%;
        height: 30px;
        font-size: 16px;
        border-radius: 25px;
        border: 3px solid ${({ error }) => (error ? '#F74017' : '#6786BE')};
        padding: 0 12px;
        font-family: 'Poppins';
        outline: none;
        background: #fff;
        text-align: center;

        > option {
            font-size: 16px;
            padding: 0 12px;
            font-family: 'Poppins';
            text-align: center;
        }
    }
`;

export const StyledLoaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
    padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? hasdeclaredpadding : '0')};
`;

export const StyledUserPanelTournamentWrapper = styled(({ loading, ...props }) => (
    <div {...props} />
))`
    width: 100%;
    height: ${({ loading }) => (loading ? '100%' : 'auto')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media only screen and (max-width: 926px) {
        margin-top: 70px;
    }
`;

export const StyledGlobalHeadingWrapper = styled.div`
    margin: 20px 0 0;

    @media only screen and (max-width: 926px) {
        margin-top: 40px;
        padding: 0 20px;

        > h2 {
            font-size: 30px;
        }
    }
`;

export const StyledGlobalScrollWrapper = styled.div`
    width: 100%;
    margin-top: 8px;
    overflow-y: scroll;
    height: ${({ $hasDeclaredHeight }) =>
        $hasDeclaredHeight ? $hasDeclaredHeight : 'calc(100% - 104px)'};
    padding-right: 15px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 926px) {
        padding: 0 20px;
        overflow-y: visible;
        height: auto;
    }
`;

export const StyledUserPanelStudentsMenu = styled.div`
    width: 100%;

    > button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        > i {
            position: absolute;
            right: 20px;
        }
    }
`;

export const StyledHeading = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 43px;
    cursor: pointer;

    > p {
        width: calc(100% - 50px);
    }

    * {
        cursor: pointer;
        user-select: none;
    }

    @media only screen and (max-width: 425px) {
        > p {
            font-size: 18px;
        }
    }
`;

export const StyledArrow = styled.i`
    border: solid ${({ blackcolor }) => (blackcolor ? '#000' : '#fff')};
    border-width: 0 4px 4px 0;
    display: inline-block;
    cursor: pointer;
    padding: 6px;
    transform: rotate(135deg);
    transform: ${({ isopen }) => (isopen ? 'rotate(45deg) translate(-5px)' : 'rotate(135deg)')};
    transition: transform 250ms;
`;

export const StyledClosePayElement = styled.button`
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ee745c;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
`;

export const StyledResignationWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    > div {
        display: flex;
        justify-content: space-between;
        width: 40%;
        align-items: center;
        margin-top: 20px;

        > button {
            height: 50px;
            width: 120px;

            &:last-child {
                margin-left: 12px;
            }
        }
    }
`;

export const userPanelElementStyles = css`
    width: ${({ parentIsSelecting }) => (parentIsSelecting ? 'calc(100% - 63px)' : '100%')};
    margin-top: ${({ isfirstchild }) => (isfirstchild ? '0px' : '20px')};
    padding-bottom: ${({ isopen }) => (isopen ? '20px' : '0')};
    border-radius: 25px 9px 9px 25px;
    min-height: 43px;
    border: 4px solid
        ${({ hasdeclaredbordercolor }) => (hasdeclaredbordercolor ? '#ee745c' : '#6786BE')};
    background-color: #fff;
    box-sizing: border-box;
`;

export const StyledHeader = styled.div`
    width: ${({ $isCheckbox, $isCheckboxMinusWidth }) =>
        $isCheckbox
            ? `calc(100% - ${$isCheckboxMinusWidth ? $isCheckboxMinusWidth : '65'}px)`
            : '100%'};
    overflow-x: scroll;
    align-self: ${({ hasalignself }) => (hasalignself ? hasalignself : 'flex-end')};

    ${scrollBlueStyles};

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: ${({ $secondDivMinWidth }) =>
            $secondDivMinWidth ? `${$secondDivMinWidth}px` : '700px'};
    }
`;

export const StyledHeaderElement = styled.div`
    width: ${({ simulatecheckbox, $hasWidth }) =>
        simulatecheckbox ? '41px' : $hasWidth ? $hasWidth : '20%'};
    padding: 0 4px;
`;

export const StyledDataWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: ${({ summarydata }) => (summarydata ? '5px' : '0')};
`;

export const StyledPreferencesWrapper = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        &:nth-child(2) {
            padding: 0 22px;
            color: #fff;
            border-radius: 20px;
            background-color: ${({ $isyes }) => ($isyes ? '#ee745c' : '#6786be')};
            cursor: pointer;
            user-select: none;
        }
    }

    @media only screen and (max-width: 528px) {
        p {
            font-size: 16px;
        }
    }
`;

export const StyledSelect = styled.select`
    border: 3px solid ${({ error }) => (error ? '#F74017' : '#6786BE')};
    width: 80%;
    height: 40px;
    border-radius: 25px;
    font-size: 16px;
    padding: 0 12px;
    font-family: 'Poppins';
    outline: none;
    transition: outline 250ms;
    cursor: pointer;
    background: #fff;
    text-align: center;

    > option {
        font-size: 16px;
        padding: 0 12px;
        font-family: 'Poppins';
    }

    @media only screen and (max-width: 926px) {
        width: 100%;
    }
`;

export const StyledSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

export const StyledInputWrapper = styled.div`
    width: ${({ hasdeclaredwidth }) => (hasdeclaredwidth ? hasdeclaredwidth : '50%')};
    display: ${({ $isCenter }) => ($isCenter ? 'flex' : 'block')};
    align-items: ${({ $isCenter }) => ($isCenter ? 'center' : 'flex-start')};
    justify-content: ${({ $isCenter }) => ($isCenter ? 'center' : 'flex-start')};

    input {
        border: 3px solid ${({ error }) => (error ? '#F74017' : '#6786be')};
        width: 100%;
        height: 40px;
        border-radius: 25px;
        font-size: 16px;
        padding: 0 12px;
        font-family: 'Poppins';
        outline: none;
        margin-bottom: ${({ $withoutInputMarginBottom }) =>
            $withoutInputMarginBottom ? null : '8px'};
        transition: outline 250ms;
        ${({ disabled }) => (!disabled ? 'cursor: pointer;' : '')}
        background: #fff;
        text-align: center;
    }

    select {
        border: 3px solid ${({ error }) => (error ? '#F74017' : '#6786be')};
        width: 100%;
        height: 40px;
        border-radius: 25px;
        font-size: 16px;
        padding: 0 12px;
        font-family: 'Poppins';
        outline: none;
        margin-bottom: 8px;
        transition: outline 250ms;
        cursor: pointer;
        background: #fff;
        text-align: center;

        > option {
            font-size: 16px;
            padding: 0 12px;
            font-family: 'Poppins';
            text-align: center;
        }
    }

    > h4 {
        display: ${({ error }) => (error ? 'block' : 'none')};
    }

    @media only screen and (max-width: 1072px) {
        label {
            p {
                font-size: 14px;
            }
        }
    }

    @media only screen and (max-width: 926px) {
        label {
            p {
                font-size: 20px;
            }
        }
    }

    textarea {
        border: 3px solid ${({ error }) => (error ? '#F74017' : '#6786be')};
        width: 100%;
        height: 200px;
        border-radius: 25px;
        font-size: 16px;
        padding: 12px;
        font-family: 'Poppins';
        outline: none;
        margin-bottom: 8px;
        transition: outline 250ms;
        background: #fff;
        resize: none;
    }

    @media only screen and (max-width: 600px) {
        width: ${({ $switchLang }) => ($switchLang ? null : '100%')};
    }
`;
