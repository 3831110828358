import styled from 'styled-components';

export const StyledLoadingTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;

    @media only screen and (max-width: 1204px) {
        width: 30%;
    }
`;
