import React from 'react';
import { StyledSmallLoader } from '../../atoms/Loader/StyledSmallLoader';

const SmallLoader = ({ declaredWidth, declaredHeight }) => {
    return (
        <StyledSmallLoader hasdeclaredwidth={declaredWidth} hasdeclaredheight={declaredHeight} />
    );
};

export default SmallLoader;
