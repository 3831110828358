import styled from 'styled-components';

export const StyledPreferencesItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 50px;
    align-items: center;
    border-radius: 25px;
    border: 4px solid
        ${({ hasdeclaredbordercolor }) => (hasdeclaredbordercolor ? '#ee745c' : '#6786BE')};
    padding: 0 10px;
    gap: 20px;

    @media only screen and (max-width: 926px) {
        flex-direction: column;
        gap: 2px;
        padding: 5px;

        > p {
            padding: 4px 0;
            span {
                display: block;
            }
        }
    }
`;
