import React, { useState, useCallback } from 'react';

import { StyledUserPanelPreferencesElement } from '../../atoms/UserPanelPreferences/StyledUserPanelPreferencesElement';
import { StyledHeading, StyledArrow, StyledClosePayElement } from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledPreferencesElements } from '../../atoms/UserPanelPreferences/StyledPreferencesElements';
import { StyledPreferencesItem } from '../../atoms/UserPanelPreferences/StyledPreferencesItem';

import { getString } from '../../../strings';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import { deleteStudentPreference } from '../../../logic/requests/students';
import { cebulaCompare } from '../../../logic/arrays';

import { StyledPreferencesWrapper } from '../../../styles/sharedStyles';

const UserPanelPreferencesElement = ({
    trainer,
    loadStudents,
    trigger,
    isFirstChild,
    themeColor,
    preferences,
    hasDelete,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => {
        trigger();
        setIsOpen((isOpen) => !isOpen);
    }, [trigger, setIsOpen]);

    const handleDelete = useCallback(
        (student) => {
            if ([student, trainer].some(({ id }) => isNaN(id))) {
                return;
            }

            if (
                !window.confirm(
                    getString('UserPanelPreferences__delete__confirm_1') +
                        ' ' +
                        `${trainer.lastname} ${trainer.firstname}` +
                        ' ' +
                        getString('UserPanelPreferences__delete__confirm_2') +
                        ' ' +
                        `${student?.lastname} ${student?.firstname}` +
                        '?'
                )
            )
                return;

            deleteStudentPreference(student.id, trainer.id)
                .then((res) => {
                    loadStudents?.();
                })
                .catch((err) => {
                    console.error('handleDelete', err);
                });
        },
        [trainer, loadStudents]
    );

    return (
        <StyledUserPanelPreferencesElement isopen={isOpen} isfirstchild={isFirstChild}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor={themeColor ? themeColor : '#6786be'}
                >
                    {trainer.firstname} {trainer.lastname}
                </StyledText>
                <StyledHeadingCheckbox hasdeclaredbgcolor={themeColor}>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledPreferencesElements>
                    {Object.values(preferences)
                        .sort((a, b) => {
                            const value = a.value - b.value;
                            if (value) return value;

                            const lastname = cebulaCompare(a.student.lastname, b.student.lastname);
                            if (lastname) return lastname;

                            const firstname = cebulaCompare(
                                a.student.firstname,
                                b.student.firstname
                            );
                            if (firstname) return firstname;

                            return 0;
                        })
                        .map((preference) => {
                            const isForbidden = preference.value === -1;
                            return (
                                <StyledPreferencesWrapper>
                                    <StyledPreferencesItem hasdeclaredbordercolor={isForbidden}>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredpadding="8px 0 8px 0"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredfontcolor="#6786be"
                                            hasdeclaredlineheight="1.4em"
                                        >
                                            {getString(
                                                'Trainings_trainings_userPreferences_student'
                                            )}
                                            :{' '}
                                            <span style={{ color: '#000' }}>
                                                {preference.student.name}
                                            </span>
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredpadding="8px 0 8px 0"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredfontcolor="#6786be"
                                            hasdeclaredlineheight="1.4em"
                                        >
                                            {getString(
                                                'Trainings_trainings_userPreferences_preferencje_type'
                                            )}
                                            :{' '}
                                            <span style={{ color: '#000' }}>
                                                {getString(
                                                    `Trainings_trainings_userPreferences_${
                                                        isForbidden ? 'Forbidden' : 'Preferred'
                                                    }`
                                                )}
                                            </span>
                                        </StyledText>
                                    </StyledPreferencesItem>
                                    {hasDelete ? (
                                        <StyledClosePayElement
                                            onClick={() => handleDelete(preference.student)}
                                        >
                                            <CloseIcon />
                                        </StyledClosePayElement>
                                    ) : undefined}
                                </StyledPreferencesWrapper>
                            );
                        })}
                </StyledPreferencesElements>
            ) : null}
        </StyledUserPanelPreferencesElement>
    );
};

export default UserPanelPreferencesElement;
