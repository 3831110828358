import React from 'react';

const PencilIcon = () => (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.2916 0L17.743 3.54859L24.8401 10.6458L28.3887 7.09719L21.2916 0ZM14.1944 7.09719L0 21.2916V28.3887H7.09719L21.2916 14.1944L14.1944 7.09719Z"
            fill="white"
        />
    </svg>
);

export default PencilIcon;
