import styled from 'styled-components';

import { scrollStyles } from '../../atoms/UserPanelGlobalStyles/scrollStyles';

export const StyledEmployeeTableDataElement = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > div {
        &:first-child {
            ${scrollStyles}
        }
    }
`;
