import React, { useMemo, useState, useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Decimal from 'decimal.js';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledPay } from '../../atoms/UserPanelTrainerPay/StyledPay';
import { StyledElement } from '../../atoms/UserPanelTrainerPay/StyledElement';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';
import { StyledPayHour } from '../../atoms/PayElement/StyledPayHour';
import { StyledEditPay } from './PayHour.styles';
import { StyledEditInputElement, StyledClosePayElement } from '../../../styles/sharedStyles';

import { getDateString } from '../../../logic/eventtime';
import { getLang, getString } from '../../../strings';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import { deleteHours, editHours } from '../../../logic/requests/students';
import PaymentsPopup from '../Payments/PaymentsPopup';

const handleSubmit = (hour, data, students, succFunc) => {
    if (!Object.keys(data).length) return succFunc();

    if (data.hours) {
        const hours = new Decimal(data.hours);

        if (!hours.isInteger()) {
            if (hours.eq('0.5')) {
                data.half = 1;
                data.hours = 0;
            } else {
                return;
            }
        } else {
            data.half = 0;
        }
    }

    if (data.student) data.student = parseInt(data.student);

    const onSuccess = (backend_data) =>
        succFunc?.({
            ...hour,
            ...data,
            student:
                students.find((student) => student.id === parseInt(data.student)) ?? hour.student,
            ...(backend_data ?? {}),
        });

    editHours(hour.id, data)
        .then((res) => {
            onSuccess(res);
        })
        .catch((err) => {
            console.error('handleSubmit', err);
        });
};

const handleDelete = (id, onSuccess, confirmPopup, setConfirmPopup) => {
    if (!confirmPopup) setConfirmPopup(true);

    if (confirmPopup)
        deleteHours(id)
            .then(() => {
                onSuccess?.();
                setConfirmPopup(false);
            })
            .catch((err) => {
                setConfirmPopup(false);
                console.error('handleDelete', err);
            });
};

const PayHour = ({ hour, students, subjects, reloadHours }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const price = useMemo(() => new Decimal(hour.price), [hour]);

    const name = useMemo(() => `${hour.student.firstname} ${hour.student.lastname}`, [hour]);
    const date = useMemo(() => getDateString(new Date(hour.date)), [hour]);
    const hours = useMemo(() => (hour.half ? '0.5' : hour.hours), [hour]);

    const inputRefs = {
        student: useRef(),
        date: useRef(),
        hours: useRef(),
        subject: useRef(),
    };

    const initialValues = useMemo(
        () => ({
            student: String(hour.student.id),
            date: new Date(hour.date).toISOString().split('T')[0],
            hours,
            subject: hour.subject,
        }),
        [hour, hours]
    );

    return (
        <>
            {confirmPopup ? (
                <PaymentsPopup
                    title={getString('pay_hour_czy_na_pewno')}
                    onClose={() => setConfirmPopup(false)}
                    cancelTag={getString('addition_data_element_usun')}
                    onCancel={() =>
                        handleDelete(hour.id, reloadHours, confirmPopup, setConfirmPopup)
                    }
                />
            ) : null}
            <StyledPayHour>
                <ScrollSyncPane>
                    <div>
                        {!isEdit ? (
                            <StyledPay>
                                <StyledElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredtextalign="center"
                                    >
                                        {name}
                                    </StyledText>
                                </StyledElement>
                                <StyledElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredtextalign="center"
                                    >
                                        {date}
                                    </StyledText>
                                </StyledElement>
                                <StyledElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredtextalign="center"
                                    >
                                        {hours}
                                    </StyledText>
                                </StyledElement>
                                <StyledElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {
                                            subjects.find(
                                                (el) => el.description && el.id === hour.subject
                                            ).description[getLang()]
                                        }
                                    </StyledText>
                                </StyledElement>
                                <StyledElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {price.dp() > 0 ? price.toFixed(2) : price.toString()} PLN
                                    </StyledText>
                                </StyledElement>
                            </StyledPay>
                        ) : (
                            <StyledEditPay>
                                <StyledEditInputElement $hasDeclaredWidth="20%">
                                    <select
                                        ref={inputRefs.student}
                                        defaultValue={initialValues.student}
                                    >
                                        {students.map((student) => (
                                            <option
                                                key={student.id}
                                                value={student.id}
                                            >{`${student.lastname} ${student.firstname}`}</option>
                                        ))}
                                    </select>
                                </StyledEditInputElement>
                                <StyledEditInputElement $hasDeclaredWidth="20%">
                                    <input
                                        type="date"
                                        ref={inputRefs.date}
                                        defaultValue={initialValues.date}
                                    />
                                </StyledEditInputElement>
                                <StyledEditInputElement $hasDeclaredWidth="20%">
                                    <input
                                        type="number"
                                        ref={inputRefs.hours}
                                        defaultValue={initialValues.hours}
                                    />
                                </StyledEditInputElement>
                                <StyledEditInputElement $hasDeclaredWidth="20%">
                                    <select
                                        ref={inputRefs.subject}
                                        defaultValue={initialValues.subject}
                                    >
                                        {subjects
                                            .filter((el) => el.description)
                                            .map((subject) => (
                                                <option
                                                    key={subject.description[getLang()]}
                                                    value={subject.id}
                                                >
                                                    {subject.description[getLang()]}
                                                </option>
                                            ))}
                                    </select>
                                </StyledEditInputElement>
                                <StyledEditInputElement $hasDeclaredWidth="20%">
                                    <StyledText
                                        hasdeclaredfontsize="16px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                    >
                                        {price.dp() > 0 ? price.toFixed(2) : price.toString()} PLN
                                    </StyledText>
                                </StyledEditInputElement>
                            </StyledEditPay>
                        )}
                    </div>
                </ScrollSyncPane>
                <StyledSavePayElement
                    isedit={isEdit}
                    onClick={
                        isEdit
                            ? () =>
                                  handleSubmit(
                                      hour,
                                      Object.fromEntries(
                                          Object.entries(inputRefs)
                                              .map(([key, value]) => [key, value?.current?.value])
                                              .filter(
                                                  ([key, value]) => initialValues[key] !== value
                                              )
                                      ),
                                      students,
                                      (data) => {
                                          setIsEdit(false);
                                          if (data) reloadHours();
                                      }
                                  )
                            : () => setIsEdit(true)
                    }
                >
                    {!isEdit ? <PencilIcon /> : <AcceptIcon />}
                </StyledSavePayElement>
                <StyledClosePayElement
                    onClick={
                        isEdit
                            ? () => setIsEdit(false)
                            : () =>
                                  handleDelete(hour.id, reloadHours, confirmPopup, setConfirmPopup)
                    }
                >
                    <CloseIcon />
                </StyledClosePayElement>
            </StyledPayHour>
        </>
    );
};

export default PayHour;
