import React from 'react';
import { Helmet } from 'react-helmet';
import { getLang } from '../../../strings';

const HelmetTemplate = ({ title, desc, ogTitle, url }) => {
    const imageUrl = 'https://cube4fun.pl/api/images/seo/ogimage_8.png';

    return (
        <Helmet htmlAttributes={{ lang: getLang() }}>
            <meta charSet="utf-8" />
            <title>{title}</title>

            {/* Open Graph meta tags */}
            <meta property="og:title" content={ogTitle || title} />
            <meta property="og:description" content={desc} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:url" content={url || window.location.href} />
            <meta property="og:type" content="website" />

            {/* Twitter meta tags */}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={desc} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={url || window.location.href} />

            {/* Other meta tags */}
            <meta name="description" content={desc} />
            <meta name="robots" content="index, follow" />
        </Helmet>
    );
};

export default HelmetTemplate;
