import React from 'react';

import { StyledCube444 } from '../../atoms/RegisterCube/StyledCube444';
import { StyledCubeMinx } from '../../atoms/RegisterCube/StyledCubeMinx';
import { StyledCubeSkewb } from '../../atoms/RegisterCube/StyledCubeSkewb';
import { StyledCubeSq1 } from '../../atoms/RegisterCube/StyledCubeSq1';

import { StyledRegisterCube } from '../../atoms/RegisterCube/StyledRegisterCube';

const RegisterCube = ({ isContactPage }) => {
    return (
        <StyledRegisterCube iscontactpage={isContactPage}>
            <StyledCube444 iscontactpage={isContactPage} />
            <StyledCubeMinx />
            <StyledCubeSkewb iscontactpage={isContactPage} />
            <StyledCubeSq1 />

            {isContactPage && <StyledCubeMinx hasdeclaredtop="540px" hasdeclaredright="440px" />}
        </StyledRegisterCube>
    );
};

export default RegisterCube;
