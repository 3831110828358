import React from 'react';
import { getString } from '../../../strings';
import TournamentsCompetitions from '../../molecules/Tournaments/TournamentsCompetitions';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledPlayerElementDataWrapper } from '../../atoms/Tournament/StyledPlayerElementDataWrapper';
import { StyledPlayerElement } from '../../atoms/Tournament/StyledPlayerElement';
import { StyledPlayerAvatar } from '../../atoms/Tournament/StyledPlayerAvatar';

import { ReactComponent as AvatarIcon } from '../../../images/register/avatarIcon.svg';

import { ImageViewer } from 'react-image-viewer-dv';

import countries from 'i18n-iso-countries';
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));

const PlayerElement = ({ playerData }) => {
    const wca_href = playerData?.wca_id
        ? `https://www.worldcubeassociation.org/persons/${playerData.wca_id}`
        : null;

    return (
        <StyledPlayerElement>
            <StyledPlayerAvatar issend={false}>
                {playerData?.has_avatar ? (
                    <ImageViewer>
                        <img src={`/api/users/get-avatar/${playerData?.id}`} />
                    </ImageViewer>
                ) : (
                    <AvatarIcon />
                )}
            </StyledPlayerAvatar>
            <StyledPlayerElementDataWrapper hasdeclaredwidth="40%">
                {wca_href ? (
                    <>
                        <a
                            aria-label={getString('seo_aria_label_przejdz_do_strony')}
                            href={wca_href}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredfontcolor={playerData?.wca_id ? '#3D5CA2' : '#EE745C'}
                                hascursor="pointer"
                            >
                                {getString('tournament_playerElement_zawodnik')}
                            </StyledText>
                        </a>
                        <a
                            aria-label={getString('seo_aria_label_przejdz_do_strony')}
                            href={wca_href}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <StyledText
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontweight="600"
                                hasdeclaredpadding="5px 0"
                                hascursor="pointer"
                            >
                                {playerData.firstname} {playerData.lastname}
                            </StyledText>
                        </a>
                    </>
                ) : (
                    <>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredfontcolor={playerData?.wca_id ? '#6786be' : '#EE745C'}
                        >
                            {getString('tournament_playerElement_zawodnik2')}
                        </StyledText>
                        <StyledText
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontweight="600"
                            hasdeclaredpadding="5px 0"
                        >
                            {playerData.firstname} {playerData.lastname}
                        </StyledText>
                    </>
                )}
            </StyledPlayerElementDataWrapper>
            <StyledPlayerElementDataWrapper hasdeclaredwidth="30%">
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786be"
                >
                    {getString('tournament_playerElement_miejscowosc')}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="5px 0"
                >
                    {playerData.city ? `${playerData.city}, ` : null}
                    {countries.getName(playerData.country, 'pl')}
                </StyledText>
            </StyledPlayerElementDataWrapper>
            <StyledPlayerElementDataWrapper hasdeclaredwidth="30%">
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786be"
                >
                    {getString('tournament_playerElement_konkurencje')}
                </StyledText>
                <TournamentsCompetitions
                    competitions={playerData.competitions}
                    size="17px"
                    hasdeclaredmargin="3px 2px 0 -1px"
                />
            </StyledPlayerElementDataWrapper>
        </StyledPlayerElement>
    );
};

export default PlayerElement;
