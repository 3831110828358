import styled from 'styled-components';

export const StyledDataRowWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 4px;

    @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 18px;
    }

    @media only screen and (max-width: 519px) {
        margin-bottom: 6px;
    }
`;
