import React, { useMemo, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import Decimal from 'decimal.js';

import { StyledEmployeeTableDataElement } from './EmployeeTableDataElement.styles';
import { StyledEditInputElement, StyledClosePayElement } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';
import { StyledTextElement } from '../../atoms/StyledTextElement/StyledTextElement';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';

import { getLang } from '../../../strings';

const emptyValue = '...';

const EmployeeTableDataElement = ({ subject, price }) => {
    const [isEdit, setIsEdit] = useState(false);

    const displayPrice = useMemo(() => {
        if (!price) return emptyValue;

        const parsed = new Decimal(price);
        return parsed.dp() > 0 ? parsed.toFixed(2) : parsed.toString();
    }, [price]);

    return (
        <StyledEmployeeTableDataElement>
            <ScrollSyncPane>
                <div>
                    {isEdit ? (
                        <StyledEditWrapper>
                            <StyledEditInputElement $hasDeclaredWidth="50%">
                                <input type="text" />
                            </StyledEditInputElement>
                            <StyledEditInputElement $hasDeclaredWidth="50%">
                                <select>
                                    <option value="brak" />
                                </select>
                            </StyledEditInputElement>
                        </StyledEditWrapper>
                    ) : (
                        <StyledGlobalUserPanelWrapper>
                            <StyledTextElement $hasDeclaredWidth="50%">
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {subject[getLang()]}
                                </StyledText>
                            </StyledTextElement>
                            <StyledTextElement $hasDeclaredWidth="50%">
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {displayPrice}
                                </StyledText>
                            </StyledTextElement>
                        </StyledGlobalUserPanelWrapper>
                    )}
                </div>
            </ScrollSyncPane>
            <StyledSavePayElement isedit={isEdit} onClick={() => setIsEdit(!isEdit)}>
                {!isEdit ? <PencilIcon /> : <AcceptIcon />}
            </StyledSavePayElement>
            <StyledClosePayElement>
                <CloseIcon />
            </StyledClosePayElement>
        </StyledEmployeeTableDataElement>
    );
};

export default EmployeeTableDataElement;
