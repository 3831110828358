import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledLoginForm = styled(motion.form)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 400px;
    position: relative;
    z-index: 2;

    @media only screen and (max-width: 836px) {
        width: 300px;

        h2 {
            font-size: 36px;
        }
    }

    @media only screen and (max-width: 767px) {
        width: 400px;

        h2 {
            font-size: 44px;
        }
    }

    @media only screen and (max-width: 440px) {
        width: 300px;

        h2 {
            font-size: 38px;
            padding-bottom: 8px;
        }
    }
`;
