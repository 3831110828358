import styled from 'styled-components';

export const StyledUserEditPasswordForm = styled.form`
    width: 360px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 140px;

    @media only screen and (max-width: 926px) {
        padding-top: 0;
    }

    @media only screen and (max-width: 540px) {
        width: 300px;
    }
`;
