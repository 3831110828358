import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import fuzzysort from 'fuzzysort';

import Loader from '../Loader/Loader';

import UserPanelStudentElement from '../UserPanelStudentElement/UserPanelStudentElement';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledUserPanelStudents } from '../../atoms/UserPanelStudens/StyledUserPanelStudents';
import { StyledUsersSearch } from '../../atoms/UserPanelStudens/StyledUsersSearch';
import { StyledGlobalScrollWrapper, StyledLoaderWrapper } from '../../../styles/sharedStyles';

import { getString } from '../../../strings';
import { listParents, listStudents } from '../../../logic/requests/students.js';
import { cebulaCompare } from '../../../logic/arrays.js';

import useScrollBar from '../../../logic/hooks/useScrollBar';

const UserPanelStudents = ({ user, former = false}) => {
    const [students, setStudents] = useState();
    const isFormer = useMemo(() => former, [former]);
    const [parents, setParents] = useState();
    const [err, setErr] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar();
    const isAdmin = useMemo(() => user.roles.includes('admin'), [user]);
    const canEdit = useMemo(
        () => isAdmin || user.roles.some((role) => ['trainer_new', 'manager'].includes(role)),
        [user, isAdmin]
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (!(user && user.roles.includes('trainer'))) navigate('/my-account', { replace: true });
        if (isFormer && (!(user && ['admin', 'manager'].some((role) => user.roles.includes(role))))){
            navigate('/my-account', { replace: true });
        }

        listStudents(isFormer ? 1 : 0)
            .then((res) => {
                setStudents(
                    res.sort((a, b) => {
                        let ret;

                        ret = cebulaCompare(a.lastname, b.lastname);

                        if (!ret) ret = cebulaCompare(a.firstname, b.firstname);

                        return ret;
                    })
                );
            })
            .catch((err) => {
                setStudents([]);
                setErr(true);
            });

        if (canEdit) {
            listParents()
                .then((res) => {
                    setParents(
                        res.sort((a, b) => {
                            let ret;

                            ret = cebulaCompare(a.lastname, b.lastname);

                            if (!ret) ret = cebulaCompare(a.firstname, b.firstname);

                            return ret;
                        })
                    );
                })
                .catch((err) => {
                    setParents([]);
                    setErr(true);
                });
        }
    }, [canEdit, isFormer, navigate, user]);

    const [search, setSearch] = useState();

    return (
        <StyledUserPanelStudents>
            {!students ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : !students.length ? (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredpadding="0px 20px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString(err ? 'blad_wczytywania' : 'UserPanelStudents__no_students')}
                    </StyledText>
                </StyledLoaderWrapper>
            ) : (
                <>
                    <StyledUsersSearch>
                        <UserPanelHeadingText
                            text={`${getString(former ? 'UserPanelFormerStudents__list__header__title' : 'UserPanelStudents__list__header__title')}:`}
                        />
                        <input
                            type="text"
                            placeholder={getString(
                                'UserPanelStudents__list__header__search_bar_placeholder'
                            )}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </StyledUsersSearch>
                    <StyledGlobalScrollWrapper
                        isscrollbar={isScrollBar}
                        ref={scrollElement}
                        $hasDeclaredHeight={isAdmin ? 'calc(100% - 160px)' : 'calc(100% - 120px)'}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            {fuzzysort
                                .go(search, students, {
                                    all: true,
                                    keys: ['lastname', 'firstname'],
                                })
                                .map((ret) => ret.obj)
                                .map((student) => (
                                    <UserPanelStudentElement
                                        key={student.id}
                                        user={user}
                                        student={student}
                                        setStudent={(val) =>
                                            setStudents([
                                                ...students.slice(0, students.indexOf(student)),
                                                typeof val === 'function' ? val(student) : val,
                                                ...students.slice(students.indexOf(student) + 1),
                                            ])
                                        }
                                        canEdit={canEdit}
                                        parents={parents}
                                    />
                                ))}
                        </div>
                    </StyledGlobalScrollWrapper>
                </>
            )}
        </StyledUserPanelStudents>
    );
};

export default UserPanelStudents;
