import styled from 'styled-components';

export const StyledResultWrapper = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
    padding: 0 20px;

    @media only screen and (max-width: 642px) {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
`;
