import styled from 'styled-components';

export const StyledSuccesMessage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px;
    width: 100%;
    gap: 10px;
    margin-top: 40px;
    width: 100%;
    border: 8px solid #6786be;
    border-radius: 25px;
    background-color: #fff;

    @media only screen and (max-width: 926px) {
        margin-bottom: 40px;
    }
`;
