import styled from 'styled-components';

export const StyledWhiteResultsDiv = styled.div`
    min-width: 624px;
    width: 60%;
    margin-left: 2%;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #fff;
    min-height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > p {
        min-width: 35px;
    }
`;
