import React from 'react';

import { StyledCubeWrapper } from '../../atoms/LoginCube/StyledCubeWrapper';

import { StyledSq1Cube } from '../../atoms/Cubes/StyledSq1Cube';

import useWindowSize from '../../../utils/getWindowSize';

const CubeSq1 = () => {
    const windowWidth = useWindowSize();

    return (
        <StyledCubeWrapper
            hasdeclaredbottomposition={windowWidth < 1600 ? '110px' : '180px'}
            hasdeclaredrightposition={windowWidth < 1600 ? '40px' : '110px'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, rotate: 42 }}
            transition={{ duration: 0.4, delay: 0.3 }}
        >
            <StyledSq1Cube
                width={windowWidth >= 2000 ? '290px' : '240px'}
                height={windowWidth >= 2000 ? '290px' : '240px'}
                fill="rgba(0,0,0,0.1)"
            />
        </StyledCubeWrapper>
    );
};

export default CubeSq1;
