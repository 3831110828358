import React from 'react';

import { StyledCube444 } from '../../atoms/RegisterCube/StyledCube444';
import { StyledCubeMinx } from '../../atoms/RegisterCube/StyledCubeMinx';
import { StyledCubeSkewb } from '../../atoms/RegisterCube/StyledCubeSkewb';
import { StyledCubeSq1 } from '../../atoms/RegisterCube/StyledCubeSq1';

import { StyledTournamentCubes } from '../../molecules/Tournament/StyledTournamentCubes';

const TournamentCubes = ({
    top1,
    left1,
    right2,
    top2,
    bottom3,
    right3,
    isSkewb,
    svgSize,
    svgSize2,
    isMinx,
}) => {
    return (
        <StyledTournamentCubes svgsize={svgSize} svgsize2={svgSize2}>
            <StyledCube444 top1={top1} left1={left1} />
            <StyledCubeSkewb hasdeclaredleft2="70%" hasdeclaredtop2="80px" />
            {isMinx && (
                <StyledCubeMinx
                    hasdeclaredright={right2}
                    hasdeclaredtop={top2}
                    iscontactpage={true}
                />
            )}
            <StyledCubeSq1
                hasdeclaredbottom={bottom3}
                hasdecalredright={right3}
                iscontactpage={true}
            />
            {isSkewb && <StyledCubeSkewb />}
        </StyledTournamentCubes>
    );
};

export default TournamentCubes;
