import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';

export const StyledUserPanelSubjectDataElement = styled.div`
    width: 100%;
    margin-bottom: 20px;
    border-radius: 25px 9px 9px 25px;
    min-height: 43px;
    border: 4px solid
        ${({ hasdeclaredbordercolor }) => (hasdeclaredbordercolor ? '#ee745c' : '#6786BE')};
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: ${({ isopen }) => (isopen ? '70px' : '0')};
    padding-left: 10px;
    position: relative;
`;

export const StyledElement = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
    width: 100%;
    position: relative;
`;

export const StyledFuckingBtn = styled.button`
    background-color: ${({ isedit }) => (isedit ? '#5FEE5C' : '#6786be')};
    cursor: pointer;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: absolute;
    bottom: -74px;
    left: -14px;
    width: 50px;
    height: 50px;
`;

export const StyledHeader = styled.div`
    width: 100%;
    overflow-x: scroll;
    align-self: ${({ hasalignself }) => (hasalignself ? hasalignself : 'flex-end')};

    ${scrollBlueStyles};

    > div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        align-self: flex-end;
        border: 4px solid transparent;
        min-width: 700px;
    }
`;

export const StyledEditButton = styled(StyledSavePayElement)`
    position: absolute;
    bottom: -74px;
    left: -14px;
    width: 50px;
    height: 50px;
`;

export const StyledHeaderElement = styled.div`
    width: ${({ simulatecheckbox, $hasWidth }) =>
        simulatecheckbox ? '41px' : $hasWidth ? $hasWidth : '33%'};
    padding: 0 4px;
`;

export const StyledDeleteButton = styled.button`
    width: 50px;
    height: 50px;
    border: none;
    background-color: #ee745c;
    position: absolute;
    bottom: -74px;
    right: -4px;
    border-radius: 9px;
    cursor: pointer;
`;
