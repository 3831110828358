import styled from 'styled-components';

export const StyledCubeIcons = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 767px) {
        display: none;
    }
`;
