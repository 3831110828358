import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { AnimatePresence } from 'framer-motion';
import Decimal from 'decimal.js';
import { getString } from '../../../strings';

import InputWrapper from '../../molecules/Register/InputWrapper';
import SelectCoach from './SelectCoach';
import Loader from '../Loader/Loader';
import SmallLoader from '../Loader/SmallLoader';
import RegisterErrorMessage from '../../molecules/Register/RegisterErrorMessage';
import CompetitionElement from '../../molecules/CompetitionElement/CompetitionElement';

import { StyledCheckboxWrapper } from '../../molecules/Register/StyledCheckboxWrapper';
import { StyledOrderRek } from '../../atoms/OrderRek/StyledOrderRek';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import {
    StyledAnswerWrapper,
    StyledRegulaminWrapper,
    StyledRadioWrapper,
    StyledRadioLabel,
    StyledRadioCompetitionWrapper,
    StyledRadioCompetitionInput,
    StyledCodeWrapper,
    StyledInputWrapp,
    StyledInputWrappSelect,
    StyledOrderRekForm,
} from '../../atoms/OrderRek/StyledOrderRek';

import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';

import { priceCheck, orderAnalysis } from '../../../logic/requests/trainings.js';
import useScrollBar from '../../../logic/hooks/useScrollBar';

import { StyledLoaderWrapper } from '../../../styles/sharedStyles';

const OrderRek = ({ user, formData }) => {
    const location = useLocation();
    const isSend = Boolean(location.state?.afterTransaction);
    const isSendErr = Boolean(location.state?.afterTransactionErr);

    const { scrollElement, isScrollBar } = useScrollBar();
    const [isRadioChecked, setIsRadioChecked] = useState('');
    const [bonusCode, setBonusCode] = useState(false);
    const [competition, setCompetition] = useState();
    const trainers =
        formData?.trainers && competition
            ? formData.trainers.filter((trainer) => trainer.competitions.includes(competition))
            : undefined;

    const [price, setPrice] = useState();

    const [format, setFormat] = useState();
    const [bonusCodeValue, setBonusCodeValue] = useState();
    const [bonusCodeStatus, setBonusCodeStatus] = useState();

    const [error, setError] = useState({ message: null });

    useEffect(() => {
        if (competition?.length && format?.length) {
            priceCheck(competition, format, bonusCodeValue)
                .then((res) => {
                    setPrice(Decimal(res.price));
                    setBonusCodeStatus(res.bonusCodeStatus);
                    setError({ message: null });
                })
                .catch(() => {
                    setError({ message: 'Błąd sprawdzania ceny' });
                });
        }
    }, [competition, format, bonusCodeValue, setPrice]);

    const handleSubmit = (data, { setSubmitting }) => {
        const toSend = {
            ...data,
            regulationsField: undefined,
            trainer: data.selectCoach,
            selectCoach: undefined,
            link: data.ytLink,
            ytLink: undefined,
        };

        orderAnalysis(toSend)
            .then(
                res => {
                    console.log("orderAnalysis", res);
                    if (res.token) {
                        const service = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "uat" ? "secure" : "sandbox";
                        window.location = `https://${service}.przelewy24.pl/trnRequest/${res.token}`;
                    }
                }
            )
            .catch(() => {
                setError({ message: 'Wystąpił błąd' });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const updateChecked = (data) => {
        setIsRadioChecked(data);
    };

    const max_birth_date = new Date();
    max_birth_date.setFullYear(max_birth_date.getFullYear() - 2);

    const name_character_blacklist = [
        '`',
        '~',
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '_',
        '=',
        '+',
        '[',
        ']',
        '{',
        '}',
        ';',
        ':',
        "'",
        '"',
        '\\',
        ',',
        '.',
        '<',
        '>',
        '/',
        '?',
        '\t',
    ];

    const contains_blacklist_character = (s) => {
        if (!s?.length) return false;
        for (let char of name_character_blacklist) {
            if (s.indexOf(char) > -1) return true;
        }
        return false;
    };

    const validationSchema = Yup.object({
        firstname: Yup.string()
            .min(2, getString('const_userDataValidationSchema_zaKrotkieImie'))
            .required(getString('const_userDataValidationSchema_imieWymagane'))
            .test(
                'firstname-blacklist',
                getString('const_userDataValidationSchema_imieZawieraNiedozwoloneZnaki'),
                (val) => !contains_blacklist_character(val)
            ),
        lastname: Yup.string()
            .required(getString('const_userDataValidationSchema_nazwiskoWymagane'))
            .test(
                'lastname-blacklist',
                getString('const_userDataValidationSchema_nazwiskoZawieraNiedozZnaki'),
                (val) => !contains_blacklist_character(val)
            ),
        phone: Yup.string()
            .matches(
                /^(?:\+?\d{1,2}\s?)?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{2,4}$/,
                getString('const_userDataValidationSchema_nieprawidlowy')
            )
            .required(getString('const_userDataValidationSchema_numerTelefonu')),
        email: Yup.string()
            .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                getString('const_registerValidationSchema_emailNiepoprawny')
            )
            .required(getString('const_registerValidationSchema_emailWymagany')),
        competition: Yup.string().required(getString('trainings_orderrek_wybierzkonkurencjeblad')),
        count: Yup.string().required(getString('trainings_orderrek_zaznaczliczbeulozen')),
        selectCoach: Yup.number().required(getString('trainings_orderrek_wybierztrenerablad')),
        ytLink: Yup.string()
            .required(getString('trainings_orderrek_podajlinkdofilmu'))
            .matches(
                /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?$/
            ),
        regulationsField: Yup.bool()
            .oneOf([true], getString('const_registerValidationSchema_akceptujRegulamin'))
            .required(getString('const_registerValidationSchema_akceptujRegulamin')),
        bonusCode: Yup.string()
            .notRequired()
            .test(
                'bonuscode-valid',
                getString(`trainings_orderrek_kodrabatowy_status_${bonusCodeStatus}`),
                (val) => !bonusCodeStatus || bonusCodeStatus === 'valid'
            ),
    });

    const initialValues = {
        firstname: user?.firstname ?? '',
        lastname: user?.lastname ?? '',
        phone: user?.phone ?? '',
        email: user?.email ?? '',
        competition: '',
        count: '',
        selectCoach: '',
        ytLink: '',
        regulationsField: false,
        bonusCode: '',
    };

    if (!user)
        return (
            <StyledText
                hasdeclaredfontsize="32px"
                hasdeclaredtextalign="center"
                hasdeclaredlineheight="1.2em"
                hasdeclaredfontweight="600"
                hasdeclaredfontcolor="#000"
                hasdeclaredpadding="0 20px 40px"
                hasdeclaredmargin="-50px 0 0 0"
                as="h2"
            >
                {getString('trainings_orderrek_zalogujsie_1') + ' '}
                <Link to={'/login'} style={{ textDecoration: 'none', color: '#6786be' }}>
                    {getString('trainings_orderrek_zalogujsie_2')}
                </Link>
                .
            </StyledText>
        );

    if (typeof formData !== 'object')
        return (
            <StyledLoaderWrapper>
                <Loader />
            </StyledLoaderWrapper>
        );

    if (!Object.keys(formData).length) {
        return (
            <StyledText
                hasdeclaredfontsize="32px"
                hasdeclaredtextalign="center"
                hasdeclaredlineheight="1.2em"
                hasdeclaredfontweight="600"
                hasdeclaredfontcolor="#000"
                hasdeclaredpadding="0 20px"
                hasdeclaredmargin="-50px 0 0 0"
                as="h2"
            >
                {getString('order_rek_brak_dostepnych_trenerow')}
            </StyledText>
        );
    }

    return (
        <StyledOrderRek
            issend={isSend}
            isscrollbar={isScrollBar}
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {isSend ? (
                <StyledAnswerWrapper>
                    <StyledText
                        hasdeclaredfontsize="18px"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#000"
                        hasdeclaredpadding="0 20px"
                    >
                        {!isSendErr ? (
                            <>
                                {getString('trainings_orderrek_taktwojaanaliza')}
                                <a href="">{getString('trainings_orderrek_mojekonto')}</a>
                                {getString('trainings_orderrek_mojekontotab')}
                            </>
                        ) : (
                            getString('trainings_orderrek_przykronam')
                        )}
                    </StyledText>
                </StyledAnswerWrapper>
            ) : !error.message ? (
                <>
                    <StyledText
                        hasdeclaredfontsize="30px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="8px 20px 24px 20px"
                        hasdeclaredfontweight="700"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredmaxwidth="800px"
                        as="h1"
                    >
                        {getString('trainings_orderrek_chceszsprawdzic')}
                        <br />
                        {getString('trainings_orderrek_przekazswojeulozenia')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredtextalign="justify"
                        hasdeclaredpadding="8px 20px 8px 20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredmaxwidth="800px"
                        as="h2"
                    >
                        {getString('trainings_orderrek_nagrajfilmik')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredtextalign="justify"
                        hasdeclaredpadding="8px 20px 8px 20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredmaxwidth="800px"
                    >
                        {getString('trainings_orderrek_wprzypadku')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredtextalign="justify"
                        hasdeclaredpadding="8px 20px 8px 20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredmaxwidth="800px"
                    >
                        {getString('trainings_orderrek_filmubezrekonstrukcji')}
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="16px"
                        hasdeclaredtextalign="justify"
                        hasdeclaredpadding="8px 20px 32px 20px"
                        hasdeclaredfontweight="600"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredmaxwidth="800px"
                    >
                        {getString('trainings_orderrek_bezscrambli')}
                    </StyledText>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ errors, handleSubmit, isSubmitting, setFieldValue, values }) => {
                            if (values.competition !== competition) {
                                setCompetition(values.competition);
                                if (values.selectCoach) {
                                    const selectedCoach = formData.trainers.find(
                                        (trainer) => trainer.id === values.selectCoach
                                    );
                                    if (
                                        !(
                                            selectedCoach &&
                                            selectedCoach.competitions.includes(values.competition)
                                        )
                                    )
                                        setFieldValue('selectCoach', '');
                                }
                            }

                            if (values.competition && values.count) {
                                if (!formData.offers[values.competition].includes(values.count))
                                    setFieldValue('count', '');
                            }

                            setFormat(values.count);
                            setBonusCodeValue(values.bonusCode);

                            return (
                                <AnimatePresence>
                                    <StyledOrderRekForm onSubmit={handleSubmit}>
                                        <StyledInputWrapp>
                                            <InputWrapper
                                                inputType="text"
                                                title={getString(
                                                    'register_registerForm_firstnameTitle'
                                                )}
                                                name="firstname"
                                                error={errors.firstname}
                                                hasDeclaredBottomPosition="-3px"
                                            />
                                        </StyledInputWrapp>
                                        <StyledInputWrapp>
                                            <InputWrapper
                                                inputType="text"
                                                title={getString(
                                                    'register_registerForm_lastnameTitle'
                                                )}
                                                name="lastname"
                                                error={errors.lastname}
                                                hasDeclaredBottomPosition="-3px"
                                            />
                                        </StyledInputWrapp>
                                        <StyledInputWrapp>
                                            <InputWrapper
                                                inputType="email"
                                                title={getString(
                                                    'register_registerForm_emailTitle'
                                                )}
                                                name="email"
                                                error={errors.email}
                                                hasDeclaredBottomPosition="-3px"
                                            />
                                        </StyledInputWrapp>
                                        <StyledInputWrapp>
                                            <InputWrapper
                                                inputType="text"
                                                title={getString(
                                                    'register_registerForm_numertelefonuTitle'
                                                )}
                                                name="phone"
                                                error={errors.phone}
                                                hasDeclaredBottomPosition="-3px"
                                            />
                                        </StyledInputWrapp>
                                        <StyledInputWrapp isfullwidth={true}>
                                            <StyledText
                                                hasdeclaredfontsize="20px"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredpadding="24px 0 0 0"
                                                hasdeclaredfontweight="600"
                                            >
                                                {getString('trainings_orderrek_wybierzkonkurencje')}
                                            </StyledText>
                                            <StyledRadioCompetitionWrapper
                                                haserror={errors.competition}
                                            >
                                                {formData.competitions.map((e) => (
                                                    <Field
                                                        key={e}
                                                        name="competition"
                                                        render={({ field }) => {
                                                            return (
                                                                <StyledRadioCompetitionInput
                                                                    {...field}
                                                                    ischecked={isRadioChecked === e}
                                                                >
                                                                    <label
                                                                        onClick={() =>
                                                                            updateChecked(e)
                                                                        }
                                                                        htmlFor={e}
                                                                    >
                                                                        <CompetitionElement
                                                                            competition={e}
                                                                            htmlFor={e}
                                                                            title={
                                                                                getString(
                                                                                    `const_competitions_${e}`
                                                                                ) ?? e
                                                                            }
                                                                        />
                                                                    </label>
                                                                    <input
                                                                        id={e}
                                                                        type="radio"
                                                                        name="competition"
                                                                        value={e}
                                                                    />
                                                                </StyledRadioCompetitionInput>
                                                            );
                                                        }}
                                                    />
                                                ))}
                                                <RegisterErrorMessage
                                                    name="competition"
                                                    hasDeclaredBottomPosition="-10px"
                                                />
                                            </StyledRadioCompetitionWrapper>
                                        </StyledInputWrapp>
                                        <StyledInputWrapp isfullwidth={true}>
                                            <StyledText
                                                hasdeclaredfontsize="20px"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredpadding="24px 0 0 0"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString('trainings_orderrek_wybierzliczbe')}
                                            </StyledText>
                                            <StyledRadioWrapper haserror={errors.count}>
                                                {competition ? (
                                                    formData.offers[competition].map((format) => (
                                                        <StyledRadioLabel>
                                                            <Field
                                                                type="radio"
                                                                name="count"
                                                                value={format}
                                                            />
                                                            <StyledText>
                                                                {format === 'single' ? '1' : format}
                                                            </StyledText>
                                                        </StyledRadioLabel>
                                                    ))
                                                ) : (
                                                    <StyledText hasdeclaredtextalign="center">
                                                        {getString(
                                                            'trainings_orderrek_wybierzkonkurencjeaby2'
                                                        )}
                                                    </StyledText>
                                                )}
                                            </StyledRadioWrapper>
                                            <RegisterErrorMessage
                                                name="count"
                                                hasDeclaredBottomPosition="-26px"
                                            />
                                        </StyledInputWrapp>
                                        <StyledInputWrappSelect>
                                            <SelectCoach
                                                title={getString(
                                                    'trainings_orderrek_wybierztrenera'
                                                )}
                                                name="selectCoach"
                                                coaches={trainers}
                                                error={errors.selectCoach}
                                                onChange={(event) =>
                                                    setFieldValue(
                                                        'selectCoach',
                                                        parseInt(event.target.value)
                                                    )
                                                }
                                            />
                                        </StyledInputWrappSelect>
                                        <StyledInputWrapp
                                            isfullwidth={true}
                                            isfullwidthinput="100%"
                                        >
                                            <InputWrapper
                                                inputType="text"
                                                title={getString(
                                                    'trainings_orderrek_linkdonagrania'
                                                )}
                                                name="ytLink"
                                                error={errors.ytLink}
                                                hasDeclaredBottomPosition="-3px"
                                            />
                                        </StyledInputWrapp>
                                        {values.competition && values.count ? (
                                            <StyledInputWrapp isfullwidth={true}>
                                                <StyledText
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredfontsize="26px"
                                                    hasdeclaredpadding="22px 0 8px 0"
                                                    hasdeclaredfontweight="700"
                                                >
                                                    {`${getString('trainings_orderrek_cena')}: `}
                                                </StyledText>
                                                {price ? (
                                                    <StyledText
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredfontsize="26px"
                                                        hasdeclaredpadding="22px 0 8px 0"
                                                        hasdeclaredfontweight="700"
                                                    >
                                                        {price.toString()}zł
                                                    </StyledText>
                                                ) : (
                                                    <SmallLoader />
                                                )}
                                            </StyledInputWrapp>
                                        ) : undefined}
                                        {user ? (
                                            <StyledCodeWrapper>
                                                {!bonusCode ? (
                                                    <StyledCheckboxWrapper>
                                                        <input type="checkbox" id="bonus" />
                                                        <label
                                                            htmlFor="bonus"
                                                            onClick={() => setBonusCode(true)}
                                                        >
                                                            <CheckboxAccept />
                                                        </label>
                                                        <StyledText
                                                            hasdeclaredfontweight="bold"
                                                            hasdeclaredpadding="0 0 0 14px"
                                                        >
                                                            {getString(
                                                                'trainings_orderrek_mamkodrabatowy'
                                                            )}{' '}
                                                        </StyledText>
                                                    </StyledCheckboxWrapper>
                                                ) : (
                                                    <>
                                                        <StyledInputWrapp>
                                                            <InputWrapper
                                                                inputType="text"
                                                                title={getString(
                                                                    'trainings_orderrek_kodrabatowy'
                                                                )}
                                                                name="bonusCode"
                                                                error={errors.bonusCode}
                                                                hasDeclaredBottomPosition="-22px"
                                                            />
                                                        </StyledInputWrapp>
                                                        <StyledButton
                                                            hasdeclaredfontcolor="#fff"
                                                            hasdeclaredwidth="240px"
                                                            hasdeclaredheight="50px"
                                                            hasdeclaredbgcolor="#ee745c"
                                                            hasdeclaredfontweight="600"
                                                            hasdeclaredmargin="0 0 20px 0"
                                                            hasdeclaredborderradius="25px"
                                                            hasdeclaredcursor="pointer"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredtexttransform="uppercase"
                                                            className="hover-red-effect"
                                                            onClick={() => {
                                                                setBonusCode(false);
                                                                setFieldValue('bonusCode', '');
                                                            }}
                                                        >
                                                            {getString('trainings_orderrek_anuluj')}
                                                        </StyledButton>
                                                    </>
                                                )}
                                            </StyledCodeWrapper>
                                        ) : undefined}
                                        <StyledRegulaminWrapper>
                                            <StyledCheckboxWrapper
                                                error={errors.regulationsField}
                                                hasdeclaredadnotationcolor="#EE745C"
                                            >
                                                <Field
                                                    type="checkbox"
                                                    name="regulationsField"
                                                    id="regulationsField"
                                                />
                                                <label htmlFor="regulationsField">
                                                    <CheckboxAccept />
                                                </label>
                                                <StyledText
                                                    hasdeclaredfontweight="bold"
                                                    hasdeclaredpadding="0 0 0 14px"
                                                >
                                                    {getString('register_registerForm_akceptuje3')}{' '}
                                                    <a
                                                        href="/regulamin"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        aria-label={getString(
                                                            'seo_aria_label_przejdz_do_strony'
                                                        )}
                                                    >
                                                        {getString(
                                                            'register_registerForm_regulamin'
                                                        )}
                                                    </a>
                                                </StyledText>
                                            </StyledCheckboxWrapper>
                                        </StyledRegulaminWrapper>
                                        <StyledRegulaminWrapper>
                                            <StyledText
                                                hasdeclaredfontsize="12px"
                                                hasdeclaredlineheight="1.4em"
                                                hasdeclaredmaxwidth="600px"
                                                hasdeclaredpadding="0 20px 20px 20px"
                                                hasdeclaredfontweight="600"
                                            >
                                                {getString('trainings_orderrek_pozamowieniu')}
                                            </StyledText>
                                        </StyledRegulaminWrapper>
                                        <div>
                                            <StyledButton
                                                hasdeclaredfontcolor="#fff"
                                                hasdeclaredwidth="240px"
                                                hasdeclaredheight="50px"
                                                hasdeclaredmargin="24px 0 0 0"
                                                hasdeclaredbgcolor="#6786BE"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredborderradius="25px"
                                                hasdeclaredcursor="pointer"
                                                type="submit"
                                                disabled={isSubmitting}
                                                hasdeclaredtextalign="center"
                                                hasdeclaredtexttransform="uppercase"
                                                whileHover={{ scale: 0.95 }}
                                            >
                                                {getString('trainings_orderrek_zamawiamiplace')}
                                            </StyledButton>
                                        </div>
                                    </StyledOrderRekForm>
                                </AnimatePresence>
                            );
                        }}
                    </Formik>
                </>
            ) : (
                <StyledText
                    hasdeclaredfontsize="32px"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.2em"
                    hasdeclaredfontweight="600"
                    hasdeclaredfontcolor="#000"
                    hasdeclaredpadding="0 20px"
                    hasdeclaredmargin="-50px 0 0 0"
                    as="h2"
                >
                    {error.message}
                </StyledText>
            )}
        </StyledOrderRek>
    );
};

export default OrderRek;
