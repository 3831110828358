import styled from 'styled-components';

export const StyledAdvantageWrapper = styled.div`
    border-radius: 25px;
    height: 50px;
    background-color: ${({ hasdeclaredbgcolor }) =>
        hasdeclaredbgcolor ? hasdeclaredbgcolor : 'transparent'};
    z-index: ${({ hasdeclaredzindex }) => (hasdeclaredzindex ? hasdeclaredzindex : '0')};
    position: absolute;
    top: 0;
    left: ${({ hasdeclaredleft }) => (hasdeclaredleft ? hasdeclaredleft : '0')};
    width: ${({ hasdeclaredwidth }) => (hasdeclaredwidth ? hasdeclaredwidth : '20%')};

    display: flex;
    align-items: center;
    justify-content: ${({ hasdeclaredjustifycontent }) =>
        hasdeclaredjustifycontent ? hasdeclaredjustifycontent : 'center'};
    padding: ${({ hasdeclaredjustifycontent }) => (hasdeclaredjustifycontent ? '0 30px 0 0' : '0')};
    gap: 5px;

    > p {
        > span {
            font-size: 20px;
            font-weight: 700;
        }
    }
`;
