const locales = require("./locales.json");

const cookie_name = "lang", default_lang = "gb";

const getLocale = (lang) => locales[lang] ?? locales[default_lang];
const getDefaultLang = () => default_lang;

module.exports = {
	cookie_name,
	default_lang,
	getLocale,
	getDefaultLang
};