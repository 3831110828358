import React, { useEffect, useRef } from 'react';
import { ScrollSync } from 'react-scroll-sync';
import { getString } from '../../../strings';
import SeriesResultsElement from './SeriesResultsElement';
import SeriesResultsElementHeading from './SeriesResultsElementHeading';
import SeriesResultsMobileElement from './SeriesResultsMobileElement';

import { StyledSeriesResults } from '../../atoms/Series/StyledSeriesResults';
import { StyledSeriesResultsWrapper } from '../../atoms/Series/StyledSeriesResultsWrapper';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledSeriesResultsInfoText } from '../../molecules/Series/StyledSeriesResultsInfoText';

import useWindowSize from '../../../utils/getWindowSize';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const SeriesResults = ({ activeResults, leagueNumbers, results, user }) => {
    const width = useWindowSize();

    const containerRef = useRef();
    const { scrollElement, isScrollBar } = useScrollBar();

    useEffect(() => {
        if (width < 768)
            containerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
    }, []);

    const currentUserResult =
        user && results ? results.find((result) => result.id === user.id) : undefined;

    return (
        <StyledSeriesResults
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            ref={containerRef}
        >
            {width > 767 ? (
                <StyledText
                    hasdeclaredfontsize="40px"
                    hasdeclaredfontweight="700"
                    hasdeclaredpadding="110px 20px 20px"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                >
                    {getString('series_seriesResults_klasyfikacja')}
                </StyledText>
            ) : null}
            {results ? (
                <StyledSeriesResultsWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                    {width > 767 ? (
                        <ScrollSync>
                            <>
                                <SeriesResultsElementHeading
                                    activeResults={activeResults}
                                    results={leagueNumbers}
                                />
                                {currentUserResult ? (
                                    <SeriesResultsElement
                                        activeResults={activeResults}
                                        leagueNumbers={leagueNumbers}
                                        result={currentUserResult}
                                        number={currentUserResult.place}
                                        redcolor={true}
                                        bottomGap={true}
                                    />
                                ) : undefined}
                                {results.map((result, idx) => (
                                    <SeriesResultsElement
                                        activeResults={activeResults}
                                        leagueNumbers={leagueNumbers}
                                        result={result}
                                        key={idx}
                                        number={result.place}
                                        redcolor={
                                            currentUserResult
                                                ? currentUserResult.id === result.id
                                                : false
                                        }
                                    />
                                ))}
                            </>
                        </ScrollSync>
                    ) : (
                        <>
                            {currentUserResult ? (
                                <SeriesResultsMobileElement
                                    activeResults={activeResults}
                                    leagueNumbers={leagueNumbers}
                                    result={currentUserResult}
                                    number={currentUserResult.place}
                                    redcolor={true}
                                    bottomGap={true}
                                />
                            ) : undefined}
                            {results.map((result, idx) => (
                                <SeriesResultsMobileElement
                                    activeResults={activeResults}
                                    leagueNumbers={leagueNumbers}
                                    result={result}
                                    key={idx}
                                    number={result.place}
                                    redcolor={
                                        currentUserResult
                                            ? currentUserResult.id === result.id
                                            : false
                                    }
                                />
                            ))}
                        </>
                    )}
                </StyledSeriesResultsWrapper>
            ) : (
                <StyledSeriesResultsInfoText>
                    <StyledText
                        hasdeclaredfontsize="32px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="0 20px"
                        hasdeclaredfontweight="700"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString('series_seriesResults_wybierzZawodyZListy')}
                    </StyledText>
                </StyledSeriesResultsInfoText>
            )}
        </StyledSeriesResults>
    );
};

export default SeriesResults;
