/* eslint-disable default-case */
import React, { useState } from 'react';
import Flag from 'react-world-flags';

import { StyledChangeLanguage } from '../../molecules/ChangeLanguage/StyledChangeLanguage';
import {
    StyledChangeLanguageWrapper,
    StyledOpenChangeLanguage,
    StyledOpenElement,
} from './ChangeLanguage.styles';
import { StyledFlag, StyledArrow } from '../../../styles/sharedStyles';

import { langList, getLang, setLang, getString } from '../../../strings';

const ChangeLanguage = ({ isInputField, isMarginTop, setLangForm }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSwitchLang = (langCode) => {
        if (!isInputField) {
            if (setLangForm) setLangForm(langCode);
            setLang(langCode);
            window.location = '';
        } else {
            // todo: tutaj mechanika w przypadku pola w formularzu
        }
    };

    return (
        <StyledChangeLanguageWrapper $isMarginTop={isMarginTop} $isInputField={isInputField}>
            <StyledChangeLanguage
                onClick={() => setIsOpen(!isOpen)}
                isactive={isOpen}
                title={getString('lang_name')}
            >
                <StyledFlag>
                    <Flag code={getLang().toUpperCase()} alt={getLang().toUpperCase()} />
                </StyledFlag>
                <StyledArrow isopen={isOpen} blackcolor={true} />
            </StyledChangeLanguage>
            {isOpen && (
                <StyledOpenChangeLanguage>
                    {langList.map((lang) => (
                        <StyledOpenElement
                            onClick={() => handleSwitchLang(lang)}
                            title={getString('lang_name')}
                        >
                            <StyledFlag>
                                <Flag code={lang.toUpperCase()} alt={getLang().toUpperCase()} />
                            </StyledFlag>
                        </StyledOpenElement>
                    ))}
                </StyledOpenChangeLanguage>
            )}
        </StyledChangeLanguageWrapper>
    );
};

export default ChangeLanguage;
