import { useCallback } from 'react';
import { createAnnouncement } from '../../../logic/requests/announcements';
import { useQueryClient } from '@tanstack/react-query';
import AnnouncementForm from './AnnouncementForm';

const initialValues = {
    title: '',
    text: '',
};

const handleSubmit = (data, { setSubmitting, resetForm }, onSuccess) => {
    createAnnouncement(data)
        .then((res) => {
            resetForm();
            onSuccess?.();
        })
        .catch((err) => {
            console.error('createAnnouncement', err);
        })
        .finally(() => {
            setSubmitting(false);
        });
};

const CreateAnnouncement = () => {
    const queryClient = useQueryClient();

    const onSubmit = useCallback(
        (data, ...params) => {
            return handleSubmit(data, ...params, () => {
                queryClient.invalidateQueries('announcements');
            });
        },
        [queryClient]
    );

    return <AnnouncementForm initialValues={initialValues} onSubmit={onSubmit} />;
};

export default CreateAnnouncement;
