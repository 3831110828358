import React from 'react'
import { getString } from "../../../strings";

import { StyledMenuElement } from "../../molecules/Series/StyledMenuElement";
import { StyledText } from "../../atoms/Text/StyledText";

import useWindowSize from "../../../utils/getWindowSize";

const TrainingsMenu = ({ setMenuVal, menuVal }) => {
    const width = useWindowSize();

    return (
        <>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "about-us" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("about-us")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_about")}
                </StyledText>
            </StyledMenuElement>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "trainers" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("trainers")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_trainers")}
                </StyledText>
            </StyledMenuElement>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "info" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("info")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_lessons")}
                </StyledText>
            </StyledMenuElement>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "sign-up" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("sign-up")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_join")}
                </StyledText>
            </StyledMenuElement>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "order-analysis" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("order-analysis")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_orderanalysis")}
                </StyledText>
            </StyledMenuElement>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "questions" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("questions")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_questions")}
                </StyledText>
            </StyledMenuElement>
            <StyledMenuElement
                ismobile={width > 767 ? false : true}
                iscenterelement={true}
                hasdeclaredbgcolor={menuVal === "partners" ? "#602B26" : "#ee745c"}
                onClick={() => setMenuVal("partners")}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                    hascursor="pointer"
                >
                    {getString("trainings_menu_partners")}
                </StyledText>
            </StyledMenuElement>
        </>
    );
}

export default TrainingsMenu
