import React, { useCallback, useMemo, useState } from 'react';
import Decimal from 'decimal.js';

import { StyledCheckboxDone } from '../../atoms/SettlementsDoneElement/StyledCheckboxDone';
import { ReactComponent as CheckboxAccept } from '../../../images/checkboxAccept.svg';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledElement,
    StyledInputWrapper,
    StyledCheckboxWrapper,
} from './AllTrainerPayPopup.styles';

import { getString } from '../../../strings';

const AllTrainerPayPopupElement = ({ trainer, handleChange }) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const maxAmountToPay = useMemo(() => {
        if (trainer.salary) {
            return Decimal(trainer.salary.totalAmount).minus(trainer.salary.paidAmount).toNumber();
        }
        return trainer.maxAmountToPay;
    }, [trainer]);

    const handleInputChange = useCallback((id, value, maxAmountToPay) => {
        setIsDisabled(!isDisabled);
        handleChange(id, value, maxAmountToPay);
    }, [handleChange, isDisabled]);

    if (!trainer) return null;

    return (
        <StyledElement key={trainer.id}>
            <StyledText
                hasdeclaredfontsize="24px"
                hasdeclaredfontweight="600"
                hasdeclaredlineheight="1.4em"
                hasdeclaredfontcolor="#6786BE"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredtextalign="center"
            >
                {trainer.name}
            </StyledText>
            <StyledText hasdeclaredfontsize="18px" hasdeclaredlineheight="1.4em" hasdeclaredtextalign="center">
            {getString('all_trainer_pay_popup_element_paid')}: {trainer.salary ? trainer.salary.paidAmount : 0} PLN
            </StyledText>
            <StyledCheckboxWrapper>
                <StyledCheckboxDone>
                    <input
                        type="checkbox"
                        id={`pay-full-${trainer.id}`}
                        onChange={() => handleInputChange(trainer.id, maxAmountToPay, maxAmountToPay)}
                    />
                    <label htmlFor={`pay-full-${trainer.id}`}>
                        <CheckboxAccept />
                    </label>
                </StyledCheckboxDone>
                <StyledText
                    hasdeclaredfontsize="18px"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                >
                    {maxAmountToPay.toString()} PLN
                </StyledText>
            </StyledCheckboxWrapper>
            <StyledInputWrapper $disabled={isDisabled}>
                <StyledText
                    hasdeclaredfontsize="18px"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.4em"
                >
                    {getString('all_trainer_pay_popup_element_inna_kwota')}:
                </StyledText>
                <input type="number" disabled={isDisabled} max={maxAmountToPay.toString()} onChange={(e) => {
                    handleChange(trainer.id, e.target.value, maxAmountToPay);
                }} />
                <StyledText
                    hasdeclaredfontsize="14px"
                    hasdeclaredfontcolor="#F74017"
                    hasdeclaredlineheight="1.2em"
                    hasdeclaredtextalign="center"
                    as="h4"
                >
                    {getString('all_trainer_pay_popup_element_to_pole_nie_moze')}
                </StyledText>
            </StyledInputWrapper>
            <div class="red" />
        </StyledElement>
    );
};

export default AllTrainerPayPopupElement;
