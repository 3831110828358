import React, { useState, useEffect } from 'react';
import { getString } from '../../../strings';

import Loader from '../Loader/Loader';
import PlayerList from '../../molecules/Tournament/PlayerList';
import SummaryPlayers from '../../molecules/Tournament/SummaryPlayers';

import { StyledTournamentPlayersContent } from '../../molecules/Tournament/StyledTournamentPlayersContent';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledTextWrapper } from '../../atoms/Tournament/StyledTextWrapper';
import { StyledSummaryPlayersWrapper } from '../../atoms/Tournament/StyledSummaryPlayersWrapper';
import { StyledPlayerElementWrapper } from '../../atoms/Tournament/StyledPlayerElementWrapper';
import { StyledLoaderWrapper } from '../../../styles/sharedStyles';

import useScrollBar from '../../../logic/hooks/useScrollBar';

const TournamentPlayersContent = ({
    event,
    players,
    playersPerCompetition,
    loaded,
    hideLoaderCallback,
}) => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    const has_players =
            players &&
            (players.confirmed?.length || players.reserve?.length || players.unconfirmed?.length),
        noob_count = has_players
            ? players.confirmed.reduce((prev, cur) => prev + (cur.wca_id ? 0 : 1), 0)
            : null;

    // KURWA, pozostałe listy nie wywołują callback'a
    //const player_lists_count = 3,
    const player_lists_count = 1,
        [listsRendered, setListsRendered] = useState(0),
        on_player_list_rendered = () => {
            const newRendered = listsRendered + 1;
            setListsRendered(newRendered);
        };

    useEffect(() => {
        if (!(listsRendered >= player_lists_count)) return;

        hideLoaderCallback?.();
    }, [listsRendered]);

    if (!loaded) {
        return (
            <StyledLoaderWrapper>
                <Loader declaredWidth="250px" declaredHeight="250px" />
            </StyledLoaderWrapper>
        );
    }

    return (
        <StyledTournamentPlayersContent
            iscentertext={event.register_status === 'upcoming' || has_players}
        >
            {event.register_status === 'upcoming' ? (
                <StyledText
                    hasdeclaredpadding="0 20px 0 20px"
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredmaxwidth="600px"
                    hasdeclaredlineheight="1.4em"
                    as="h2"
                >
                    {getString('tournament_tournamentPlayersContent_rejestracjaNaZawody')}
                </StyledText>
            ) : (
                <>
                    <StyledSummaryPlayersWrapper>
                        <SummaryPlayers
                            quantities={playersPerCompetition}
                            totalPlayers={`${players?.confirmed?.length} (${noob_count})`}
                        />
                    </StyledSummaryPlayersWrapper>
                    {has_players ? (
                        <StyledPlayerElementWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                            {players.confirmed?.length ? (
                                <PlayerList
                                    players={players.confirmed}
                                    triggerParentScroll={trigger}
                                    renderingFinishedCallback={on_player_list_rendered}
                                />
                            ) : (
                                <></>
                            )}
                            {players.unconfirmed?.length ? (
                                <>
                                    <StyledText
                                        hasdeclaredmargin="32px 0px 0px 0px"
                                        hasdeclaredpadding="26px"
                                        hasdeclaredfontsize="26px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredmaxwidth="600px"
                                        hasdeclaredlineheight="1.4em"
                                        as="h4"
                                    >
                                        {getString(
                                            'tournament_tournamentPlayersContent_listaOczekujacych'
                                        )}{' '}
                                        ({players.unconfirmed.length})
                                    </StyledText>
                                    <PlayerList
                                        players={players.unconfirmed}
                                        triggerParentScroll={trigger}
                                        renderingFinishedCallback={on_player_list_rendered}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                            {players.reserve?.length ? (
                                <>
                                    <StyledText
                                        hasdeclaredmargin="32px 0px 0px 0px"
                                        hasdeclaredpadding="26px"
                                        hasdeclaredfontsize="26px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredmaxwidth="600px"
                                        hasdeclaredlineheight="1.4em"
                                        as="h4"
                                    >
                                        {getString(
                                            'tournament_tournamentPlayersContent_listaRezerwowa'
                                        )}{' '}
                                        ({players.reserve.length})
                                    </StyledText>
                                    <PlayerList
                                        players={players.reserve}
                                        triggerParentScroll={trigger}
                                        renderingFinishedCallback={on_player_list_rendered}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </StyledPlayerElementWrapper>
                    ) : (
                        <StyledTextWrapper>
                            <StyledText
                                hasdeclaredpadding="0 20px 0 20px"
                                hasdeclaredfontsize="22px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredmaxwidth="600px"
                                hasdeclaredlineheight="1.4em"
                                as="h2"
                            >
                                {getString(
                                    'tournament_tournamentPlayersContent_obecnieNieMaZadnego'
                                )}
                            </StyledText>
                        </StyledTextWrapper>
                    )}
                </>
            )}
        </StyledTournamentPlayersContent>
    );
};

export default TournamentPlayersContent;
