import styled from 'styled-components';

export const StyledAdditionDataElement = styled.div`
    width: 100%;

    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        width: 100%;

        > div {
            &:first-child {
                width: calc(100% - 104px);
                overflow-x: scroll;

                scrollbar-color: transparent transparent;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    height: 0;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: transparent;
                }
            }
        }
    }
`;

export const StyledAdditionDataTextElement = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '25%')};
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;

export const StyledFuckingBtn = styled.div`
    background-color: ${({ isedit }) => (isedit ? '#5FEE5C' : '#6786be')};
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
`;
