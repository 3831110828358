import styled from 'styled-components';

export const StyledScrolContentPayAll = styled.div`
    width: 100%;
    overflow-x: scroll;

    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
`;
