import styled from 'styled-components';

export const StyledContentTournament = styled.div`
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 20px;
    min-height: 650px;

    @media only screen and (max-width: 767px) {
        width: 95%;
    }

    @media only screen and (max-width: 433px) {
        width: 100%;
    }
`;
