import React, { useCallback, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';

import { StyledDataWrapper } from '../../atoms/UserPanelAddRole/StyledDataWrapper';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledDeleteCheckbox } from '../../atoms/UserPanelAddRole/StyledDeleteCheckbox';
import { StyledHeaderElement } from '../../../styles/sharedStyles';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import { getString } from '../../../strings';
import { unassignRole } from '../../../logic/requests/students';
import { StyledSummaryEditElement } from '../../atoms/SummaryEditElement/StyledSummaryEditElement';
import { useQueryClient } from '@tanstack/react-query';

const RoleElement = ({ user, role }) => {
    const queryClient = useQueryClient();
    const [showPopup, setShowPopUp] = useState(false);

    const handleDelete = useCallback(() => {
        if (!showPopup) setShowPopUp(true);

        if (showPopup) {
            unassignRole(user.id, role)
                .then(() => {
                    setShowPopUp(false);
                    queryClient.invalidateQueries({ queryKey: ['users', 'all'] });
                    queryClient.invalidateQueries({ queryKey: ['roles', 'all'] });
                })
                .catch((err) => {
                    setShowPopUp(false);
                    console.error('unassignRole', err);
                });
        }
    }, [user, role, showPopup]);

    return (
        <>
            {showPopup ? (
                <PaymentsPopup
                    title={getString(
                        getString('role_element_czy_na_pewno_usunac_role') +
                            ' ' +
                            getString(`roles_${role}`).toUpperCase() +
                            ' ' +
                            `${getString('role_element_dla_uzytkownika')} ${user.name}?`
                    )}
                    cancelTag={getString('addition_data_element_usun')}
                    onClose={() => setShowPopUp(false)}
                    onCancel={handleDelete}
                />
            ) : null}
            <StyledSummaryEditElement $minusWidth={50}>
                <ScrollSyncPane>
                    <div>
                        <StyledDataWrapper>
                            <StyledHeaderElement $hasWidth="33%">
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {user.name}
                                </StyledText>
                            </StyledHeaderElement>
                            <StyledHeaderElement $hasWidth="33%">
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {user.email}
                                </StyledText>
                            </StyledHeaderElement>
                            <StyledHeaderElement $hasWidth="33%">
                                <StyledText
                                    hasdeclaredfontsize="16px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {user.roles
                                        .map((role) => getString(`roles_${role}`))
                                        .join(', ')}
                                </StyledText>
                            </StyledHeaderElement>
                        </StyledDataWrapper>
                    </div>
                </ScrollSyncPane>
                <StyledDeleteCheckbox onClick={handleDelete}>
                    <CloseIcon />
                </StyledDeleteCheckbox>
            </StyledSummaryEditElement>
        </>
    );
};

export default RoleElement;
