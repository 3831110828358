import React from 'react';

import { StyledText } from '../../atoms/Text/StyledText';

const UserPanelHeadingText = ({ text }) => (
    <StyledText
        hasdeclaredpadding="10px 20px 30px 0"
        hasdeclaredfontsize="36px"
        hasdeclaredfontweight="700"
        hasdeclaredlineheight="1.4em"
        as="h2"
    >
        {text}
    </StyledText>
);

export default UserPanelHeadingText;
