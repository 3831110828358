import React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { StyledFlag, StyledEditInputElement } from '../../../styles/sharedStyles';
import {
    StyledSubjectDataElement,
    StyledSubjectDataTextElement,
} from './SubjectDataElement.styles';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';

import Flag from 'react-world-flags';
import { getLang } from '../../../strings';

const SubjectDataElement = ({ lang, isEdit, errors, register, name, value }) => {
    return (
        <>
            <StyledSubjectDataElement>
                <ScrollSyncPane>
                    <div>
                        {isEdit ? (
                            <StyledEditWrapper>
                                <StyledEditInputElement $hasDeclaredWidth="10%">
                                    <StyledFlag $hasDeclaredMarginLeft="20px">
                                        <Flag code={lang} alt={getLang().toUpperCase()} />
                                    </StyledFlag>
                                </StyledEditInputElement>
                                <StyledEditInputElement
                                    error={errors?.[name]}
                                    $isFlex
                                    $hasDeclaredWidth="90%"
                                >
                                    <input type="text" {...register(name)} />
                                </StyledEditInputElement>
                            </StyledEditWrapper>
                        ) : (
                            <StyledGlobalUserPanelWrapper>
                                <StyledSubjectDataTextElement $hasDeclaredWidth="10%">
                                    <StyledFlag>
                                        <Flag code={lang} />
                                    </StyledFlag>
                                </StyledSubjectDataTextElement>
                                <StyledSubjectDataTextElement $isFlex $hasDeclaredWidth="90%">
                                    <StyledText
                                        hasdeclaredfontsize="13px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredpadding="0 0 0 15px"
                                    >
                                        {value(name)}
                                    </StyledText>
                                </StyledSubjectDataTextElement>
                            </StyledGlobalUserPanelWrapper>
                        )}
                    </div>
                </ScrollSyncPane>
            </StyledSubjectDataElement>
        </>
    );
};

export default SubjectDataElement;
