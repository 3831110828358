import React, { useMemo, useCallback, useState, useEffect } from 'react';

import UserPanelRoleElement from '../UserPanelRoleElement/UserPanelRoleElement';
import ErrorText from '../../atoms/ErrorText/ErrorText.js';

import { StyledUserPanelAddRoleComponent } from '../../atoms/UserPanelAddRole/StyledUserPanelAddRoleComponent';
import { StyledAddRoleButtons } from '../../atoms/UserPanelAddRole/StyledAddRoleButtons';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledSelectsWrapper } from '../../atoms/UserPanelAddRole/StyledSelectsWrapper';
import { StyledSelect } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledUSerPanelRoleElements } from '../../atoms/UserPanelAddRole/StyledUSerPanelRoleElements';
import { StyledSuccesMessage } from '../../atoms/TrackHours/StyledSuccesMessage';
import { StyledInfo } from '../../atoms/TrackHours/StyledInfo';
import { StyledRowInfo } from '../../atoms/TrackHours/StyledRowInfo';

import { getString } from '../../../strings';
import { useRef } from 'react';
import { assignRole } from '../../../logic/requests/students';
import { useQueryClient } from '@tanstack/react-query';

const UserPanelAddRoleComponent = ({ users, roles, trigger }) => {
    const queryClient = useQueryClient();
    const [errors, setErrors] = useState({});
    const [submitSuccess, setSubmitSuccess] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const formValues = {
        user: useRef(),
        role: useRef(),
    };

    const rolesWithUsers = useMemo(
        () =>
            users.reduce((prev, cur) => {
                cur.roles.forEach((role) => {
                    if (!prev[role]) prev[role] = [];

                    prev[role].push(cur);
                });
                return prev;
            }, {}),
        [users]
    );

    const validateForm = useCallback(() => {
        const user_id = parseInt(formValues.user.current?.value);
        const role_id = formValues.role.current?.value;
        const user = users.find((user) => user.id === user_id);

        const newErrors = {
            user: !user ? true : false,
            roles: !roles.some((role) => role === role_id) ? true : false,
            rolesIncludes: user?.roles?.includes(role_id) ? true : false,
        };

        setErrors(newErrors);
    }, [users, roles]);

    useEffect(() => {
        if (submitSuccess) {
            setErrors({});
            const timeout = setTimeout(() => setSubmitSuccess(undefined), 3000);
            return () => clearTimeout(timeout);
        }
    }, [submitSuccess, setSubmitSuccess]);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            setIsSubmitted(true);
            validateForm();

            if (Object.values(errors).some((error) => error)) return;

            const user_id = parseInt(formValues.user.current?.value);
            const role_id = formValues.role.current?.value;
            const user = users.find((user) => user.id === user_id);

            assignRole(user_id, role_id)
                .then((res) => {
                    e.target.reset();
                    setSubmitSuccess({
                        who: user.name,
                        email: user.email,
                        role: getString(`roles_${role_id}`),
                    });
                    queryClient.invalidateQueries({ queryKey: ['users', 'all'] });
                    queryClient.invalidateQueries({ queryKey: ['roles', 'all'] });
                    setIsSubmitted(false);
                })
                .catch((err) => {
                    console.error('assignRole', err);
                });
        },
        [users, roles, errors, validateForm]
    );

    return (
        <StyledUserPanelAddRoleComponent onSubmit={handleSubmit}>
            <form onSubmit={handleSubmit}>
                <StyledSelectsWrapper>
                    <div>
                        <label>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('UserPanelTrainerAddRoleComponentUser')}:
                            </StyledText>
                        </label>
                        <StyledSelect
                            error={isSubmitted && errors.user}
                            type="select"
                            ref={formValues.user}
                            onChange={validateForm}
                        >
                            <option value="">
                                - {getString('UserPanelAddRole__form__select_user')} -
                            </option>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.lastname} {user.firstname} ({user.email})
                                </option>
                            ))}
                        </StyledSelect>
                        {errors.user ? <ErrorText text="To pole jest wymagane" /> : null}
                    </div>
                    <div>
                        <label>
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('UserPanelTrainerAddRoleComponentRole')}:
                            </StyledText>
                        </label>
                        <StyledSelect
                            error={isSubmitted && errors.roles}
                            type="select"
                            ref={formValues.role}
                            defaultValue="student"
                            onChange={validateForm}
                        >
                            {roles.map((role) => (
                                <option key={role} value={role}>
                                    {getString(`roles_${role}`)}
                                </option>
                            ))}
                        </StyledSelect>
                        {errors.roles ? <ErrorText text="To pole jest wymagane" /> : null}
                    </div>
                </StyledSelectsWrapper>
                <StyledAddRoleButtons $hasError={errors.rolesIncludes}>
                    <StyledButton
                        hasdeclaredfontweight="600"
                        hasdeclaredfontsize="22px"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        hasdeclaredmargin="20px 0 0 0"
                        hasdeclaredbgcolor="#6786be"
                        whileHover={{ scale: 0.95 }}
                        type="submit"
                    >
                        {getString('UserPanelTrainerAddRoleComponentSave')}
                    </StyledButton>
                    <StyledButton
                        hasdeclaredfontweight="600"
                        hasdeclaredfontsize="22px"
                        hasdeclaredtextalign="center"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        hasdeclaredmargin="20px 0 0 0"
                        hasdeclaredbgcolor="#ee745c"
                        type="reset"
                        className="hover-red-effect"
                    >
                        {getString('UserPanelTrainerAddRoleComponentCancel')}
                    </StyledButton>
                </StyledAddRoleButtons>
                {errors.rolesIncludes ? (
                    <ErrorText text="Ten użytkownik posiada podaną rolę" />
                ) : null}
            </form>
            {submitSuccess ? (
                <StyledSuccesMessage>
                    <StyledText
                        hasdeclaredfontcolor="#6AB95D"
                        hasdeclaredpadding="0 0 20px"
                        hasdeclaredfontsize="28px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        hasdeclaredtexttransform="uppercase"
                        as="p"
                    >
                        {getString('UserPanelAddRole__form_submit__succ')}
                    </StyledText>
                    <StyledInfo>
                        <StyledRowInfo>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredfontcolor="#6786be"
                                >
                                    {getString(
                                        'UserPanelTrainerTrackHours__form_submit_after__for_who'
                                    )}
                                    :
                                </StyledText>
                                <StyledText hasdeclaredfontsize="20px" hasdeclaredfontweight="600">
                                    {submitSuccess.who}
                                </StyledText>
                            </div>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredfontcolor="#6786be"
                                >
                                    {getString('UserPanelAddRole__form_submit__email')}:
                                </StyledText>
                                <StyledText hasdeclaredfontsize="20px" hasdeclaredfontweight="600">
                                    {submitSuccess.email}
                                </StyledText>
                            </div>
                            <div>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredfontcolor="#6786be"
                                >
                                    {getString('UserPanelAddRole__form_submit__role')}:
                                </StyledText>
                                <StyledText hasdeclaredfontsize="20px" hasdeclaredfontweight="600">
                                    {submitSuccess.role}
                                </StyledText>
                            </div>
                        </StyledRowInfo>
                    </StyledInfo>
                </StyledSuccesMessage>
            ) : undefined}
            <StyledText
                hasdeclaredpadding="50px 20px 5px 0"
                hasdeclaredfontsize="36px"
                hasdeclaredfontweight="700"
                hasdeclaredlineheight="1.4em"
                as="h2"
            >
                {getString('UserPanelTrainerAddRoleComponentList')}:
            </StyledText>
            <StyledUSerPanelRoleElements>
                {Object.entries(rolesWithUsers).map(([role, users]) => (
                    <UserPanelRoleElement
                        key={role}
                        role={role}
                        users={users}
                        trigger={trigger}
                    />
                ))}
            </StyledUSerPanelRoleElements>
        </StyledUserPanelAddRoleComponent>
    );
};

export default UserPanelAddRoleComponent;
