import styled from 'styled-components';

export const StyledSyncWrapp = styled.div`
    width: 100%;
    overflow: auto;
    scrollbar-color: #6786be rgba(103, 134, 190, 0.3);
    margin-bottom: 40px;
    padding-bottom: 10px;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
        background: rgba(103, 134, 190, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background: #6786be;
        border-radius: 6px;
    }
`;
