import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { getString } from '../../strings';
import { parse } from 'csv-parse/browser/esm/sync';

import LoginLink from '../organisms/LoginLink/LoginLink';
import LogoLink from '../organisms/Logo/LogoLink';
import TournamentStartContent from '../organisms/Tournament/TournamentStartContent';
import TournamentInformationContent from '../organisms/Tournament/TournamentInformationContent';
import TournamentTimetableContent from '../organisms/Tournament/TournamentTimetableContent';
import TournamentRegistrationContent from '../organisms/Tournament/TournamentRegistrationContent';
import TournamentPlayersContent from '../organisms/Tournament/TournamentPlayersContent';
import TournamentRequirementsContent from '../organisms/Tournament/TournamentRequirementsContent';
import TournamentAdditionalInformationsContent from '../organisms/Tournament/TournamentAdditionalInformationsContent';
import TournamentCubes from '../organisms/Tournament/TournamentCubes';
import Loader from '../organisms/Loader/Loader';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import { StyledLoaderMenuWrapper } from '../atoms/Tournament/StyledLoaderMenuWrapper';
import { StyledTournamentStartContentLinkMobile } from '../atoms/Tournament/StyledTournamentStartContentLinkMobile';
import { StyledTournamentPage } from '../atoms/Tournament/StyledTournamentPage';
import { StyledButton } from '../atoms/Button/StyledButton';
import { StyledMenuWrapper } from '../molecules/Tournament/StyledMenuWrapper';
import { StyledText } from '../atoms/Text/StyledText';
import { StyledContentTournament } from '../atoms/Tournament/StyledContentTournament';
import { StyledFacebookIconWrapper } from '../atoms/Tournament/StyledFacebookIconWrapper';
import { StyledRightShape } from '../atoms/Tournaments/StyledRightShape';
import { getEventData, isCurrentUserRegistered } from '../../logic/requests/events';
import useEventRegisterCountdown from '../../hooks/useEventRegisterCountdown';

import { getPlayersForEvent } from '../../logic/requests/events.js';
import { cebulaCompare } from '../../logic/arrays';
import useWindowSize from '../../utils/getWindowSize';
import { ReactComponent as Results } from '../../images/tournaments/results.svg';
import { ReactComponent as FacebookIcon } from '../../images/fbv2.svg';

import REGISTER_STATUS from '../constants/register_status.json';

const Tournament = () => {
    class PageState {
        constructor(state) {
            this.value = state;
        }

        static DEFAULT_LOADING = new PageState(0);
        static LOADING_FINISHED = new PageState(1);
        static LOADING_ERROR = new PageState(2);
    }

    const PagePanel = {
        START: 'start',
        INFO: 'info',
        TIMETABLE: 'schedule',
        REGISTER: 'register',
        PLAYERS: 'competitors',
        REQUIREMENTS: 'requirements',
        ADD_INFO: 'additional-info',
        RESULTS: 'results',
    };

    const width = useWindowSize();
    const navigate = useNavigate();
    const { code, panel: panelParam } = useParams();
    const [event, setEvent] = useState();
    const [players, setPlayers] = useState([]);
    const [playersPerCompetition, setPlayersPerCompetition] = useState({});
    const [pageState, setPageState] = useState(PageState.DEFAULT_LOADING.value);
    const defaultPanel =
        Object.values(PagePanel).find((panel) => panel === panelParam) ?? PagePanel.START;
    const [pagePanel, setPagePanel] = useState(defaultPanel);
    const [playersLoaded, setPlayersLoaded] = useState(false);
    const containerRef = useRef(null);
    const setEventRegisterStatus = useCallback(
        (status) => {
            if (event) {
                setEvent({
                    ...event,
                    register_status: status,
                });
            }
        },
        [event, setEvent]
    );
    const registerText = useEventRegisterCountdown(event ?? {}, setEventRegisterStatus);
    const [registerStatus, setRegisterStatus] = useState({});

    const [playersLoader, setPlayersLoader] = useState(pagePanel === PagePanel.PLAYERS);

    useEffect(() => {
        getEventData(code, { withLongTexts: true })
            .then((res) => {
                setEvent(res);
                setPageState(PageState.LOADING_FINISHED.value);
            })
            .catch((err) => {
                setPageState(PageState.LOADING_ERROR.value);
            });
    }, [code]);

    useEffect(() => {
        if (!event) return;

        getPlayersForEvent(event.id)
            .then((res) => {
                if (Array.isArray(res?.confirmed)) {
                    res.confirmed.sort(
                        (p1, p2) =>
                            cebulaCompare(p1.lastname, p2.lastname) ||
                            cebulaCompare(p1.firstname, p2.firstname)
                    );
                }
                setPlayers(res);
                setPlayersLoaded(true);
                if (!res?.confirmed?.length) setPlayersLoader(false);
            })
            .catch((err) => {
                console.error('getPlayersForEvent', err);
            });
    }, [event]);

    useEffect(() => {
        if (!event) return;

        isCurrentUserRegistered(event.id)
            .then((res) => {
                setRegisterStatus(res);
            })
            .catch((err) => {
                console.error('isCurrentUserRegistered', err);
            });
    }, [event]);

    useEffect(() => {
        if (!event) return;

        isCurrentUserRegistered(event.id)
            .then((res) => {
                setRegisterStatus(res);
            })
            .catch((err) => {
                console.error('isCurrentUserRegistered', err);
            });
    }, [event]);

    useEffect(() => {
        if (event && players?.confirmed?.length) {
            const tmp = {};
            event.competitions.forEach((competition) => (tmp[competition] = 0));

            players.confirmed.forEach((player) =>
                player.competitions.forEach((competition) => tmp[competition]++)
            );

            setPlayersPerCompetition(tmp);
        }
    }, [players]);

    useEffect(() => {
        if (pageState !== PageState.LOADING_FINISHED.value) return;

        if (pagePanel === PagePanel.REGISTER && event.register_status === 'closed')
            setPagePanel(PagePanel.START);
    }, [event, pagePanel]);

    useEffect(() => {
        if (pagePanel !== defaultPanel) {
            navigate(`/competition/${code}/${pagePanel}`);
        }
    }, [pagePanel]);

    const scrollToContent = () => {
        return width > 767
            ? null
            : setTimeout(
                  () =>
                      containerRef.current.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                          inline: 'nearest',
                      }),
                  100
              );
    };

    const handleClickMenuButton = (panel) => {
        const newPanel = pagePanel === panel.key ? PagePanel.START : panel.key;

        setPagePanel(newPanel);

        if (newPanel === PagePanel.START) {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        } else if (newPanel === PagePanel.PLAYERS && players.confirmed.length) {
            setPlayersLoader(true);
        } else {
            scrollToContent();
        }
    };

    return (
        <>
            <HelmetTemplate
                title={getString('seo_tournaments_title')}
                desc={getString('seo_tournaments_desc')}
                ogTitle={getString('seo_tournaments_twitter_title')}
            />
            <StyledTournamentPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <StyledRightShape />
                <TournamentCubes />
                <LoginLink />
                <LogoLink />
                <StyledContentTournament ref={containerRef}>
                    {(() => {
                        switch (pageState) {
                            case PageState.DEFAULT_LOADING.value:
                                return <Loader declaredWidth="250px" declaredHeight="250px" />;
                            // idź dalej, zwracamy któryś z paneli
                            case PageState.LOADING_FINISHED.value:
                                break;
                            // TODO: coś lepszego tutaj?
                            case PageState.LOADING_ERROR.value:
                            default:
                                return null;
                        }
                        switch (pagePanel) {
                            case PagePanel.START:
                                return <TournamentStartContent event={event} />;
                            case PagePanel.INFO:
                                return <TournamentInformationContent event={event} />;
                            case PagePanel.TIMETABLE:
                                return event.schedule ? (
                                    <TournamentTimetableContent schedule={parse(event.schedule)} />
                                ) : (
                                    <StyledText
                                        hasdeclaredfontsize="44px"
                                        hasdeclaredfontweight="bold"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredpadding="0 0 38px 0"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredmaxwidth="500px"
                                        hasdeclaredlineheight="1.4em"
                                        as="h1"
                                    >
                                        {getString('pages_tournament_brakHarmonogramu')}
                                    </StyledText>
                                );
                            case PagePanel.REGISTER:
                                return (
                                    <TournamentRegistrationContent
                                        event={event}
                                        registerText={registerText}
                                        registerStatus={registerStatus}
                                        setRegisterStatus={setRegisterStatus}
                                    />
                                );
                            case PagePanel.PLAYERS:
                                return (
                                    <TournamentPlayersContent
                                        event={event}
                                        players={players}
                                        playersPerCompetition={playersPerCompetition}
                                        loaded={playersLoaded}
                                        hideLoaderCallback={() => {
                                            setPlayersLoader(false);
                                            scrollToContent();
                                        }}
                                    />
                                );
                            case PagePanel.REQUIREMENTS:
                                return <TournamentRequirementsContent event={event} />;
                            case PagePanel.ADD_INFO:
                                return <TournamentAdditionalInformationsContent event={event} />;
                            case PagePanel.RESULTS:
                                return event?.code ? (
                                    <Navigate to={`/results/${event.code}`} />
                                ) : (
                                    <></>
                                );
                            default:
                                return <></>;
                        }
                    })()}
                </StyledContentTournament>
                <StyledMenuWrapper>
                    {(() => {
                        switch (pageState) {
                            case PageState.DEFAULT_LOADING.value:
                                return (
                                    <StyledLoaderMenuWrapper>
                                        <Loader declaredWidth="200px" declaredHeight="200px" />
                                    </StyledLoaderMenuWrapper>
                                );
                            case PageState.LOADING_FINISHED.value:
                                return (
                                    <>
                                        <StyledText
                                            hasdeclaredfontsize="44px"
                                            hasdeclaredfontweight="bold"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredpadding="0 0 38px 0"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredmaxwidth="500px"
                                            hasdeclaredlineheight="1.3em"
                                            as="h1"
                                        >
                                            {event.name}
                                        </StyledText>
                                        {event?.status === 'live' ? (
                                            <StyledTournamentStartContentLinkMobile
                                                href={`https://live.worldcubeassociation.org/link/competitions/${event.wca_id}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <StyledButton
                                                    hasdeclaredfontsize="44px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredfontcolor="#fff"
                                                    hasdeclaredcursor="pointer"
                                                >
                                                    <Results />
                                                    {getString(
                                                        'tournament_tournamentRequirementsContent_wynikiLive'
                                                    )}
                                                </StyledButton>
                                            </StyledTournamentStartContentLinkMobile>
                                        ) : (
                                            <></>
                                        )}
                                        {[
                                            {
                                                key: PagePanel.INFO,
                                                text: getString('pages_tournament_informacje'),
                                            },
                                            {
                                                key: PagePanel.TIMETABLE,
                                                text: getString('pages_tournament_harmonogram'),
                                            },
                                            event?.register_status !== 'closed' ||
                                            (registerStatus.value &&
                                                !registerStatus.reserve &&
                                                registerStatus.register_status ===
                                                    REGISTER_STATUS.TRANSACTION_PENDING)
                                                ? {
                                                      key: PagePanel.REGISTER,
                                                      text: getString(
                                                          'pages_tournament_rejestracja'
                                                      ),
                                                  }
                                                : undefined,
                                            event?.type?.id === 'online' &&
                                            ['live', 'over'].includes(event?.status)
                                                ? {
                                                      key: PagePanel.RESULTS,
                                                      text: getString('pages_tournament_wyniki'),
                                                  }
                                                : undefined,
                                            {
                                                key: PagePanel.PLAYERS,
                                                text: getString('pages_tournament_zawodnicy'),
                                            },
                                            {
                                                key: PagePanel.REQUIREMENTS,
                                                text: getString('pages_tournament_wymagania'),
                                            },
                                            {
                                                key: PagePanel.ADD_INFO,
                                                text: getString(
                                                    'pages_tournament_informacjeDodatkowe'
                                                ),
                                            },
                                        ]
                                            .filter((panel) => panel)
                                            .map((panel) => (
                                                <StyledButton
                                                    key={panel.key}
                                                    hasdeclaredbgcolor={
                                                        pagePanel === panel.key
                                                            ? '#592E28'
                                                            : '#EE745C'
                                                    }
                                                    onClick={() => handleClickMenuButton(panel)}
                                                >
                                                    {panel.text}
                                                </StyledButton>
                                            ))}
                                        {event?.link && (
                                            <StyledFacebookIconWrapper>
                                                <a
                                                    aria-label={getString(
                                                        'seo_aria_label_przejdz_do_strony'
                                                    )}
                                                    href={event.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <FacebookIcon />
                                                    <StyledText
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="0"
                                                        hasdeclaredfontweight="600"
                                                    >
                                                        WYDARZENIE
                                                    </StyledText>
                                                </a>
                                            </StyledFacebookIconWrapper>
                                        )}
                                        {width > 767 ? null : playersLoader ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Loader />
                                            </div>
                                        ) : null}
                                    </>
                                );
                            case PageState.LOADING_ERROR.value:
                                return (
                                    <StyledText
                                        hasdeclaredfontsize="54px"
                                        hasdeclaredfontweight="bold"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredpadding="0 0 38px 0"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredmaxwidth="500px"
                                        as="h1"
                                    >
                                        {getString('pages_tournament_bladWczytywania')}
                                    </StyledText>
                                );
                            default:
                                return <></>;
                        }
                    })()}
                </StyledMenuWrapper>
            </StyledTournamentPage>
        </>
    );
};

export default Tournament;
