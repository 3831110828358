import styled from "styled-components";

export const StyledTournamentLogoWrapper = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
        max-width: 50%;
        max-height: 300px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 1235px) {
        img {
            width: 50%;
        }
    }
`;
