import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sessionService } from 'redux-react-session';
import { getString } from '../../strings';

import LogoLink from '../organisms/Logo/LogoLink';
import LoginLink from '../organisms/LoginLink/LoginLink';
import SeriesMenu from '../organisms/Series/SeriesMenu';
import Loader from '../organisms/Loader/Loader';
import SmallLoader from '../organisms/Loader/SmallLoader';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import SeriesButton from '../molecules/Tournaments/SeriesButton';
import SeriesResults from '../organisms/Series/SeriesResults';
import SeriesCubes from '../molecules/Series/SeriesCubes';

import { StyledSeriesPage } from '../atoms/Series/StyledSeriesPage';
import { StyledLoadingTextWrapper } from '../atoms/Series/StyledLoadingTextWrapper';

import useWindowSize from '../../utils/getWindowSize';
import { StyledText } from '../atoms/Text/StyledText';

import {
    getAllResultsForEvent,
    getAllResultsForSeries,
    getSeries,
} from '../../logic/requests/series.js';
import classification_points from '../constants/classification_points.json';
import { defaultCompare } from '../../logic/arrays.js';

import { ACTIVERESULTSTYPE } from '../constants/Series';

const Series = () => {
    class PageState {
        constructor(state) {
            this.value = state;
        }

        static LOADING = new PageState(0);
        static LOADING_FINISHED = new PageState(1);
        static LOADING_ERROR = new PageState(2);
    }
    const [menuState, setMenuState] = useState(PageState.LOADING.value);
    const [firstLoadDone, setFirstLoadDone] = useState(false);

    const [user, setUser] = useState();

    const width = useWindowSize();
    const [series, setSeries] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const loadSeries = useCallback(() => {
        getSeries({ cursorId: series[series.length - 1]?.id || 0 })
            .then((res) => {
                if (res.length) {
                    setSeries(
                        series.concat(
                            res
                                .map((serie) => ({
                                    ...serie,
                                    events: serie?.events?.filter(
                                        // nie uwzględniamy zawodów bez dostępnych wyników
                                        (event) => event?.results_available
                                    ),
                                }))
                                .filter(
                                    // nie uwzględniamy serii bez żadnych zawodów z dostępnymi wynikami
                                    (serie) => Array.isArray(serie?.events) && serie.events.length
                                )
                        )
                    );
                } else {
                    setHasMore(false);
                }
                if (!firstLoadDone) {
                    setMenuState(PageState.LOADING_FINISHED.value);
                    setFirstLoadDone(true);
                }
            })
            .catch((err) => {
                console.error('getSeries', err);
                // nie ukrywamy poprzednio wczytanych serii, jeśli wystąpi błąd przy > 1 ładowaniu
                if (!firstLoadDone) {
                    setMenuState(PageState.LOADING_ERROR.value);
                    setPageState(PageState.LOADING_ERROR.value);
                    setFirstLoadDone(true);
                }
            });
    }, []);

    useEffect(() => {
        if (!firstLoadDone) {
            loadSeries();
        }
    }, [firstLoadDone]);

    const { type, code } = useParams();
    const activeResults =
            type && code
                ? {
                      ...(type === ACTIVERESULTSTYPE.EVENT
                          ? series.reduce((prev, cur) => prev.concat(cur.events), [])
                          : type === ACTIVERESULTSTYPE.SERIES
                          ? series
                          : []
                      ).find((thing) => thing.code === code),
                      type,
                  }
                : undefined,
        refresh =
            type && code
                ? `${activeResults.type}${activeResults.code}${activeResults.id}`
                : undefined;
    const [pageState, setPageState] = useState(
        refresh ? PageState.LOADING.value : PageState.LOADING_FINISHED.value
    );
    const navigate = useNavigate();
    const setActiveResults = ({ code, type }) =>
        navigate(code && type ? `/series/${type}/${code}` : '/series');
    const [leagueNumbers, setLeagueNumbers] = useState([]);
    const [results, setResults] = useState();

    useEffect(() => {
        if (!activeResults?.id) {
            return;
        }

        switch (activeResults?.type) {
            case ACTIVERESULTSTYPE.SERIES:
                getAllResultsForSeries(activeResults.id)
                    .then((res) => {
                        const results = {},
                            league_numbers = [];
                        for (let event of res) {
                            for (let score of event.scores) {
                                // FIXME: Tutaj kod się wykładał na dev na rekordzie bez EventResultDetail - na produkcji nie powinno się to zdażyć
                                if (!score.competitions) continue;

                                // Do nagłówka - i według nich będziemy sortować wyniki w wierszu
                                if (!league_numbers.includes(event.league_number))
                                    league_numbers.push(event.league_number);

                                let result = score.competitions.length
                                    ? score.competitions.reduce(
                                          // suma wszystkich miejsc we wszystkich konkurencjach
                                          (prev, cur) =>
                                              prev +
                                              (classification_points.place[cur.place - 1] ?? 0),
                                          0
                                      ) +
                                      // rekordy
                                      (score.noob
                                          ? classification_points.achievements.firstEvent
                                          : // PB nie liczą się dla nowicjuszy - bo układają pierwszy raz
                                            score.pb_count *
                                            classification_points.achievements.records
                                                .personalBest) +
                                      // pozostałe liczą się zawsze
                                      (score.nr_count *
                                          classification_points.achievements.records
                                              .nationalRecord +
                                          score.cr_count *
                                              classification_points.achievements.records
                                                  .continentalRecord +
                                          score.wr_count *
                                              classification_points.achievements.records
                                                  .worldRecord)
                                    : '-';

                                if (!results[score.user_id])
                                    results[score.user_id] = {
                                        id: score.user_id,
                                        firstname: score.firstname,
                                        lastname: score.lastname,
                                        events: {},
                                    };
                                results[score.user_id].events[event.league_number] = result;
                            }
                        }

                        // suma wyników ze wszystkich zawodów
                        for (let user of Object.values(results)) {
                            user.total = Object.values(user.events).reduce(
                                (prev, cur) => (typeof cur === 'number' ? prev + cur : prev),
                                0
                            );
                        }

                        // sortowanko
                        const ret = Object.values(results).sort(
                            (a, b) =>
                                b.total - a.total ||
                                defaultCompare(a.lastname, b.lastname) ||
                                defaultCompare(a.firstname, b.firstname)
                        );

                        // numery miejsc
                        ret[0].place = 1;
                        for (let i = 1; i < ret.length; i++) {
                            ret[i].place =
                                ret[i - 1].total === ret[i].total
                                    ? // ex aequo - takie samo miejsce jak ten przed nim
                                      ret[i - 1].place
                                    : ret[i - 1].place + 1;
                        }

                        setLeagueNumbers(league_numbers.sort((a, b) => a - b));
                        setResults(ret);

                        if (pageState === PageState.LOADING.value)
                            setPageState(PageState.LOADING_FINISHED.value);
                    })
                    .catch((err) => {
                        console.error('getAllResultsForSeries', err);
                        if (pageState === PageState.LOADING.value)
                            setPageState(PageState.LOADING_ERROR.value);
                    });
                break;
            case ACTIVERESULTSTYPE.EVENT:
                getAllResultsForEvent(activeResults.id)
                    .then((res) => {
                        // TODO: lepsza nazwa dla 'leagueNumbers' :(
                        const results = {},
                            leagueNumbers = [];
                        for (let score of res.scores) {
                            // FIXME: Tutaj kod się wykładał na dev na rekordzie bez EventResultDetail - na produkcji nie powinno się to zdażyć
                            if (!score.competitions) continue;

                            // Do nagłówka - i według nich będziemy sortować wyniki w wierszu
                            for (let competition of score.competitions)
                                if (!leagueNumbers.includes(competition.id))
                                    leagueNumbers.push(competition.id);

                            let bonus =
                                    // rekordy
                                    (score.noob
                                        ? classification_points.achievements.firstEvent
                                        : // PB nie liczą się dla nowicjuszy - bo układają pierwszy raz
                                          score.pb_count *
                                          classification_points.achievements.records.personalBest) +
                                    // pozostałe liczą się zawsze
                                    (score.nr_count *
                                        classification_points.achievements.records.nationalRecord +
                                        score.cr_count *
                                            classification_points.achievements.records
                                                .continentalRecord +
                                        score.wr_count *
                                            classification_points.achievements.records.worldRecord),
                                competitions = score.competitions.map((competition) => ({
                                    ...competition,
                                    score: classification_points.place[competition.place - 1] ?? 0,
                                })),
                                total =
                                    competitions.reduce(
                                        // suma wszystkich miejsc we wszystkich konkurencjach
                                        (prev, cur) => prev + cur.score,
                                        0
                                    ) + bonus;

                            results[score.user_id] = {
                                id: score.user_id,
                                firstname: score.firstname,
                                lastname: score.lastname,
                                competitions,
                                bonus,
                                total,
                            };
                        }

                        // sortowanko
                        const ret = Object.values(results).sort(
                            (a, b) =>
                                b.total - a.total ||
                                defaultCompare(a.lastname, b.lastname) ||
                                defaultCompare(a.firstname, b.firstname)
                        );

                        // numery miejsc
                        ret[0].place = 1;
                        for (let i = 1; i < ret.length; i++) {
                            ret[i].place =
                                ret[i - 1].total === ret[i].total
                                    ? // ex aequo - takie samo miejsce jak ten przed nim
                                      ret[i - 1].place
                                    : ret[i - 1].place + 1;
                        }

                        setLeagueNumbers(leagueNumbers.sort());
                        setResults(ret);

                        if (pageState === PageState.LOADING.value)
                            setPageState(PageState.LOADING_FINISHED.value);
                    })
                    .catch((err) => {
                        console.error('getAllResultsForEvent', err);
                        // TODO: jakiś lepszy error-handling?
                        if (pageState === PageState.LOADING.value)
                            setPageState(PageState.LOADING_ERROR.value);
                    });
                break;
            default:
                console.warn(
                    'pages/Series: Attempted to set results view to an unknown view type',
                    activeResults?.type
                );
                break;
        }
    }, [refresh]);

    useEffect(() => {
        if (!firstLoadDone) return;
        if (code && type && !activeResults?.id) {
            loadSeries();
        }
    }, [code, type, activeResults, loadSeries]);

    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const changeActiveResults = useCallback(
        (val) => {
            if (val) {
                setPageState(PageState.LOADING.value);
                setActiveResults(val);
            } else {
                setResults(null);
                setActiveResults({ type: false, code: false });
            }
        },
        [setPageState, setActiveResults]
    );

    const pageContent = {
        [PageState.LOADING.value]: () => (
            <StyledLoadingTextWrapper>
                <Loader declaredWidth="120px" declaredHeight="120px" />
            </StyledLoadingTextWrapper>
        ),
        [PageState.LOADING_FINISHED.value]: () => (
            <SeriesResults
                activeResults={activeResults}
                leagueNumbers={leagueNumbers}
                results={results}
                user={user}
            />
        ),
        [PageState.LOADING_ERROR.value]: () => (
            <StyledLoadingTextWrapper>
                <StyledText
                    hasdeclaredfontsize="32px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredpadding="80px 20px 0 20px"
                >
                    {getString('pages_series_bladWczytywania')}
                </StyledText>
            </StyledLoadingTextWrapper>
        ),
    };

    const menuContent = {
        [PageState.LOADING.value]: () => (
            <StyledLoadingTextWrapper>
                <SmallLoader declaredWidth="100px" declaredHeight="100px" />
            </StyledLoadingTextWrapper>
        ),
        [PageState.LOADING_FINISHED.value]: () => (
            <SeriesMenu
                series={series}
                loadSeries={loadSeries}
                hasMore={hasMore}
                activeResults={activeResults}
                setActiveResults={changeActiveResults}
            />
        ),
        [PageState.LOADING_ERROR.value]: () => null,
    };

    return (
        <>
            <HelmetTemplate
                title={getString('seo_series_title')}
                desc={getString('seo_series_desc')}
                ogTitle={getString('seo_series_twitter_title')}
            />
            <StyledSeriesPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <LogoLink />
                <LoginLink />
                <SeriesCubes />
                {width > 767 ? (
                    <>
                        {menuContent[menuState]()}
                        {pageContent[pageState]()}
                    </>
                ) : (
                    <>
                        {menuContent[menuState]()}
                        {pageContent[pageState]()}
                    </>
                )}
                <SeriesButton isSeriesPage={true} />
            </StyledSeriesPage>
        </>
    );
};

export default Series;
