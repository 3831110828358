import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledSeriesResultsElementHeadingResults = styled.div`
    display: flex;
    justify-content: ${({ isscrollbar }) => (isscrollbar ? 'flex-start' : 'center')};
    align-items: center;
    width: 50%;
    overflow-x: scroll;

    ${scrollBlueStyles};

    > p {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;
        min-height: 40px;
        margin-right: 5px;
        height: 100%;
    }

    @media only screen and (max-width: 1100px) {
        > p {
            font-size: 15px;
        }
    }
`;
