import React from 'react';

import { StyledSeriesCubes } from '../../atoms/Series/StyledSeriesCubes';

import { StyledCube444 } from '../../atoms/RegisterCube/StyledCube444';
import { StyledCubeMinx } from '../../atoms/RegisterCube/StyledCubeMinx';

const SeriesCubes = () => {
    return (
        <StyledSeriesCubes>
            <StyledCube444 />
            <StyledCubeMinx />
        </StyledSeriesCubes>
    );
};

export default SeriesCubes;
