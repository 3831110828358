import styled from 'styled-components';

export const StyledTitleWrapper = styled.div`
    width: 100%;
    display: grid;
    margin-bottom: 4px;
    grid-template-columns: 64px 64px repeat(6, 1fr);
    grid-template-areas: 'start koniec konkurencja runda format cutoff limit przechodzi';

    > div {
        padding: 0 4px;

        &:nth-child(1) {
            grid-area: start;
        }

        &:nth-child(2) {
            grid-area: koniec;
        }

        &:nth-child(3) {
            grid-area: konkurencja;
        }

        &:nth-child(4) {
            grid-area: runda;
        }

        &:nth-child(5) {
            grid-area: format;
        }

        &:nth-child(6) {
            grid-area: cutoff;
        }

        &:nth-child(7) {
            grid-area: limit;
        }

        &:nth-child(8) {
            grid-area: przechodzi;
        }
    }

    @media only screen and (max-width: 1179px) {
        grid-template-columns: 49px 49px repeat(6, 1fr);
    }

    @media only screen and (max-width: 1017px) {
        grid-template-columns: 39px 39px repeat(6, 1fr);
    }

    @media only screen and (max-width: 871px) {
        grid-template-columns: 34px 34px repeat(6, 1fr);
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: 64px 64px repeat(6, 1fr);
    }

    @media only screen and (max-width: 600px) {
        grid-template-areas: 'konkurencja runda format cutoff limit przechodzi';
        grid-template-columns: repeat(6, 1fr);

        > div {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: none;
            }
        }
    }
`;
