import React from 'react';

import { StyledCube444 } from '../../atoms/Competitions/StyledCube444';
import { StyledCubeMinx } from '../../atoms/Competitions/StyledCubeMinx';
import { StyledMobileContactCube } from '../../atoms/Contact/StyledContactForm';

const MobileContactCube = () => {
    return (
        <StyledMobileContactCube>
            <StyledCube444 />
            <StyledCubeMinx />
        </StyledMobileContactCube>
    );
};

export default MobileContactCube;
