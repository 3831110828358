import React, { useEffect, useRef, useState } from 'react'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Link } from "react-router-dom";
import { getString } from "../../../strings";
import { StyledUserPanelTournamentResults } from "../../atoms/UserPanelTournamentResults/StyledUserPanelTournamentResults";
import { StyledUserPanelResultsIcons } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsIcons";
import { StyledUserPanelResultsPlace } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsPlace";
import { StyledUserPanelResults } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResults";
import { StyledWhitePlaceDiv } from "../../atoms/UserPanelTournamentResults/StyledWhitePlaceDiv";
import { StyledWhiteResultsDiv } from "../../atoms/UserPanelTournamentResults/StyledWhiteResultsDiv";
import { StyledUserPanelFirstDiv } from "../../atoms/UserPanelTournamentResults/StyledUserPanelFirstDiv";
import { StyledUserPanelResultsFirstDiv } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsFirstDiv";
import { StyledUserPanelResultsSum } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsSum";
import { StyledUserPanelResultsBonus } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsBonus";
import { StyledUserPanelResultsSumWrapper } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsSumWrapper";
import { StyledUserPanelResultsFirstDivIcons } from "../../atoms/UserPanelTournamentResults/StyledUserPanelResultsFirstDivIcons";
import { StyledUserPanelResultsIconsWrapper } from "../../molecules/UserPanelTournamentResults/StyledUserPanelResultsIconsWrapper";

import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButtonsWrapper } from "../../molecules/UserPanelTournamentResults/StyledButtonsWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";
import CompetitionElement from '../../molecules/CompetitionElement/CompetitionElement';

import { getResultsForUser } from "../../../logic/requests/events.js";
import classification_points from '../../constants/classification_points.json';

const UserPanelTournamentResults = ({ eventId, close }) => {
    class ComponentState {
        constructor(state) {
            this.value = state;
        }

        static DEFAULT_LOADING = new ComponentState(0);
        static LOADING_FINISHED = new ComponentState(1);
        static LOADING_ERROR = new ComponentState(2);
    }
    const [componentState, setComponentState] = useState(ComponentState.DEFAULT_LOADING.value);

    const [results, setResults] = useState();
    const [bonus, setBonus] = useState();
    const [total, setTotal] = useState();
    useEffect(
        () => {
            if (!eventId)
                return;
            console.log("eventId", eventId);

            getResultsForUser(eventId)
                .then(
                    res => {
                        console.log("getResultsForUser", res);
                        setResults(res);
                        setComponentState(ComponentState.LOADING_FINISHED.value);
                    }
                )
                .catch(
                    err => {
                        console.log("getResultsForUser", err);
                    }
                )
            ;
        },
        [ eventId ]
    );
    useEffect(
        () => {
            if (!results)
                return;

            let bonus = results.noob ? (
                classification_points.achievements.firstEvent
            ) : (
                // PB nie liczą się dla nowicjusz - bo układają pierwszy raz
                results.pb_count * classification_points.achievements.records.personalBest
            );
            // pozostałe liczą się zawsze
            bonus += (
                (
                    results.nr_count * classification_points.achievements.records.nationalRecord
                ) + (
                    results.cr_count * classification_points.achievements.records.continentalRecord
                ) + (
                    results.wr_count * classification_points.achievements.records.worldRecord
                )
            );

            const total = results.competitions.reduce(
                (prev, cur) => prev + classification_points.place[cur.place-1],
                0
            ) + (
                bonus
            );

            setBonus(bonus);
            setTotal(total);
        },
        [ results ]
    );

    const ref = useRef();
    useEffect(
        () => {
            if (componentState === ComponentState.LOADING_FINISHED.value)
                ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
        },
        [ componentState ]
    );

    const componentContent = {
        [ComponentState.DEFAULT_LOADING.value]: () => (
            <StyledText
                hasdeclaredfontsize="32px"
                hasdeclaredfontcolor="#000"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredtextalign="center"
                hasdeclaredfontweight="700"
                hasdeclaredmargin="24px 0 0 0"
            >
                {getString("userPanel_userPanelTournamentResults_wczytywanie")}
            </StyledText>
        ),
        [ComponentState.LOADING_FINISHED.value]: () => (
            <ScrollSync>
                <StyledUserPanelTournamentResults>
                    <ScrollSyncPane>
                        <div>
                            <StyledUserPanelResultsIcons>
                                <StyledUserPanelResultsFirstDivIcons></StyledUserPanelResultsFirstDivIcons>
                                <StyledUserPanelResultsIconsWrapper>
                                    {
                                        results.competitions.map(
                                            comp => <CompetitionElement competition={comp.id} />
                                        )
                                    }
                                </StyledUserPanelResultsIconsWrapper>
                            </StyledUserPanelResultsIcons>
                            <StyledUserPanelResultsPlace>
                                <StyledUserPanelFirstDiv>
                                    <StyledText
                                        hasdelcaredfontsize="16px"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontweight="700"
                                        hascursor="pointer"
                                    >
                                        {getString("userPanel_userPanelTournamentResults_miejsce")}
                                    </StyledText>
                                </StyledUserPanelFirstDiv>
                                <StyledWhitePlaceDiv>
                                    {
                                        results.competitions.map(
                                            comp =>
                                            <StyledText
                                                hasdeclaredfontweight="700"
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredtextalign="center"
                                                hascursor="pointer"
                                                as="p"
                                            >
                                                {comp.place}
                                            </StyledText>
                                        )
                                    }                                   
                                </StyledWhitePlaceDiv>
                            </StyledUserPanelResultsPlace>
                            <StyledUserPanelResults>
                                <StyledUserPanelResultsFirstDiv>
                                    <StyledText
                                        hasdelcaredfontsize="16px"
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredtexttransform="uppercase"
                                        hasdeclaredfontweight="700"
                                        hascursor="pointer"
                                    >
                                        {getString("userPanel_userPanelTournamentResults_wyniki")}
                                    </StyledText>
                                </StyledUserPanelResultsFirstDiv>
                                <StyledWhiteResultsDiv>
                                    {
                                        results.competitions.map(
                                            comp =>
                                            <StyledText
                                                hasdeclaredfontweight="700"
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredtextalign="center"
                                                hascursor="pointer"
                                                as="p"
                                            >
                                                {classification_points.place[comp.place-1]}
                                            </StyledText>
                                        )
                                    }
                                </StyledWhiteResultsDiv>
                                <StyledUserPanelResultsSum>
                                    <StyledUserPanelResultsBonus
                                        headerText={
                                            getString("userPanelTournamentResults_StyledUserPanelResultsBonus_headerText")
                                        }
                                    >
                                        <StyledText
                                            hasdeclaredfontweight="700"
                                            hasdeclaredfontsize="16px"
                                            hascursor="pointer"
                                        >
                                            {bonus}
                                        </StyledText>
                                    </StyledUserPanelResultsBonus>
                                    <StyledUserPanelResultsSumWrapper
                                        headerText={
                                            getString("userPanelTournamentResults_StyledUserPanelResultsSumWrapper_headerText")
                                        }
                                    >
                                        <StyledText
                                            hasdeclaredfontweight="700"
                                            hasdeclaredfontsize="16px"
                                            hascursor="pointer"
                                        >
                                            {total}
                                        </StyledText>
                                    </StyledUserPanelResultsSumWrapper>
                                </StyledUserPanelResultsSum>
                            </StyledUserPanelResults>
                        </div>
                    </ScrollSyncPane>
                    <StyledButtonsWrapper ref={ref}>
                        <StyledButton
                            hasdeclaredfontweight="bold"
                            hasdeclaredfontsize="18px"
                            hasdeclaredtextalign="center"
                            hasdeclaredbgcolor="#ee745c"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredborderradius="25px"
                            hasdeclaredcursor="pointer"
                            onClick={close}
                            className="hover-red-effect"
                        >
                            {getString("userPanel_userPanelTournamentResults_zamknij")}
                        </StyledButton>
                        <Link to="/series">
                            <StyledButton
                                hasdeclaredfontweight="bold"
                                hasdeclaredfontsize="18px"
                                hasdeclaredtextalign="center"
                                hasdeclaredbgcolor="#4E6BAA"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredborderradius="25px"
                                hasdeclaredcursor="pointer"
                                whileHover={{ scale: 0.95 }}
                                onClick={close}
                            >
                                {getString("userPanel_userPanelTournamentResults_klasyfikacja")}
                            </StyledButton>
                        </Link>
                    </StyledButtonsWrapper>
                </StyledUserPanelTournamentResults>
            </ScrollSync>
        ),
        [ComponentState.LOADING_ERROR.value]: () => (
            <StyledText
                hasdeclaredfontsize="32px"
                hasdeclaredfontcolor="#000"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredtextalign="center"
                hasdeclaredfontweight="700"
                hasdeclaredmargin="24px 0 0 0"
            >
                {getString("userPanel_userPanelTournamentResults_wczytywanie2")}
            </StyledText>
        )
    }

    return componentContent[componentState]();
}

export default UserPanelTournamentResults
