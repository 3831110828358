import React, { useState } from 'react';

import { StyledMenuElement } from '../../molecules/Series/StyledMenuElement';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledArrow } from '../../../styles/sharedStyles';
import { StyledGalleryList, StyledGalleryIsOpenWrapper } from '../../atoms/Gallery/StyledGallery';

const GalleryList = ({
    series,
    initialOpen,
    activeEvent,
    setActiveEvent,
    isMobile,
    setActiveClicked,
    isClickedValue,
}) => {
    const [isOpen, setIsOpen] = useState(initialOpen);

    if (!typeof series === 'object') {
        console.warn('GalleryList:', 'got invalid series', series);
        return null;
    }

    const handleClick = () => {
        setActiveClicked(!isClickedValue);
        setIsOpen(!isOpen);
    };

    return (
        <StyledGalleryList ismobile={isMobile}>
            <StyledMenuElement
                hasdeclaredbgcolor={isOpen ? '#602B26' : '#ee745c'}
                onClick={() => handleClick()}
                ismobile={isMobile}
                iscenterelement={true}
            >
                <StyledText
                    hasdeclaredfontsize="24px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    hasdeclaredtexttransform="uppercase"
                    hascursor="pointer"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 10px"
                >
                    {series.name}
                </StyledText>
                <StyledArrow isopen={isOpen} />
            </StyledMenuElement>
            {isOpen && (
                <StyledGalleryIsOpenWrapper>
                    {series.events.map((event, idx) => (
                        <StyledMenuElement
                            hasdeclaredbgcolor={activeEvent === event ? '#602B26' : '#ee745c'}
                            onClick={() =>
                                setActiveEvent(event.id === activeEvent?.id ? null : event)
                            }
                            ismobile={isMobile}
                            key={idx}
                        >
                            <StyledText
                                hasdeclaredfontsize="24px"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hascursor="pointer"
                                hasdeclaredlineheight="1.3em"
                                hasdeclaredtextalign="center"
                            >
                                {event.city}
                            </StyledText>
                        </StyledMenuElement>
                    ))}
                </StyledGalleryIsOpenWrapper>
            )}
        </StyledGalleryList>
    );
};

export default GalleryList;
