import styled from 'styled-components';

export const StyledUserPanelTrainerPay = styled.div`
    width: 100%;
    height: calc(100% - 60px);

    @media only screen and (max-width: 926px) {
        margin-top: 20px;
    }
`;
