import styled from 'styled-components';

export const StyledRegisterForm = styled.form`
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 2;
    -moz-overflow: hidden;

    @media only screen and (max-width: 1030px) {
        h1 {
            font-size: 54px;
        }
    }

    @media only screen and (max-width: 883px) {
        h1 {
            font-size: 44px;
        }
    }

    @media only screen and (max-width: 781px) {
        padding-top: ${({ isuserpanel }) => (isuserpanel ? '0' : '140px')};
    }

    @media only screen and (max-width: 415px) {
        h1 {
            font-size: 38px;
        }
    }

    @media only screen and (max-width: 380px) {
        h1 {
            font-size: 32px;
        }
    }
`;
