import styled from 'styled-components';

export const StyledScriptIconAndText = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 3px;

    @media only screen and (max-width: 728px) {
        padding-left: 0;
    }
`;
