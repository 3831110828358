import React, { useCallback, useEffect, useState, useRef } from 'react';
import { sessionService } from 'redux-react-session';
import { getString } from '../../strings';

import LogoLink from '../organisms/Logo/LogoLink';
import LoginLink from '../organisms/LoginLink/LoginLink';
import GalleryMenu from '../organisms/Gallery/GalleryMenu';
import AddToFavourite from '../molecules/Gallery/AddToFavourite';
import Loader from '../organisms/Loader/Loader';
import RegisterCube from '../molecules/RegisterCube/RegisterCube';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import { StyledText } from '../atoms/Text/StyledText';
import { StyledButton } from '../atoms/Button/StyledButton';
import {
    StyledLoadingWrapper,
    StyledModalImage,
    StyledModalGalleryWrapper,
    StyledImageWrapper,
    StyledImageListLoadingWrapper,
    StyledGalleryImageWrapper,
    StyledGalleryPage,
    StyledGalleryContent,
    StyledGalleryContentWrapper,
    StyledButtonsWrapper,
    StyledCloseButton,
    StyledChoseImagesWrapper,
} from '../atoms/Gallery/StyledGallery';
import { StyledButtonSvg } from '../atoms/Login/StyledLogin';
import LoadingImageWithLoader from '../organisms/LoadingImage/LoadingImageWithLoader';

import useWindowSize from '../../utils/getWindowSize';
import { getMenuEntries } from '../../logic/requests/gallery';
import { getFavorites, listImagesForEvent } from '../../logic/requests/gallery';
import { FAVORITES_ID } from '../constants/GallerySite';
import { useNavigate, useParams } from 'react-router-dom';
import useScrollBar from '../../logic/hooks/useScrollBar';

const scrollIntoViewOnLoad = (elem) =>
    elem ? elem.scrollIntoView({ behavior: 'smooth' }) : undefined;

const GallerySite = () => {
    class PageState {
        constructor(state) {
            this.value = state;
        }

        static DEFAULT_LOADING = new PageState(0);
        static LOADING_FINISHED = new PageState(1);
        static LOADING_ERROR = new PageState(2);
        static GALLERY_EMPTY = new PageState(3);
    }
    const [pageState, setPageState] = useState(PageState.DEFAULT_LOADING.value);

    const [user, setUser] = useState();
    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const { scrollElement, isScrollBar } = useScrollBar();
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [actualImage, setActualImage] = useState(0);
    const width = useWindowSize();

    const [menu, setMenu] = useState({ series: [], events: [] });
    const [hasMore, setHasMore] = useState(true);
    const { code } = useParams();
    const activeEvent =
        code === FAVORITES_ID
            ? {
                  id: FAVORITES_ID,
                  name: 'Ulubione',
              }
            : code
            ? menu.series
                  // wszystkie wydarzenia z wszystkich serii
                  .reduce((prev, cur) => prev.concat(cur.events), [])
                  // wszystkie wydarzenia bez serii
                  .concat(menu.events)
                  .find((event) => event.code === code)
            : code;
    const navigate = useNavigate();
    const setActiveEvent = (code) => navigate(`/gallery/${code}`);
    const loadSeries = useCallback(() => {
        if (!hasMore) return;

        getMenuEntries()
            .then((res) => {
                console.log('getMenuEntries', res);
                setHasMore(false);
                if (res.series?.length || res.events?.length) {
                    const new_series = menu.series.concat(
                        res.series.filter(
                            // serie bez zawodów z dostępnymi zdjęciami się nie liczą
                            (serie) => Array.isArray(serie.events) && serie.events.length
                        )
                    );

                    setMenu((menu) => ({
                        series: menu.series.concat(new_series),
                        events: menu.events.concat(res.events),
                    }));

                    setPageState(PageState.LOADING_FINISHED.value);
                } else {
                    //setHasMore(false);
                    if (!(menu.series.length && menu.events.length))
                        setPageState(PageState.GALLERY_EMPTY.value);
                }
            })
            .catch((err) => {
                console.error('getSeries', err);
                // nie ukrywamy poprzednio wczytanych serii, jeśli wystąpi błąd przy > 1 ładowaniu
                if (pageState === PageState.DEFAULT_LOADING.value)
                    setPageState(PageState.LOADING_ERROR.value);
            });
    }, [menu, setMenu, pageState, setPageState, hasMore, setHasMore]);
    const [images, setImages] = useState([]);

    const handleOpenCarousel = useCallback(
        (e) => {
            e.preventDefault();
            setViewerIsOpen(true);
            setActualImage(parseInt(e.target.dataset.index));
        },
        [setViewerIsOpen, setActualImage]
    );
    const handlePrevImage = useCallback(() => {
        setActualImage(actualImage <= 0 ? images.length - 1 : actualImage - 1);
    }, [actualImage, setActualImage, images]);
    const handleNextImage = useCallback(() => {
        setActualImage(actualImage >= images.length - 1 ? 0 : actualImage + 1);
    }, [actualImage, setActualImage, images]);

    useEffect(() => {
        if (pageState === PageState.DEFAULT_LOADING.value) loadSeries();
    }, [pageState]);

    useEffect(() => {
        console.log('GallerySite', 'activeEvent', activeEvent);
        if (activeEvent?.id === FAVORITES_ID) {
            // mamy otwarte ulubione
            setImages(favorites);
        } else {
            // mamy otwarte jakieś zawody
            if (typeof activeEvent?.id !== 'number') {
                console.warn(
                    'GallerySite',
                    'invalid activeEvent (ID is not a number); not loading images'
                );
                return;
            }

            listImagesForEvent(activeEvent.id)
                .then((res) => {
                    setImages(
                        res.map((img) => ({
                            eventId: activeEvent.id,
                            fileName: img,
                        }))
                    );
                })
                .catch((err) => {
                    console.error('listImagesForEvent', err);
                    setPageState(PageState.LOADING_ERROR.value);
                });
        }
    }, [activeEvent]);

    const [favorites, setFavorites] = useState([]);
    const [hasLoadedFavorites, setHasLoadedFavorites] = useState(false);
    useEffect(() => {
        if (hasLoadedFavorites) return;

        if (user) {
            // ładujemy ulubione
            getFavorites()
                .then((res) => {
                    setFavorites(res);
                    setHasLoadedFavorites(true);
                })
                .catch((err) => {
                    console.error('getFavorites', err);
                    // TODO: Jakiś error handling?
                    setHasLoadedFavorites(true);
                });
        }
    }, [user, favorites, setFavorites, hasLoadedFavorites, setHasLoadedFavorites]);

    useEffect(() => {
        const keyPressListener = (e) => {
            if (!e) return;

            e.preventDefault();
            if (e.keyCode === 39) {
                nextButtonRef.current.focus();
                handleNextImage();
            }
            if (e.keyCode === 37) {
                prevButtonRef.current.focus();
                handlePrevImage();
            }
        };

        if (viewerIsOpen) {
            window.addEventListener('keyup', keyPressListener);
            return () => window.removeEventListener('keyup', keyPressListener);
        }
    }, [viewerIsOpen, handleNextImage, handlePrevImage]);

    const isFavorite = useCallback(
        (img) =>
            Boolean(
                favorites.find(
                    (fav) => fav.eventId === img.eventId && fav.fileName === img.fileName
                )
            ),
        [favorites]
    );

    const refreshFavoritesAdd = useCallback(
        (img) => {
            if (!isFavorite(img)) setFavorites(favorites.concat(img));
        },
        [favorites, setFavorites]
    );

    const refreshFavoritesRemove = useCallback(
        (img) => {
            if (isFavorite(img))
                setFavorites(
                    favorites.filter(
                        (fav) => !(fav.eventId === img.eventId && fav.fileName === img.fileName)
                    )
                );
        },
        [favorites, setFavorites]
    );

    const activateEvent = useCallback(
        (event) => {
            if (event?.id === activeEvent?.id) return;
            setImages([]);
            setActiveEvent(
                event
                    ? event.id === FAVORITES_ID
                        ? event.id
                        : event.code
                    : // tu może być null - wtedy wracamy na główną
                      ''
            );
        },
        [setImages, activeEvent, setActiveEvent]
    );

    const closeModalImage = (e) => {
        if (e.target.dataset.close === 'close') {
            setViewerIsOpen(false);
        }
    };

    const pageContent = {
        [PageState.DEFAULT_LOADING.value]: () => (
            <StyledLoadingWrapper>
                <Loader declaredWidth="250px" declaredHeight="250px" />
            </StyledLoadingWrapper>
        ),
        [PageState.LOADING_FINISHED.value]: () => (
            <>
                {width > 767 ? (
                    <>
                        <GalleryMenu
                            user={user}
                            menuData={menu}
                            loadSeries={() => {}}
                            hasMore={false}
                            activeEvent={activeEvent}
                            setActiveEvent={activateEvent}
                        />
                        {activeEvent ? (
                            <StyledGalleryContent
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <StyledText
                                    hasdeclaredfontsize="42px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtexttransform="uppercase"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="0 0 34px 0"
                                    hasdeclaredlineheight="1.2em"
                                    as="h2"
                                >
                                    {activeEvent.name}
                                </StyledText>
                                {activeEvent?.id === FAVORITES_ID && !images.length ? (
                                    <StyledLoadingWrapper>
                                        <StyledText
                                            hasdeclaredfontsize="26px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredpadding="0 0 34px 0"
                                            hasdeclaredlineheight="1.4em"
                                            as="h1"
                                        >
                                            {getString(
                                                'pages_gallerySite_nacisnijIkoneSercaPrzyZdjeciu'
                                            )}
                                        </StyledText>
                                    </StyledLoadingWrapper>
                                ) : images.length ? (
                                    <StyledGalleryContentWrapper
                                        isscrollbar={isScrollBar}
                                        ref={scrollElement}
                                    >
                                        {images.map((img, idx) => (
                                            <StyledImageWrapper key={idx}>
                                                <LoadingImageWithLoader
                                                    src={`/api/images/gallery/file/${img.eventId}/${img.fileName}`}
                                                    alt={img}
                                                    data-index={idx}
                                                    onClick={handleOpenCarousel}
                                                    smallLoader={true}
                                                />
                                                {user ? (
                                                    <AddToFavourite
                                                        user={user}
                                                        img={img}
                                                        isFill={isFavorite(img)}
                                                        refreshFavoritesAdd={refreshFavoritesAdd}
                                                        refreshFavoritesRemove={
                                                            refreshFavoritesRemove
                                                        }
                                                    />
                                                ) : null}
                                            </StyledImageWrapper>
                                        ))}
                                    </StyledGalleryContentWrapper>
                                ) : (
                                    <StyledImageListLoadingWrapper>
                                        <Loader declaredWidth="250px" declaredHeight="250px" />
                                    </StyledImageListLoadingWrapper>
                                )}
                            </StyledGalleryContent>
                        ) : (
                            <StyledChoseImagesWrapper
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                <StyledText
                                    hasdeclaredfontsize="44px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredpadding="0 20px"
                                    as="h2"
                                >
                                    {getString('pages_gallerySite_wybierzZawodyZListyAby')}
                                </StyledText>
                            </StyledChoseImagesWrapper>
                        )}
                    </>
                ) : (
                    <>
                        <StyledText
                            hasdeclaredfontsize="40px"
                            hasdeclaredfontweight="700"
                            hasdeclaredpadding="140px 20px 20px"
                            hasdeclaredtextalign="center"
                            hasdeclaredtexttransform="uppercase"
                        >
                            {getString('pages_gallerySite_galeria')}
                        </StyledText>
                        <GalleryMenu
                            user={user}
                            menuData={menu}
                            loadSeries={() => {}}
                            hasMore={false}
                            activeEvent={activeEvent}
                            setActiveEvent={activateEvent}
                        />
                        {activeEvent ? (
                            activeEvent.id === FAVORITES_ID && !images.length ? (
                                <StyledText
                                    hasdeclaredfontsize="32px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredpadding="80px 20px 0 20px"
                                >
                                    {getString(
                                        'pages_gallerySite_nacisnijIkoneSercaPrzyZdjeciuAbyDodac'
                                    )}
                                </StyledText>
                            ) : images.length ? (
                                <StyledGalleryContentWrapper
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    {images.map((img, idx) => (
                                        <StyledImageWrapper key={idx}>
                                            <LoadingImageWithLoader
                                                src={`/api/images/gallery/file/${img.eventId}/${img.fileName}`}
                                                alt={img}
                                                data-index={idx}
                                                onClick={handleOpenCarousel}
                                                smallLoader={true}
                                                innerRef={
                                                    idx === 0 ? scrollIntoViewOnLoad : undefined
                                                }
                                            />
                                            {user ? (
                                                <AddToFavourite
                                                    user={user}
                                                    img={img}
                                                    isFill={isFavorite(img)}
                                                    refreshFavoritesAdd={refreshFavoritesAdd}
                                                    refreshFavoritesRemove={refreshFavoritesRemove}
                                                />
                                            ) : null}
                                        </StyledImageWrapper>
                                    ))}
                                </StyledGalleryContentWrapper>
                            ) : (
                                <StyledImageListLoadingWrapper
                                    ref={scrollIntoViewOnLoad}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Loader declaredWidth="250px" declaredHeight="250px" />
                                </StyledImageListLoadingWrapper>
                            )
                        ) : (
                            <StyledText
                                hasdeclaredfontsize="32px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="30px 20px 0 20px"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.5 }}
                            >
                                {getString(
                                    'pages_gallerySite_wybierzZawodyZListyAbyZobaczycZdjecia'
                                )}
                            </StyledText>
                        )}
                    </>
                )}
                {viewerIsOpen && (
                    <StyledModalGalleryWrapper
                        onClick={(e) => closeModalImage(e)}
                        data-close="close"
                    >
                        <StyledCloseButton onClick={() => setViewerIsOpen(false)} />
                        <StyledGalleryImageWrapper data-close="close">
                            <StyledModalImage>
                                <div>
                                    <img
                                        src={`/api/images/gallery/file/${images[actualImage].eventId}/${images[actualImage].fileName}`}
                                        alt=""
                                        data-index={actualImage}
                                    />
                                    {user ? (
                                        <AddToFavourite
                                            img={images[actualImage]}
                                            isFill={isFavorite(images[actualImage])}
                                            refreshFavoritesAdd={refreshFavoritesAdd}
                                            refreshFavoritesRemove={refreshFavoritesRemove}
                                        />
                                    ) : null}
                                </div>
                            </StyledModalImage>
                            <StyledButtonsWrapper>
                                <StyledButton
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredbgcolor="#6786be"
                                    hasdeclaredpadding="8px 18px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredwidth="80px"
                                    hasdeclaredheight="60px"
                                    hasdeclaredborderradius="25px"
                                    whileHover={{ scale: 0.95 }}
                                    onClick={handlePrevImage}
                                    ref={prevButtonRef}
                                >
                                    <StyledButtonSvg isrotate={true} />
                                </StyledButton>
                                <StyledButton
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredbgcolor="#6786be"
                                    hasdeclaredpadding="8px 18px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredcursor="pointer"
                                    hasdeclaredwidth="80px"
                                    hasdeclaredheight="60px"
                                    hasdeclaredborderradius="25px"
                                    whileHover={{ scale: 0.95 }}
                                    onClick={handleNextImage}
                                    ref={nextButtonRef}
                                >
                                    <StyledButtonSvg />
                                </StyledButton>
                            </StyledButtonsWrapper>
                        </StyledGalleryImageWrapper>
                    </StyledModalGalleryWrapper>
                )}
            </>
        ),
        [PageState.LOADING_ERROR.value]: () => (
            <StyledLoadingWrapper>
                <StyledText
                    hasdeclaredfontsize="56px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 0 34px 0"
                    hasdeclaredlineheight="1.4em"
                    as="h1"
                >
                    {getString('pages_gallerySite_bladWczytywania')}
                </StyledText>
            </StyledLoadingWrapper>
        ),
        [PageState.GALLERY_EMPTY.value]: () => (
            <StyledLoadingWrapper
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <StyledText
                    hasdeclaredfontsize="56px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredtextalign="center"
                    hasdeclaredpadding="0 0 34px 0"
                    hasdeclaredlineheight="1.4em"
                    as="h1"
                >
                    {getString('pages_gallerySite_brakZdjecWGalerii')}
                </StyledText>
            </StyledLoadingWrapper>
        ),
    };

    return (
        <>
            <HelmetTemplate
                title={getString('seo_gallery_title')}
                desc={getString('seo_gallery_desc')}
                ogTitle={getString('seo_gallery_twitter_title')}
            />
            <StyledGalleryPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <LogoLink />
                <LoginLink />
                <RegisterCube isContactPage={true} />
                {pageContent[pageState]()}
            </StyledGalleryPage>
        </>
    );
};

export default GallerySite;
