import styled from 'styled-components';

export const StyledSummaryPlayers = styled.div`
    margin: 40px 0 0 230px;
    padding-right: 20px;

    > div {
        &:first-child {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }

    @media only screen and (min-width: 1441px) {
        > div {
            &:first-child {
                p {
                    font-size: 34px;
                }
            }
        }
    }

    @media only screen and (max-width: 1112px) {
        margin: 40px 0 0 200px;
    }

    @media only screen and (max-width: 884px) {
        margin: 40px 0 0 160px;
    }

    @media only screen and (max-width: 767px) {
        margin: 40px 0 0 0;
        padding-right: 0;
    }
`;
