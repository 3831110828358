import styled from 'styled-components';
import { motion } from 'framer-motion';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledQuestions = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: flex-start;

    max-height: ${({ issend }) => (issend ? '100%' : '90%')};
    overflow-y: ${({ issend }) => (issend ? 'visible' : 'scroll')};

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        max-height: unset;
        height: auto;
        overflow-y: visible;
        > h1,
        h2 {
            font-size: 32px;
            padding: 0 20px;
        }
        > h2 {
            padding-bottom: 20px;
        }
    }

    @media only screen and (max-width: 320px) {
        h1,
        h2 {
            font-size: 30px;
        }
    }
`;
