import styled from 'styled-components';

export const StyledPayAllElement = styled.div`
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;
