import styled from 'styled-components';

export const StyledUserIdContentWrapper = styled.div`
    padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? hasdeclaredpadding : 'none')};
    display: flex;

    @media only screen and (max-width: 1084px) {
        > p {
            font-size: 30px;
        }
    }

    @media only screen and (max-width: 926px) {
        > p {
            font-size: 38px;
        }
    }

    @media only screen and (max-width: 600px) {
        > p {
            font-size: 28px;
        }
    }

    @media only screen and (max-width: 457px) {
        > p {
            font-size: 24px;
        }
    }

    @media only screen and (max-width: 389px) {
        > p {
            font-size: 20px;
        }
    }
`;
