import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { sessionReducer, sessionService } from "redux-react-session";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import axios from "axios";

import App from "./App";

const store = createStore(sessionReducer);
sessionService.initSessionService(store, { driver: "COOKIES" });

// bazowy URL dla zapytań HTTP za pomocą 'axios' - adres i port serwera backend
axios.defaults.baseURL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port +
    "/api";

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
