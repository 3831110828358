import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'formik';

import { StyledCheckboxRegisterInput } from './StyledCheckboxRegisterInput';

import { getString } from '../../../strings';

const CheckboxRegisterInput = ({ competition, children, initialChecked, setFieldValue }) => {
    const [isChecked, setIsChecked] = useState(initialChecked || false);

    const updateChecked = useCallback(
        (val, { dirty } = { dirty: true }) => {
            setFieldValue?.(val, dirty);
            setIsChecked(val);
        },
        [setFieldValue, setIsChecked]
    );

    useEffect(() => updateChecked(initialChecked, { dirty: false }), [initialChecked]);

    return (
        <StyledCheckboxRegisterInput ischecked={isChecked}>
            <label
                htmlFor={competition}
                onClick={() => updateChecked(!isChecked)}
                title={getString(`const_competitions_${competition}`) ?? competition}
            >
                {children}
            </label>
            <Field type="checkbox" id={competition} name={competition} />
        </StyledCheckboxRegisterInput>
    );
};

export default CheckboxRegisterInput;
