import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';
import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledGlobalScrollWrapper,
    StyledGlobalHeadingWrapper,
} from '../../../styles/sharedStyles';
import { StyledUserPanelManageContracts } from './UserPanelManageContracts.styles';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';

import useScrollBar from '../../../logic/hooks/useScrollBar';
import ManageContractsElement from '../ManageContractsElement/ManageContractsElement';

import { formSchema } from './UserPanelManageContracts.data';
import { getString } from '../../../strings';

const UserPanelManageContracts = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            name: '',
            startDate: '',
            endDate: '',
        },
    });

    const onSubmit = useCallback((data) => {
        console.log('dodaj', data);
    }, []);

    const ErrorText = useMemo(
        () => (
            <StyledText
                as="h4"
                hasdeclaredfontsize="16px"
                hasdeclaredfontcolor="#F74017"
                hasdeclaredtextalign="center"
                hasdeclaredfontweight="700"
                hasdeclaredtexttransform="uppercase"
                hasdeclaredlineheight="1.4em"
            >
                Wymagane
            </StyledText>
        ),
        []
    );

    return (
        <StyledUserPanelManageContracts>
            <StyledGlobalHeadingWrapper>
                <StyledGlobalHeadingWrapper>
                    <UserPanelHeadingText text={`Umowy`} />
                </StyledGlobalHeadingWrapper>
            </StyledGlobalHeadingWrapper>
            <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper error={errors?.['name']} hasdeclaredwidth="33%">
                            <label>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('user_panel_manage_contracts_nazwa_umowy')}:
                                </StyledText>
                            </label>
                            <input type="text" {...register('name')} />
                            {ErrorText}
                        </StyledInputWrapper>
                        <StyledInputWrapper error={errors?.['startDate']} hasdeclaredwidth="33%">
                            <label>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('user_panel_manage_contracts_start')}
                                </StyledText>
                            </label>
                            <input type="date" {...register('startDate')} />
                            {ErrorText}
                        </StyledInputWrapper>
                        <StyledInputWrapper error={errors?.['endDate']} hasdeclaredwidth="33%">
                            <label>
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('user_panel_manage_contracts_koniec')}
                                </StyledText>
                            </label>
                            <input type="date" {...register('endDate')} />
                            {ErrorText}
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsButtonsWrapper>
                        <input type="submit" value={'Zapisz'} />
                        <button type="button" className="reset" onClick={() => reset()}>
                            {getString('user_panel_manage_contracts_anuluj')}
                        </button>
                    </StyledInputsButtonsWrapper>
                </form>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredmargin="clamp(30px, 4vw, 80px) 0 20px 0"
                >
                    {getString('user_panel_manage_contracts_umowy')}:
                </StyledText>
                <ManageContractsElement />
                <ManageContractsElement />
                <ManageContractsElement />
            </StyledGlobalScrollWrapper>
        </StyledUserPanelManageContracts>
    );
};

export default UserPanelManageContracts;
