import styled from 'styled-components';

export const StyledDeleteCheckbox = styled.div`
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: ${({ isbgcolor }) => (isbgcolor ? 'none' : '#EE745C')};
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    margin-bottom: 6px;
`;
