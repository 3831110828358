import axios from "axios";

const func = (link, body, fileName = undefined) => {
    axios({
        method: "POST",
        url: link,
        data: body,
        responseType: "blob",
    })
        .then(({ data, headers }) => {
            const name =
                fileName ??
                headers["content-disposition"]?.match?.(/^attachment; filename=(.*)$/)?.[1] ??
                "file";

            const a = document.createElement("a");
            // https://stackoverflow.com/a/54373308
            a.href = window.URL.createObjectURL(data);
            a.download = name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
        .catch((err) => console.error("downloadFilePost", err));
};

export default func;
