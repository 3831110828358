import styled from 'styled-components';

export const StyledTournamentPlayersContent = styled.div`
    width: 100%;
    height: 95vh;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: ${({ iscentertext }) => (iscentertext ? 'center' : 'flex-start')};
    padding-bottom: 40px;

    h2 {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

    @media only screen and (max-width: 767px) {
        height: auto;
        h2 {
            font-size: 20px;
            padding: 40px 0;
        }
    }
`;
