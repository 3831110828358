import styled from 'styled-components';

export const StyledResultPageHeader = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #6886be;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;

    > span {
        position: absolute;
        left: 24px;
        top: 50%;
        -webkit-transform: translateY(-65%);
        -ms-transform: translateY(-65%);
        transform: translateY(-65%);

        @media only screen and (max-width: 420px) {
            left: 8px;
            font-size: 22px;
        }
    }

    a {
        text-decoration: none;
        color: #f9ec4b;
        font-weight: 900;
        -webkit-transition: color 250ms, -webkit-transform 250ms;
        transition: color 250ms, -webkit-transform 250ms;
        -o-transition: color 250ms, transform 250ms;
        transition: color 250ms, transform 250ms;
        transition: color 250ms, transform 250ms, -webkit-transform 250ms;

        &:hover {
            color: #fff;
        }
    }
`;
