import React, { useEffect, useState } from 'react';
import { sessionService } from 'redux-react-session';
import * as Yup from 'yup';
import { getString } from '../../../strings';

import { Formik, Field } from 'formik';
import { AnimatePresence } from 'framer-motion';

import InputWrapper from '../../molecules/Register/InputWrapper';
import RegisterErrorMessage from '../../molecules/Register/RegisterErrorMessage';

import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledContactForm } from '../../atoms/Contact/StyledContactForm';
import { StyledContactFormWrapper, StyledHeaderText } from '../../atoms/Contact/StyledContactForm';

import { send } from '../../../logic/requests/contact.js';

const ContactForm = ({ onMessageSent }) => {
    const [user, setUser] = useState();

    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    const validationSchema = Yup.object({
        title: Yup.string()
            .min(3, getString('contact_contactForm_zaKrotkiTytul'))
            .required(getString('contact_contactForm_tytulWymagany')),
        email: Yup.string()
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                getString('contact_contactForm_emailNiepoprawny')
            )
            .required(getString('contact_contactForm_emailWymagany')),
        message: Yup.string()
            .min(10, getString('contact_contactForm_minimum10znakow'))
            .required(getString('contact_contactForm_wiadomoscWymagana')),
    });

    const handleSubmit = (data, { setSubmitting }) => {
        send(data.email, data.title, data.message)
            .then(() => {
                setSubmitting(false);
                if (typeof onMessageSent === 'function') onMessageSent();
            })
            .catch((err) => {
                console.error('handleSubmit', err);
                setSubmitting(false);
            });
    };
    return (
        <StyledContactFormWrapper>
            <StyledHeaderText>
                <StyledText
                    hasdeclaredfontweight="800"
                    hasdeclaredfontsize="52px"
                    hasdeclaredtextalign="center"
                    hasdeclaredmargin="0 0 18px 0"
                >
                    {getString('contact_contactForm_napisz')}
                </StyledText>
            </StyledHeaderText>
            <Formik
                initialValues={{ email: user?.email, title: '', message: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, handleSubmit, isSubmitting }) => (
                    <AnimatePresence>
                        <StyledContactForm onSubmit={handleSubmit}>
                            <div>
                                <InputWrapper
                                    inputType="email"
                                    title={getString('contact_contactForm_emailTitle')}
                                    name="email"
                                    error={errors.email}
                                    hasDeclaredBottomPosition="-8px"
                                />
                            </div>
                            <div>
                                <InputWrapper
                                    inputType="text"
                                    title={getString('contact_contactForm_titleTitle')}
                                    name="title"
                                    error={errors.title}
                                    hasDeclaredBottomPosition="-8px"
                                />
                            </div>
                            <div>
                                <label htmlFor="message">
                                    <StyledText
                                        hasdeclaredfontsize="20px"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredtextalign="center"
                                    >
                                        {getString('contact_contactForm_napiszWiadomosc')}
                                    </StyledText>
                                </label>
                                <Field as="textarea" name="message" />
                                <RegisterErrorMessage
                                    name="message"
                                    hasDeclaredBottomPosition="-8px"
                                />
                            </div>
                            <StyledButton
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredpadding="8px 44px"
                                hasdeclaredmargin="24px 0 0 0"
                                hasdeclaredbgcolor="#6786BE"
                                hasdeclaredfontweight="600"
                                hasdeclaredborderradius="25px"
                                hasdeclaredcursor="pointer"
                                type="submit"
                                disabled={isSubmitting}
                                whileHover={{ scale: 0.95 }}
                                hasdeclaredtexttransform="uppercase"
                            >
                                {getString('contact_contactForm_wyslij')}
                            </StyledButton>
                        </StyledContactForm>
                    </AnimatePresence>
                )}
            </Formik>
        </StyledContactFormWrapper>
    );
};

export default ContactForm;
