import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getString } from '../../strings';
import LogoLink from '../organisms/Logo/LogoLink';
import LoginLink from '../organisms/LoginLink/LoginLink';
import RegisterCube from '../molecules/RegisterCube/RegisterCube';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';

import { StyledThankYouPage } from '../../components/atoms/ThankYouPage/StyledThankYouPage';
import { StyledText } from '../../components/atoms/Text/StyledText';
import { StyledButton } from '../atoms/Button/StyledButton';
import { StyledContentWrapper } from '../atoms/ThankYouPage/StyledContentWrapper';

import { resendEmail } from '../../logic/requests/users.js';

const ThankYouPage = ({ email }) => {
    const location = useLocation();
    const state = location.state;

    const [hasResentEmail, setHasResentEmail] = useState(false);
    const [resendEmailErr, setResendEmailErr] = useState(false);
    const [resendEmailErrCode, setResendEmailErrCode] = useState();
    const [resendEmailErrMsg, setResendEmailErrMsg] = useState();

    // mmm... kod spaghetti
    const actual_email = email || state?.email || '';

    const handleResendEmail = () => {
        setHasResentEmail(false);
        setResendEmailErr(false);
        setResendEmailErrCode(null);
        setResendEmailErrMsg(null);
        resendEmail({ email: actual_email })
            .then(() => {
                setHasResentEmail(true);
                setTimeout(() => setHasResentEmail(false), 5000);
            })
            .catch((err) => {
                setResendEmailErrCode(err.status);
                setResendEmailErrMsg(err.data.message);
                setResendEmailErr(true);
            });
    };

    // e-mail podany przez prop => przyszedł tutaj z /login
    const isReturningClient = Boolean(email);

    return (
        <>
            <HelmetTemplate
                title={getString('seo_thank_you_page_title')}
                desc={getString('seo_thank_you_page_desc')}
                ogTitle={getString('seo_thank_you_page_twitter_title')}
            />
            <StyledThankYouPage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <RegisterCube registerCubeFill="rgba(0,0,0,0.1)" />
                <LogoLink />
                <LoginLink />
                <StyledContentWrapper>
                    {isReturningClient ? (
                        <></>
                    ) : (
                        <StyledText
                            hasdeclaredfontsize="52px"
                            hasdeclaredfontweight="bold"
                            hasdeclaredmargin="0 0 38px 0"
                            hasdeclaredtextalign="center"
                            hasdeclaredpadding="0 20px"
                            hasdeclaredlineheight="1.4em"
                            as="h1"
                        >
                            {getString('pages_thankYouPage_dziekujemyZaRejestracje')}
                        </StyledText>
                    )}
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="0 10px"
                        as="h3"
                        hasdeclaredlineheight="1.4em"
                    >
                        {getString('pages_thankYouPage_zweryfikujKontoPoprzez')} ({actual_email}).
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredmargin="60px 0 10px 0"
                        hasdeclaredtextalign="center"
                    >
                        {getString('pages_thankYouPage_linkNieDotarl')}
                    </StyledText>
                    <StyledButton
                        hasdeclaredbgcolor="#6786be"
                        hasdeclaredpadding="17px 44px"
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredfontweight="bold"
                        hasdeclaredborderradius="40px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredcursor="pointer"
                        whileHover={{ scale: 0.95 }}
                        onClick={handleResendEmail}
                    >
                        {getString('pages_thankYouPage_wyslijPonownie')}
                    </StyledButton>
                    {hasResentEmail ? (
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredpadding="16px 0"
                            hasdeclaredfontweight="600"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredtextalign="center"
                        >
                            {getString('pages_thankYouPage_wyslano')}
                        </StyledText>
                    ) : (
                        <></>
                    )}
                    {resendEmailErr ? (
                        <>
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="16px 10px"
                                hasdeclaredfontweight="600"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                as="h3"
                            >
                                {getString('pages_thankYouPage_bladPodczasWysylaniaWiadomosci')}{' '}
                                {resendEmailErrCode
                                    ? `${getString(
                                          'pages_thankYouPage_kodBledu'
                                      )} ${resendEmailErrCode}) `
                                    : ''}
                                {resendEmailErrMsg
                                    ? `; ${getString(
                                          'pages_thankYouPage_wiadomoscZSerweraBackend'
                                      )}`
                                    : ''}
                            </StyledText>
                            {resendEmailErrMsg ? (
                                <StyledText
                                    hasdeclaredfontsize="18px"
                                    hasdeclaredlineheight="1.4em"
                                    hasdeclaredpadding="16px 10px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredfontcolor="#F74017"
                                    hasdeclaredtextalign="center"
                                    as="h3"
                                >
                                    {resendEmailErrMsg}
                                </StyledText>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </StyledContentWrapper>
            </StyledThankYouPage>
        </>
    );
};

export default ThankYouPage;
