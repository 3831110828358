import React from 'react';

const CloseIcon = () => (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.84968 0L0 5.0293L2.47643 7.59745L8.59873 14.0535L2.47643 20.4025L0 22.8637L4.84968 28L7.32612 25.4318L13.5516 18.9758L19.6739 25.4318L22.0471 28L27 22.8637L24.5236 20.4025L18.2981 14.0535L24.5236 7.59745L27 5.0293L22.0471 0L19.6739 2.56815L13.5516 8.9172L7.32612 2.56815L4.84968 0Z"
            fill="white"
        />
    </svg>
);

export default CloseIcon;
