import * as Yup from 'yup';

import { getString } from '../../strings';

export const resetPasswordValidationSchema = Yup.object({
    newPassword: Yup.string()
        .min(8, getString('const_resetPasswordValidationSchema_minLiczbaZnakow'))
        .required(getString('const_resetPasswordValidationSchema_podajNoweHaslo'))
        .notOneOf(
            [Yup.ref('oldPassword'), null],
            getString('const_resetPasswordValidationSchema_noweHasloMusi')
        ),
    newPasswordConfirmation: Yup.string().oneOf(
        [Yup.ref('newPassword'), null],
        getString('const_resetPasswordValidationSchema_haslaMuszaBycIdentyczne')
    ),
    oldPassword: Yup.string().required(
        getString('const_resetPasswordValidationSchema_podajStareHaslo')
    ),
});
