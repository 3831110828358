import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledTournamentAdditionalInformationsContentWrapper = styled.div`
    width: 100%;
    height: 65%;
    margin-top: 220px;
    overflow-y: scroll;
    padding-right: 10px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 767px) {
        height: 100%;
        margin-top: 60px;
        overflow-y: visible;
        padding-bottom: 40px;
    }
`;
