import React, { useCallback, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { StyledStudentPaymentsElement } from '../../atoms/UserPanelStudentsPayments/StyledStudentPaymentsElement';
import { StyledDataElement } from '../../atoms/SettlementsDoneDataElement/StyledDataElement';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledStudentPaymentsElementWrapper } from '../../atoms/UserPanelStudentsPayments/StyledStudentPaymentsElementWrapper';
import { StyledScrollWrapperElement } from '../../atoms/UserPanelStudentsPayments/StyledScrollWrapperElement';
import PaymentsPopup from '../Payments/PaymentsPopup';
import { useQueryClient } from '@tanstack/react-query';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';

import { getString } from '../../../strings';
import { markHourAsPaid } from '../../../logic/requests/students';

const StudentPaymentsElement = ({
    student,
    subject,
    date,
    hours_count,
    trainer,
    free,
    price,
    id,
    paid,
}) => {
    const [confirmPopup, setConfirmPopup] = useState(false);
    const queryClient = useQueryClient();

    const handleChange = () => {
        setConfirmPopup((prev) => !prev);
    };

    const handleAcceptHour = useCallback(() => {
        markHourAsPaid(id).then((res) => {
            queryClient.invalidateQueries({ queryKey: ['studentsHours'] });
        });
    }, [id, queryClient]);

    return (
        <>
            {confirmPopup ? (
                <PaymentsPopup
                    title={getString(
                        'student_payments_element_are_you_sure'
                    )  + ` Szczegóły: ${student} ${hours_count}h ${date} ${subject}`}
                    onClose={handleAcceptHour}
                    cancelTag={getString('student_payments_element_cancel')}
                    closeTag={getString('student_payments_element_yes')}
                    onCancel={() => setConfirmPopup(false)}
                />
            ) : null}
            <StyledStudentPaymentsElementWrapper>
                <ScrollSyncPane>
                    <StyledScrollWrapperElement>
                        <StyledStudentPaymentsElement
                            hasDeclaredBorderColor={free ? '#5FEE5C' : undefined}
                        >
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {student}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {subject}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {date}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {hours_count}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {trainer}
                                </StyledText>
                            </StyledDataElement>
                            <StyledDataElement>
                                <StyledText
                                    hasdeclaredfontsize="13px"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtextalign="center"
                                >
                                    {price}
                                </StyledText>
                            </StyledDataElement>
                        </StyledStudentPaymentsElement>
                    </StyledScrollWrapperElement>
                </ScrollSyncPane>
                {!paid && (
                    <StyledSavePayElement
                        style={{ transform: 'translateY(0)' }}
                        onClick={handleChange}
                        type="submit"
                        isedit
                    >
                        <AcceptIcon />
                    </StyledSavePayElement>
                )}
            </StyledStudentPaymentsElementWrapper>
        </>
    );
};

export default StudentPaymentsElement;
