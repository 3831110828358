import styled from 'styled-components';
import { userPanelElementStyles } from '../../../styles/sharedStyles';

export const StyledManageContractsElement = styled.div`
    ${userPanelElementStyles};
`;

export const StyledElement = styled.div`
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
`;

export const StyledManageContractsElementDataText = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '25%')};
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
`;

export const StyledFlexElement = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-top: 5px;

    > div {
        &:first-child {
            width: calc(100% - 104px);
            overflow-x: scroll;

            scrollbar-color: transparent transparent;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                height: 0;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }
        }
    }
`;

export const StyledHeaderElement = styled.div`
    width: ${({ simulatecheckbox, $hasWidth }) =>
        simulatecheckbox ? '41px' : $hasWidth ? $hasWidth : '33%'};
    padding: 0 4px;
`;
