import React from 'react';
import HomePageCards from '../organisms/Home/HomePageCards';

import { StyledHomePage } from '../atoms/Home/StyledHome';
import HomePageSocialMedia from '../molecules/HomePageSocialMedia/HomePageSocialMedia';
import HelmetTemplate from '../molecules/HelmetTemplate/HelmetTemplate';
import { getString } from '../../strings';

const Home = () => {
    return (
        <>
            <HelmetTemplate
                title={getString('seo_home_title')}
                desc={getString('seo_home_desc')}
                ogTitle={getString('seo_home_twitter_title')}
            />
            <StyledHomePage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: 'tween', duration: 0.2 }}
            >
                <HomePageCards />
                <HomePageSocialMedia />
            </StyledHomePage>
        </>
    );
};

export default Home;
