import styled from 'styled-components';

export const StyledAdvantge = styled.div`
    display: flex;
    align-items: center;
    min-height: 50px;
    margin-top: 20px;
    position: relative;
    min-width: 700px;

    @media only screen and (min-width: 1441px) {
        min-width: unset;
        max-width: 900px;
        margin: 20px auto 0 auto;
    }
`;
