import styled from 'styled-components';

export const StyledCheckboxRegisterInput = styled.div`
    label {
        svg {
            width: 36px;
            height: 36px;
            cursor: pointer;
            fill: ${({ ischecked }) => (ischecked ? '#4c7bbc' : 'rgba(0,0,0,0.2)')};
            transition: fill 250ms;
            transform: translate(7px, 2px);
        }
    }

    input {
        opacity: 0;
        pointer-events: none;
    }
`;
