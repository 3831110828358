import styled from 'styled-components';

export const StyledSeriesResultsElement = styled(({ hasdeclaredmargin, ...props }) => (
    <div {...props} />
))`
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 50px;
    margin: ${({ hasdeclaredmargin }) => hasdeclaredmargin ?? '0 0 10px 0'};
`;
