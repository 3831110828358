import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
    width: calc(92% - 60px);
    border: ${({ iscolored }) => (iscolored ? '3px solid #000' : 'none')};
    border-radius: ${({ iscolored }) => (iscolored ? '15px' : 'none')};
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ iscolored, redcolor }) =>
        iscolored
            ? redcolor
                ? 'linear-gradient(90deg, rgba(238,116,91,1) 0%, rgba(255,255,255,1) 100%);'
                : '#fff'
            : 'transparent'};
`;
