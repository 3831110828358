import React, { useCallback, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import Decimal from 'decimal.js';

import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { StyledUserPanelEmployeeElement } from '../../atoms/UserPanelEmployeeConfiguration/StyledUserPanelEmployeeElement';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';

import { getString } from '../../../strings';
import { getDateString } from '../../../logic/eventtime';

const handleSubmit = (data, { setSubmitting, resetForm }, onSuccess) => {
    setSubmitting(false);
    onSuccess?.();
    //resetForm();
};

const initialValues = {
    trainer: '',
    contract: '',
    price: 0,
    bonus_amount: null,
    bonus_hours: null,
};

const UserPanelEmployeeConfElement = ({ trainers, onSuccess }) => {
    const [trainerId, setTrainerId] = useState('');
    const trainer = useMemo(
        () => trainers.find(({ id }) => id === trainerId),
        [trainers, trainerId]
    );

    const schema = useMemo(
        () =>
            yup.object({
                trainer: yup
                    .number()
                    .required(getString('Formik__field_is_required'))
                    .oneOf(trainers.map(({ id }) => id)),
                contract: yup
                    .number()
                    .notRequired()
                    .oneOf((trainer?.contracts ?? []).map(({ id }) => id)),
                price: yup
                    .number()
                    .positive()
                    .required(getString('Formik__field_is_required'))
                    .not([0], getString('Formik__field_is_required')),
                bonus_amount: yup.number().positive().nullable(),
                bonus_hours: yup.number().positive().nullable(),
            }),
        [trainers, trainer]
    );
    const onSubmit = useCallback(
        (data, formikHelpers) => {
            if (!trainer) return;

            const { firstname, lastname, contracts } = trainer;
            const contract = contracts.find(({ id }) => id === data.contract);
            const price = new Decimal(data.price);
            const bonus_amount = data.bonus_amount
                ? new Decimal(data.bonus_amount)
                : data.bonus_amount;
            const bonus_hours = data.bonus_hours ? new Decimal(data.bonus_hours) : data.bonus_hours;

            handleSubmit(data, formikHelpers, () =>
                onSuccess([
                    {
                        title: getString('User_panel_employee_konfiguracja_trener'),
                        value: `${firstname} ${lastname}`,
                    },
                    {
                        title: getString('User_panel_employess_conf_element'),
                        value: !contract
                            ? getString('contract__none')
                            : `${getString(`contract__${contract.type}`)} | ${getDateString(
                                  contract.start
                              )} - ${
                                  contract.end
                                      ? getDateString(contract.end)
                                      : `(${getString('contract__date__no_end')})`
                              }`,
                    },
                    {
                        title: getString('User_panel_employee_konfiguracja_stawka_godzinowa'),
                        value: (price.dp() > 0 ? price.toFixed(2) : price.toString()) + ' PLN',
                    },
                    {
                        title: getString('User_panel_employee_konfiguracja_wysokosc_premii'),
                        value: !bonus_amount
                            ? getString('none')
                            : (bonus_amount.dp() > 0
                                  ? bonus_amount.toFixed(2)
                                  : bonus_amount.toString()) + ' PLN',
                    },
                    {
                        title: getString('User_panel_employee_konfiguracja_godzin_do_premii'),
                        value: !bonus_hours ? getString('none') : bonus_hours.toString(),
                    },
                ])
            );
        },
        [trainer, onSuccess]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors, initialValues, setFieldValue }) => (
                <StyledUserPanelEmployeeElement>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper hasdeclaredwidth="50%" error={errors.trainer}>
                            <label htmlFor="trainer">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_trener')}
                                </StyledText>
                            </label>
                            <Field
                                as="select"
                                name="trainer"
                                defaultValue={initialValues.trainer}
                                onChange={({ target: { value } }) => {
                                    const parsed = value ? parseInt(value, 10) : value;

                                    setTrainerId(parsed);
                                    setFieldValue('trainer', parsed);

                                    const contract =
                                        trainers.find(({ id }) => id === parsed)?.current_contract
                                            ?.id ?? '';
                                    setFieldValue('contract', contract);
                                }}
                            >
                                <option value="">
                                    - {getString('UserPanelAdditions__inputs__trainer__default')} -
                                </option>
                                {trainers.map(({ id, firstname, lastname }) => (
                                    <option key={id} value={id}>
                                        {lastname} {firstname}
                                    </option>
                                ))}
                            </Field>
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.trainer}
                            </StyledText>
                        </StyledInputWrapper>
                        <StyledInputWrapper hasdeclaredwidth="50%" error={errors.contract}>
                            <label htmlFor="contract">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employess_conf_element')}:
                                </StyledText>
                            </label>
                            <Field
                                as="select"
                                name="contract"
                                disabled={!trainer?.contracts?.length}
                                defaultValue={initialValues.contract}
                                onChange={({ target: { value } }) =>
                                    setFieldValue('contract', value ? parseInt(value, 10) : value)
                                }
                            >
                                {!trainer ? (
                                    <option value="">
                                        -{' '}
                                        {getString(
                                            'UserPanelEmployeeConfiguration__form__contract__choose_trainer_first'
                                        )}{' '}
                                        -
                                    </option>
                                ) : !trainer.contracts.length ? (
                                    <option value="">
                                        -{' '}
                                        {getString(
                                            'UserPanelEmployeeConfiguration__form__contract__no_contracts'
                                        )}{' '}
                                        -
                                    </option>
                                ) : (
                                    <>
                                        <option value="">- {getString('contract__none')} -</option>
                                        {trainer.contracts.map(({ id, type, start, end }) => (
                                            <option key={id} value={id}>
                                                {getString(`contract__${type}`)} |{' '}
                                                {getDateString(start)} -{' '}
                                                {end
                                                    ? getDateString(end)
                                                    : `(${getString('contract__date__no_end')})`}
                                            </option>
                                        ))}
                                    </>
                                )}
                            </Field>
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.contract}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsDivWrapper>
                        <StyledInputWrapper hasdeclaredwidth="33%" error={errors.price}>
                            <label htmlFor="price">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_stawka_godzinowa')}
                                </StyledText>
                            </label>
                            <Field
                                as="input"
                                type="number"
                                name="price"
                                defaultValue={initialValues.price}
                                min={0}
                                step="any"
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.price}
                            </StyledText>
                        </StyledInputWrapper>
                        <StyledInputWrapper hasdeclaredwidth="33%" error={errors.bonus_amount}>
                            <label htmlFor="bonus_amount">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_wysokosc_premii')}
                                </StyledText>
                            </label>
                            <Field
                                as="input"
                                type="number"
                                name="bonus_amount"
                                min={0}
                                step="any"
                                defaultValue={initialValues.bonus_amount ?? 0}
                                onChange={({ target: { value } }) => {
                                    const parsed = value ? parseFloat(value) : value;
                                    return setFieldValue(
                                        'bonus_amount',
                                        isNaN(parsed) || !parsed ? null : parsed
                                    );
                                }}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.bonus_amount}
                            </StyledText>
                        </StyledInputWrapper>
                        <StyledInputWrapper hasdeclaredwidth="33%" error={errors.bonus_hours}>
                            <label htmlFor="bonus_hours">
                                <StyledText
                                    hasdeclaredfontsize="20px"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredpadding="8px 0 8px 0"
                                    hasdeclaredfontweight="600"
                                >
                                    {getString('User_panel_employee_konfiguracja_godzin_do_premii')}
                                </StyledText>
                            </label>
                            <Field
                                as="input"
                                type="number"
                                name="bonus_hours"
                                min={0}
                                defaultValue={initialValues.bonus_hours ?? 0}
                                onChange={({ target: { value } }) => {
                                    const parsed = value ? parseInt(value, 10) : value;
                                    return setFieldValue(
                                        'bonus_amount',
                                        isNaN(parsed) || !parsed ? null : parsed
                                    );
                                }}
                            />
                            <StyledText
                                as="h4"
                                hasdeclaredfontsize="16px"
                                hasdeclaredfontcolor="#F74017"
                                hasdeclaredtextalign="center"
                                hasdeclaredfontweight="700"
                                hasdeclaredtexttransform="uppercase"
                                hasdeclaredlineheight="1.4em"
                            >
                                {errors.bonus_hours}
                            </StyledText>
                        </StyledInputWrapper>
                    </StyledInputsDivWrapper>
                    <StyledInputsButtonsWrapper>
                        <input
                            type="submit"
                            value={getString('User_panel_employee_konfiguracja_zapisz')}
                        />
                        <input
                            type="reset"
                            value={getString('User_panel_employee_konfiguracja_anuluj')}
                        />
                    </StyledInputsButtonsWrapper>
                </StyledUserPanelEmployeeElement>
            )}
        </Formik>
    );
};

export default UserPanelEmployeeConfElement;
