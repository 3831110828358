import styled from 'styled-components';
import Background from '../../../images/gallery/gallerybg.jpg';
import { motion } from 'framer-motion';

export const StyledTrainingsPage = styled(motion.section)`
    width: 100%;
    position: relative;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;

    @media only screen and (max-width: 1110px) {
        background: #f9ec4b;
    }

    @media only screen and (max-width: 767px) {
        min-height: 100vh;
    }
`;
