import React from 'react';

const PhoneIcon = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.2667 16.9592L19.0166 14.7092C18.7924 14.6136 18.5431 14.5935 18.3064 14.6518C18.0697 14.7102 17.8583 14.8438 17.7041 15.0327L15.3791 17.8733C11.7301 16.1529 8.79359 13.2163 7.07315 9.5674L9.91383 7.24236C10.1031 7.08846 10.237 6.87709 10.2953 6.64026C10.3537 6.40344 10.3333 6.15404 10.2373 5.92984L7.98723 0.679743C7.88181 0.438055 7.69537 0.240726 7.46004 0.12178C7.22472 0.00283371 6.95527 -0.0302726 6.69815 0.0281692L1.82306 1.15319C1.57517 1.21043 1.354 1.35001 1.19565 1.54914C1.03729 1.74827 0.951115 1.9952 0.951172 2.24961C0.951172 14.2733 10.6967 24 22.7015 24C22.956 24.0002 23.2031 23.914 23.4023 23.7557C23.6015 23.5973 23.7412 23.3761 23.7984 23.1281L24.9235 18.253C24.9815 17.9947 24.9477 17.7241 24.8279 17.488C24.708 17.2519 24.5096 17.0649 24.2667 16.9592Z"
            fill="#6786BE"
        />
    </svg>
);

export default PhoneIcon;
