import styled from 'styled-components';

export const StyledRegisterCube = styled(({ iscontactpage, ...props }) => <div {...props} />)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    -moz-overflow: hidden;
    max-height: 100vh;

    svg {
        width: 220px;
        height: 220px;
        fill: rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 1227px) {
        display: ${({ iscontactpage }) => (iscontactpage ? 'none' : 'block')};
    }

    @media only screen and (max-width: 950px) {
        display: none;
    }
`;
