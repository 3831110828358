import * as Yup from 'yup';

import { getString } from '../../strings';

import { validationSchema as userDataValidationSchema } from './userDataValidationSchema';

export const validationSchema = Yup.object({
    ...userDataValidationSchema,
    email: Yup.string()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            getString('const_registerValidationSchema_emailNiepoprawny')
        )
        .required(getString('const_registerValidationSchema_emailWymagany')),
    country: Yup.string()
        .required(getString('const_registerValidationSchema_podajPanstwo'))
        .nullable(),
    password: Yup.string()
        .min(8, getString('const_registerValidationSchema_minLiczbaZnakow'))
        .required(getString('const_registerValidationSchema_hasloWymagane')),
    '222': Yup.bool(),
    '333': Yup.bool(),
    '333bf': Yup.bool(),
    '333oh': Yup.bool(),
    '444': Yup.bool(),
    '555': Yup.bool(),
    '666': Yup.bool(),
    '777': Yup.bool(),
    'clock': Yup.bool(),
    'minx': Yup.bool(),
    'pyram': Yup.bool(),
    'skewb': Yup.bool(),
    'sq1': Yup.bool(),
    regulationsField: Yup.bool()
        .oneOf([true], getString('const_registerValidationSchema_akceptujRegulamin'))
        .required(getString('const_registerValidationSchema_akceptujRegulamin')),
});
