import styled from 'styled-components';

export const StyledPayHeader = styled.div`
    width: 100%;
    padding: 8px 0;

    > div {
        > div {
            width: 100%;
            min-width: 700px;
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
        }
    }
`;
