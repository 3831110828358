import styled from 'styled-components';

export const StyledUserPanelFirstDiv = styled.div`
    min-width: 131px;
    width: 15%;
    background-color: #4e6baa;
    border: 2px solid #000;
    border-radius: 15px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
