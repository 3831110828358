import styled from 'styled-components';

export const StyledUserPanelAddRoleComponent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 926px) {
        > h2 {
            padding-top: 20px;
            font-size: 30px;
        }
    }
`;
