import React, { useEffect, useState } from 'react';
import { sessionService } from 'redux-react-session';
import { Link } from 'react-router-dom';
import { getString } from '../../../strings';
import ChangeLanguage from '../ChangeLanguage/ChangeLanguage';

import { StyledLoginLink } from '../../molecules/LoginLink/StyledLoginLink';
import { StyledText } from '../../atoms/Text/StyledText';

const LoginLink = () => {
    const [user, setUser] = useState();

    useEffect(
        () =>
            (async () => {
                let user = null;
                try {
                    user = await sessionService.loadUser();
                } catch (err) {}
                setUser(user);
            })(),
        [sessionService, setUser]
    );

    return (
        <StyledLoginLink>
            <Link to={user && Object.keys(user).length ? '/my-account' : '/login'}>
                <StyledText
                    hasdeclaredfontsize="20px"
                    hasdeclaredfontcolor="#fff"
                    hasdeclaredfontweight="600"
                    as="p"
                >
                    {user && Object.keys(user).length
                        ? getString('loginLink_loginLink_mojeKonto')
                        : getString('loginLink_loginLink_zalogujSie')}
                </StyledText>
            </Link>
            <ChangeLanguage />
        </StyledLoginLink>
    );
};

export default LoginLink;
