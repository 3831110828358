export const initialValues = {
    firstname: '',
    email: '',
    birthdate: '',
    country: null,
    phone: '',
    lastname: '',
    password: '',
    gender: null,
    city: '',
    wca_id: '',
    avatar: null,
    shirtSize: '',
    '222': false,
    '333': false,
    '333bf': false,
    '333oh': false,
    '444': false,
    '555': false,
    '666': false,
    '777': false,
    'clock': false,
    'minx': false,
    'pyram': false,
    'skewb': false,
    'sq1': false,
    newsletter: false,
};
