import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToggle } from 'react-use';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import {
    StyledManageContractsElement,
    StyledElement,
    StyledManageContractsElementDataText,
    StyledFlexElement,
    StyledHeaderElement,
} from './ManageContractsElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledHeading,
    StyledArrow,
    StyledEditInputElement,
    StyledClosePayElement,
    StyledHeader,
} from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledEditWrapper } from '../../atoms/StyledEditWrapper/StyledEditWrapper';
import { StyledGlobalUserPanelWrapper } from '../../atoms/StyledGlobalUserPanelWrapper/StyledGlobalUserPanelWrapper';
import { StyledSavePayElement } from '../../atoms/PayElement/StyledSavePayElement';

import useScrollBar from '../../../logic/hooks/useScrollBar';

import CloseIcon from '../../atoms/UserPanelStudens/CloseIcon';
import PencilIcon from '../../atoms/UserPanelStudens/PencilIcon';
import AcceptIcon from '../../atoms/UserPanelStudens/AcceptIcon';

import { formSchema } from './ManageContractsElement.data';
import { getString } from '../../../strings';

const ManageContractsElement = () => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    const [isEdit, setIsEdit] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            name: '',
            startDate: '',
            endDate: '',
        },
    });

    const onSubmit = useCallback((data) => {
        console.log('dodaj', data);
    }, []);

    const handleDelete = useCallback(() => console.log('delete'));

    return (
        <StyledManageContractsElement isopen={isOpen}>
            <StyledHeading onClick={toggleIsOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {getString('user_panel_manage_contracts_element_nazwa_umowy')}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledElement>
                    <ScrollSync>
                        <>
                            <ScrollSyncPane>
                                <StyledHeader
                                    isscrollbar={isScrollBar}
                                    ref={scrollElement}
                                    hasalignself="flex-start"
                                    $isCheckboxMinusWidth={104}
                                    $isCheckbox={true}
                                >
                                    <div>
                                        <StyledHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'user_panel_manage_contracts_element_nazwa'
                                                )}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'user_panel_manage_contracts_element_start'
                                                )}
                                            </StyledText>
                                        </StyledHeaderElement>
                                        <StyledHeaderElement>
                                            <StyledText
                                                hasdeclaredfontsize="16px"
                                                hasdeclaredfontweight="700"
                                                hasdeclaredtextalign="center"
                                                hasdeclaredlineheight="1.4em"
                                            >
                                                {getString(
                                                    'user_panel_manage_contracts_element_koniec'
                                                )}
                                            </StyledText>
                                        </StyledHeaderElement>
                                    </div>
                                </StyledHeader>
                            </ScrollSyncPane>
                            <StyledFlexElement onSubmit={handleSubmit(onSubmit)}>
                                <ScrollSyncPane>
                                    <div>
                                        {isEdit ? (
                                            <StyledEditWrapper>
                                                <StyledEditInputElement
                                                    error={errors?.['name']}
                                                    $hasDeclaredWidth="33%"
                                                >
                                                    <input type="text" {...register('name')} />
                                                </StyledEditInputElement>
                                                <StyledEditInputElement
                                                    error={errors?.['startDate']}
                                                    $hasDeclaredWidth="33%"
                                                >
                                                    <input type="date" {...register('startDate')} />
                                                </StyledEditInputElement>
                                                <StyledEditInputElement
                                                    {...register('endDate')}
                                                    error={errors?.['endDate']}
                                                    $hasDeclaredWidth="33%"
                                                >
                                                    <input type="date" />
                                                </StyledEditInputElement>
                                            </StyledEditWrapper>
                                        ) : (
                                            <StyledGlobalUserPanelWrapper>
                                                <StyledManageContractsElementDataText $hasDeclaredWidth="33%">
                                                    <StyledText
                                                        hasdeclaredfontsize="13px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtextalign="center"
                                                    >
                                                        {getString(
                                                            'user_panel_manage_contracts_element_nazwa'
                                                        )}
                                                    </StyledText>
                                                </StyledManageContractsElementDataText>
                                                <StyledManageContractsElementDataText $hasDeclaredWidth="33%">
                                                    <StyledText
                                                        hasdeclaredfontsize="13px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtextalign="center"
                                                    >
                                                        {getString(
                                                            'user_panel_manage_contracts_element_start'
                                                        )}
                                                    </StyledText>
                                                </StyledManageContractsElementDataText>
                                                <StyledManageContractsElementDataText $hasDeclaredWidth="33%">
                                                    <StyledText
                                                        hasdeclaredfontsize="13px"
                                                        hasdeclaredfontweight="600"
                                                        hasdeclaredtextalign="center"
                                                    >
                                                        {getString(
                                                            'user_panel_manage_contracts_element_koniec'
                                                        )}
                                                    </StyledText>
                                                </StyledManageContractsElementDataText>
                                            </StyledGlobalUserPanelWrapper>
                                        )}
                                    </div>
                                </ScrollSyncPane>
                                <StyledSavePayElement
                                    type={isEdit ? 'submit' : 'button'}
                                    isedit={isEdit}
                                    onClick={isEdit ? null : () => setIsEdit(!isEdit)}
                                >
                                    {!isEdit ? <PencilIcon /> : <AcceptIcon />}
                                </StyledSavePayElement>
                                <StyledClosePayElement type="button" onClick={handleDelete}>
                                    <CloseIcon />
                                </StyledClosePayElement>
                            </StyledFlexElement>
                        </>
                    </ScrollSync>
                </StyledElement>
            ) : null}
        </StyledManageContractsElement>
    );
};

export default ManageContractsElement;
