import React, { useState } from 'react';

import SmallLoader from '../Loader/SmallLoader';
import Loader from '../Loader/Loader';
import { StyledLoadingWrapper } from '../../atoms/LoadingImage/StyledLoadingWrapper';

const LoadingImageWithLoader = ({
    style: orig_style,
    smallLoader = false,
    innerRef,
    onLoad: orig_onLoad,
    ...props
}) => {
    const style = { ...(orig_style ?? {}), display: 'none' },
        [hasLoaded, setHasLoaded] = useState(false),
        onLoad = (e) => {
            setHasLoaded(true);

            e.target.style.display = orig_style?.display ?? null;

            if (typeof orig_onLoad === 'function') orig_onLoad(e);
        };
    return (
        <>
            {/* <img> zawsze musi tu być zwrócony - nie zacznie się ładować, jeśli nie jest częścią DOM'u */}
            <img ref={hasLoaded ? innerRef : undefined} onLoad={onLoad} style={style} {...props} />
            {!hasLoaded ? (
                <>
                    {smallLoader ? (
                        <StyledLoadingWrapper ref={!hasLoaded ? innerRef : undefined}>
                            <SmallLoader declaredWidth="100px" declaredHeight="100px" />
                        </StyledLoadingWrapper>
                    ) : (
                        <Loader ref={!hasLoaded ? innerRef : undefined} />
                    )}
                </>
            ) : null}
        </>
    );
};

export default LoadingImageWithLoader;
