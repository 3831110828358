import React, { useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import RoleElement from '../../molecules/UserPanelAddRole/RoleElement';

import { StyledRoleElement } from '../../atoms/UserPanelAddRole/StyledRoleElement';
import {
    StyledHeading,
    StyledArrow,
    StyledDataWrapper,
    StyledHeader,
    StyledHeaderElement,
} from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledDeleteCheckbox } from '../../atoms/UserPanelAddRole/StyledDeleteCheckbox';
import { StyledIsOpenWrapper } from '../../atoms/UserPanelAddRole/StyledIsOpenWrapper';

import { getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import { useQueryClient } from '@tanstack/react-query';

const UserPanelRoleElement = ({ role, users, trigger }) => {
    const queryClient = useQueryClient();
    const [isOpen, setIsOpen] = useState(false);
    const { scrollElement, isScrollBar } = useScrollBar(false, true);

    const handleOpen = () => {
        trigger();
        setIsOpen(!isOpen);
    };

    return (
        <StyledRoleElement isopen={isOpen}>
            <StyledHeading onClick={handleOpen}>
                <StyledText
                    hasdeclaredfontsize="22px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtextalign="center"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredfontcolor="#6786BE"
                >
                    {getString(`roles_${role}`)}
                </StyledText>
                <StyledHeadingCheckbox>
                    <StyledArrow isopen={isOpen} />
                </StyledHeadingCheckbox>
            </StyledHeading>
            {isOpen ? (
                <StyledDataWrapper>
                    <ScrollSync>
                        <>
                            <StyledIsOpenWrapper>
                                <ScrollSyncPane>
                                    <StyledHeader isscrollbar={isScrollBar} ref={scrollElement}>
                                        <div>
                                            <StyledHeaderElement $hasWidth="33%">
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('UserPanelAddRole__list__user')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement $hasWidth="33%">
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('UserPanelAddRole__list__email')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                            <StyledHeaderElement $hasWidth="33%">
                                                <StyledText
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    {getString('UserPanelAddRole__list__roles')}
                                                </StyledText>
                                            </StyledHeaderElement>
                                        </div>
                                    </StyledHeader>
                                </ScrollSyncPane>
                                <StyledDeleteCheckbox isbgcolor />
                            </StyledIsOpenWrapper>
                            <div
                                style={{
                                    marginTop: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '5px',
                                }}
                            >
                                {users.map((user) => (
                                    <RoleElement
                                        key={user.id}
                                        user={user}
                                        role={role}
                                    />
                                ))}
                            </div>
                        </>
                    </ScrollSync>
                </StyledDataWrapper>
            ) : null}
        </StyledRoleElement>
    );
};

export default UserPanelRoleElement;
