import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { ReactComponent as ContactSvg } from '../../../images/home/koperta.svg';
import { ReactComponent as Phone } from '../../../images/home/telefon.svg';
import { ReactComponent as Tournaments } from '../../../images/home/kostka.svg';
import { ReactComponent as Treningi } from '../../../images/home/treningi.svg';
import { ReactComponent as Gallery } from '../../../images/home/aparat.svg';

import bgcolor from '../../../images/register/background.jpg';

export const StyledContactSvg = styled(ContactSvg)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: none;
    transform: translateX(70px);

    @media only screen and (min-width: 1920px) {
        height: 260px;
    }

    @media only screen and (max-width: 1154px) {
        height: 140px;
        width: 240px;
    }

    @media only screen and (max-width: 943px) {
        height: 110px;
        width: 210px;
    }

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        width: 300px;
        height: 180px;
    }

    @media only screen and (max-width: 601px) {
        width: 260px;
        height: 140px;
    }

    @media only screen and (max-width: 535px) {
        transform: translateX(0);
    }

    @media only screen and (max-width: 465px) {
        width: 160px;
        height: 110px;
        transform: translateX(40px);
    }

    @media only screen and (max-width: 359px) {
        width: 130px;
    }
`;

export const StyledContactSvgWrapper = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledContectPhoneSvg = styled(Phone)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: none;

    @media only screen and (min-width: 1920px) {
        height: 205px;
    }

    @media only screen and (max-width: 1154px) {
        height: 140px;
        width: 240px;
    }

    @media only screen and (max-width: 943px) {
        height: 110px;
        width: 210px;
    }

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        width: 300px;
        height: 180px;
    }

    @media only screen and (max-width: 601px) {
        width: 260px;
        height: 140px;
    }

    @media only screen and (max-width: 535px) {
        width: 200px;
    }

    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }

    @media only screen and (max-width: 359px) {
        width: 180px;
        height: 90px;
    }
`;

export const StyledGallerySvg = styled(Gallery)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: none;

    @media only screen and (min-width: 1920px) {
        height: 260px;
        width: 420px;
    }

    @media only screen and (max-width: 943px) {
        height: 110px;
        width: 210px;
    }

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        width: 400px;
        height: 180px;
    }

    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }
`;

export const StyledHomePage = styled(motion.section)`
    background: url(${bgcolor}) no-repeat;
    background-size: cover;
    width: 100%;

    @media only screen and (max-width: 767px) {
        background-color: #f9ec4b;
    }

    @media only screen and (max-width: 465px) {
        background: #f9ec4b;
    }
`;

export const StyledHomePageCards = styled.section`
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
`;

export const StyledHomePageLogo = styled(motion.div)`
    width: 200px;
    height: 200px;
    border: 6px solid #fff;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 35px;
    overflow: hidden;

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        display: none;
    }
`;

export const StyledTournamentLink = styled(Link)`
    width: 50%;
    min-height: 50%;
    max-height: 50vh;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        width: 100%;
        min-height: 250px;
        max-height: none;
    }
`;

export const StyledTournamentSvg = styled(Tournaments)`
    position: relative;
    z-index: 1;
    width: 400px;
    height: 180px;
    fill: #fff;

    @media only screen and (min-width: 1920px) {
        height: 270px;
    }

    @media only screen and (max-width: 943px) {
        height: 115px;
        width: 210px;
    }

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        width: 400px;
        height: 180px;
    }

    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }
`;

export const StyledTrainingSvg = styled(Treningi)`
    position: relative;
    z-index: 1;
    width: 300px;
    height: 170px;
    fill: #000;

    @media only screen and (min-width: 1920px) {
        height: 270px;
        width: 420px;
    }

    @media only screen and (max-width: 943px) {
        height: 115px;
        width: 210px;
    }

    @media only screen and (max-width: ${({ isang }) => (isang ? '1036px' : '767px')}) {
        width: 400px;
        height: 180px;
    }

    @media only screen and (max-width: 465px) {
        width: 200px;
        height: 110px;
    }
`;
