import styled from 'styled-components';
import Background from '../../../images/register/background3.jpg';
import { motion } from 'framer-motion';

export const StyledThankYouPage = styled(motion.section)`
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;

    @media only screen and (max-width: 767px) {
        > h2 {
            font-size: 22px;
            padding: 0 20px;
        }
    }
`;
