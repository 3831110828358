import * as yup from 'yup';

export const formSchema = yup.object({
    namePL: yup.string().required(),
    nameGB: yup.string(),
    nameUA: yup.string(),
    nameCZ: yup.string(),
    studentPrice: yup.number().required(),
    trainerPrice: yup.number().required(),
});
