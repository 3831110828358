import styled from 'styled-components';

export const StyledRegistrationAccepted = styled.div`
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    a {
        text-decoration: none;
        color: #6786be;
    }

    @media only screen and (max-width: 767px) {
        margin-top: 70px;
    }
`;
