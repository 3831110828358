import styled from 'styled-components';

export const StyledInformationWrapper = styled.div`
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: start;
    align-content: flex-start;

    > p {
        > a {
            color: #000;
            text-decoration: none;
        }
    }

    @media only screen and (min-width: 1441px) {
        p,
        span {
            font-size: 26px;
        }
    }
`;
