import styled from 'styled-components';

export const StyledPlayerAvatar = styled.div`
    width: 37px;
    height: 37px;
    background: #fff;
    border-radius: ${({ issend }) => (issend ? '0' : '5px')};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: ${({ issend }) => (issend ? 'none' : '1px solid #6786be')};
    margin-right: 12px;
    box-sizing: border-box;

    > div {
        width: 100%;
        height: 100%;

        > img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            cursor: pointer;
        }

        > div {
            width: 100%;
            padding: 80px;
            max-width: 940px;

            > img {
                object-fit: contain;
            }
        }
    }

    > svg {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        fill: #6786be;
    }

    @media only screen and (max-width: 1300px) {
        margin-right: 0;
        margin-top: 8px;
        padding: 0;

        > img {
            -webkit-transform: translateX(-1px);
            -ms-transform: translateX(-1px);
            transform: translateX(-1px);
        }
    }
`;
