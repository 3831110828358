import styled from 'styled-components';

export const StyledGlobalUserPanelWrapper = styled.div`
    width: 100%;
    min-width: 700px;
    min-height: 48px;
    height: 100%;
    background: #fff;
    border: 4px solid #6786be;
    display: flex;
    justify-content: space-between;
    border-radius: 25px;
    padding: 5px 0;
`;
