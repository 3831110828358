import styled from 'styled-components';

export const StyledRightTrainingWrapper = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;

    @media only screen and (max-width: 1196px) {
        width: 65%;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`;
