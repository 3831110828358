import { StyledText } from '../Text/StyledText';

const ErrorText = ({ text }) => (
    <StyledText
        hasdeclaredfontsize="16px"
        hasdeclaredfontcolor="#F74017"
        hasdeclaredtextalign="center"
        hasdeclaredfontweight="700"
        hasdeclaredtexttransform="uppercase"
        hasdeclaredlineheight="1.4em"
    >
        {text}
    </StyledText>
);

export default ErrorText;
