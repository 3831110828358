import { StyledButton } from '../../atoms/Button/StyledButton';

const UserPanelTournamentButton = ({ name, color, onClick }) => (
    <StyledButton
        hasdeclaredfontweight="bold"
        hasdeclaredfontsize="18px"
        hasdeclaredtextalign="center"
        hasdeclaredbgcolor={color}
        hasdeclaredfontcolor="#fff"
        hasdeclaredtexttransform="uppercase"
        hasdeclaredborderradius="25px"
        hasdeclaredcursor="pointer"
        onClick={onClick}
        whileHover={color === '#4E6BAA' ? { scale: 0.95 } : null}
    >
        {name}
    </StyledButton>
);

export default UserPanelTournamentButton;
