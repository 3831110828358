import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { AnimatePresence } from 'framer-motion';

import RegisterErrorMessage from '../../molecules/Register/RegisterErrorMessage';
import InputWrapper from '../../molecules/Register/InputWrapper';

import { StyledQuestions } from '../../atoms/Questions/StyledQuestions';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledQuestionsForm } from '../../atoms/Questions/StyledQuestionsForm';

import { send } from '../../../logic/requests/contact.js';
import { getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const Questions = () => {
    const { scrollElement, isScrollBar } = useScrollBar();
    const [isSend, setIsSend] = useState(false);

    const validationSchema = Yup.object({
        title: Yup.string()
            .min(3, getString('contact_contactForm_zaKrotkiTytul'))
            .required(getString('contact_contactForm_tytulWymagany')),
        email: Yup.string()
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                getString('contact_contactForm_emailNiepoprawny')
            )
            .required(getString('contact_contactForm_emailWymagany')),
        message: Yup.string()
            .min(10, getString('contact_contactForm_minimum10znakow'))
            .required(getString('contact_contactForm_wiadomoscWymagana')),
    });

    const handleSubmit = (data, { setSubmitting }) => {
        send(data.email, data.title, data.message, true)
            .then(() => {
                setSubmitting(false);
                setIsSend(true);
            })
            .catch((err) => {
                console.error('handleSubmit', err);
                setSubmitting(false);
            });
    };

    return (
        <StyledQuestions
            issend={isSend}
            isscrollbar={isScrollBar}
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {isSend ? (
                <>
                    <StyledText
                        hasdeclaredfontsize="44px"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.2em"
                        hasdeclaredfontweight="600"
                        hasdeclaredfontcolor="#6AB95D"
                        hasdeclaredmargin="-50px 0 0 0"
                        as="h2"
                    >
                        {getString('Trainings__Questions__wiadomosc_zostala_wyslana')}
                    </StyledText>
                    <StyledButton
                        hasdeclaredfontcolor="#fff"
                        hasdeclaredpadding="8px 44px"
                        hasdeclaredmargin="24px 0 0 0"
                        hasdeclaredbgcolor="#6786BE"
                        hasdeclaredfontweight="600"
                        hasdeclaredborderradius="25px"
                        hasdeclaredcursor="pointer"
                        whileHover={{ scale: 0.95 }}
                        hasdeclaredtexttransform="uppercase"
                        onClick={() => setIsSend(false)}
                    >
                        {getString('Trainings__Questions__zamknij')}
                    </StyledButton>
                </>
            ) : (
                <>
                    <StyledText
                        hasdeclaredfontsize="38px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="8px 0 8px 0"
                        hasdeclaredfontweight="700"
                        as="h1"
                    >
                        {getString('Trainings__Questions__masz_pytania')}?
                    </StyledText>
                    <StyledText
                        hasdeclaredfontsize="38px"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="0 0 18px 0"
                        hasdeclaredfontweight="700"
                        as="h2"
                    >
                        {getString('Trainings__Questions__napisz_do_nas')}!
                    </StyledText>
                    <Formik
                        initialValues={{ email: '', title: '', message: '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={true}
                        validateOnBlur={false}
                    >
                        {({ errors, handleSubmit, isSubmitting }) => (
                            <AnimatePresence>
                                <StyledQuestionsForm onSubmit={handleSubmit} error={errors.message}>
                                    <div>
                                        <InputWrapper
                                            inputType="email"
                                            title={getString(
                                                'Trainings__Questions__twoj_adres_email'
                                            )}
                                            name="email"
                                            error={errors.email}
                                            hasDeclaredBottomPosition="-8px"
                                        />
                                    </div>
                                    <div>
                                        <InputWrapper
                                            inputType="text"
                                            title={getString('Trainings__Questions__tytul_emaila')}
                                            name="title"
                                            error={errors.title}
                                            hasDeclaredBottomPosition="-8px"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="message">
                                            <StyledText
                                                hasdeclaredfontsize="20px"
                                                hasdeclaredfontweight="600"
                                                hasdeclaredtextalign="center"
                                            >
                                                {getString(
                                                    'Trainings__Questions__napisz_wiadomosc'
                                                )}
                                            </StyledText>
                                        </label>
                                        <Field as="textarea" name="message" />
                                        <RegisterErrorMessage
                                            name="message"
                                            hasDeclaredBottomPosition="-8px"
                                        />
                                    </div>
                                    <StyledButton
                                        hasdeclaredfontcolor="#fff"
                                        hasdeclaredpadding="8px 44px"
                                        hasdeclaredmargin="24px 0 0 0"
                                        hasdeclaredbgcolor="#6786BE"
                                        hasdeclaredfontweight="600"
                                        hasdeclaredborderradius="25px"
                                        hasdeclaredcursor="pointer"
                                        type="submit"
                                        disabled={isSubmitting}
                                        whileHover={{ scale: 0.95 }}
                                        hasdeclaredtexttransform="uppercase"
                                    >
                                        {getString('Trainings__Questions__wyslij')}
                                    </StyledButton>
                                </StyledQuestionsForm>
                            </AnimatePresence>
                        )}
                    </Formik>
                </>
            )}
        </StyledQuestions>
    );
};

export default Questions;
