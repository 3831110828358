import React from 'react'
import { getString } from "../../../strings";

import PartnerElement from "./PartnerElement";

import { StyledTrainingsPartners } from "../../atoms/TrainingsPartners/StyledTrainingsPartners"
import { StyledText } from "../../atoms/Text/StyledText";

import hallcube from "../../../images/sponsors/halcubepl.png";
import speedcube from "../../../images/sponsors/speedcube.svg";

import useScrollBar from '../../../logic/hooks/useScrollBar';

const TrainingsPartners = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledTrainingsPartners 
            isscrollbar={isScrollBar} 
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <StyledText
                hasdeclaredfontsize="38px"
                hasdeclaredfontweight="700"
                hasdeclaredtextalign="center"
                hasdeclaredpadding="0 20px 40px 20px"
                hasdeclaredlineheight="1.4em "
                as="h1"
            >
                {getString("trainings_partners_poznajnaszych")}
            </StyledText>
            <PartnerElement
                image={speedcube}
                title={`
                    ${getString("trainings_partners_jesttopartner")} 
                    <a aria-label=${getString("seo_aria_label_przejdz_do_strony")} href="https://speedcube.pl/">Speedcube.pl</a>
                    ${getString("trainings_partners_zawszewspiera")}
                    <a aria-label=${getString("seo_aria_label_przejdz_do_strony")} href="https://speedcube.pl/">Speedcube.pl</a>
                    ${getString("trainings_partners_zajmujesie")}
                `}
                alt="speedcube"
                link="https://speedcube.pl/"
            />
            <PartnerElement
                image={hallcube}
                title={`
                    ${getString("trainings_partners_jesttopartnerzktorym")} 
                    <a aria-label=${getString("seo_aria_label_przejdz_do_strony")} href="https://www.halcube.pl/">Halcube.pl</a>
                    ${getString("trainings_partners_wspieranas")}
                    <a aria-label=${getString("seo_aria_label_przejdz_do_strony")} href="https://www.halcube.pl/">Halcube.pl</a>
                    ${getString("trainings_partners_wspieranaspodczas2")}
                `}
                alt="Hallcube"
                link="https://www.halcube.pl/"
            />
        </StyledTrainingsPartners>
    );
}

export default TrainingsPartners
