import React from 'react';

import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledArrow } from '../../../styles/sharedStyles';

const UserPanelButton = ({ isActive, label, onClick, hasArrow, isOpen }) => {
    return (
        <StyledButton
            hasdeclaredfontweight="600"
            hasdeclaredfontsize="22px"
            hasdeclaredtextalign="center"
            hasdeclaredbgcolor={isActive ? '#592E28' : '#ee745c'}
            hasdeclaredfontcolor="#fff"
            hasdeclaredtexttransform="uppercase"
            hasdeclaredborderradius="25px"
            hasdeclaredcursor="pointer"
            hasdeclaredmargin="20px 0 0 0"
            onClick={onClick}
        >
            {label}
            {hasArrow ? <StyledArrow isopen={isOpen} /> : null}
        </StyledButton>
    );
};

export default UserPanelButton;
