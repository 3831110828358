import styled from 'styled-components';

export const StyledUserPanelResultsIconsWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-width: 624px;
    width: 60%;
    margin-left: 2%;
    min-height: 30px;

    > svg {
        width: 35px;
        height: 25px;
        fill: #000;
    }
`;
