import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledLoggoutWrapper = styled(motion.div)`
    width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    button {
        transition: background-color 250ms linear;

        &:hover {
            background-color: #602b26;
        }
    }

    > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 200px;
    }
`;
