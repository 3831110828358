import React from 'react';

import { StyledHeart } from '../../atoms/Gallery/StyledGallery';

import { ReactComponent as Heart } from '../../../images/gallery/heart.svg';
import { addToFavorites, removeFromFavorites } from '../../../logic/requests/gallery.js';

const AddToFavourite = ({ img, isFill, refreshFavoritesAdd, refreshFavoritesRemove }) => {
    const handleAddToFavourite = (e) => {
        e.preventDefault();

        if (isFill) {
            removeFromFavorites(img.eventId, img.fileName)
                .then(() => {
                    refreshFavoritesRemove?.(img);
                })
                .catch((err) => {
                    console.error('removeFromFavorites', img, err);
                    // TODO: Error handling?
                });
        } else {
            addToFavorites(img.eventId, img.fileName)
                .then(() => {
                    refreshFavoritesAdd?.(img);
                })
                .catch((err) => {
                    console.error('addToFavorites', img, err);
                    // TODO: Error handling?
                });
        }
    };
    return (
        <StyledHeart hasfill={isFill} onClick={(e) => handleAddToFavourite(e)}>
            <Heart />
        </StyledHeart>
    );
};

export default AddToFavourite;
