import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledCubeWrapper = styled(motion.div)`
    transform: ${({ hasdeclaredtransform }) =>
        hasdeclaredtransform ? hasdeclaredtransform : 'none'};
    grid-area: ${({ hasdeclaredgridarea }) => (hasdeclaredgridarea ? hasdeclaredgridarea : 'none')};
    align-self: ${({ hasdeclaredalignself }) =>
        hasdeclaredalignself ? hasdeclaredalignself : 'none'};
    margin: ${({ hasdeclaredmargin }) => (hasdeclaredmargin ? hasdeclaredmargin : '0')};
    padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? hasdeclaredpadding : '0')};
    position: absolute;
    top: ${({ hasdeclaredtopposition }) =>
        hasdeclaredtopposition ? hasdeclaredtopposition : 'initial'};
    bottom: ${({ hasdeclaredbottomposition }) =>
        hasdeclaredbottomposition ? hasdeclaredbottomposition : 'initial'};
    left: ${({ hasdeclaredleftposition }) =>
        hasdeclaredleftposition ? hasdeclaredleftposition : 'initial'};
    right: ${({ hasdeclaredrightposition }) =>
        hasdeclaredrightposition ? hasdeclaredrightposition : 'initial'};

    svg {
        @media only screen and (max-width: 1144px) {
            width: 175px;
            height: 175px;
        }
    }
`;
