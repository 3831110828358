import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Loader from "../Loader/Loader";
import SettlementsDoneElement from "./SettlementsDoneElement";
import PaymentsPopup from "../Payments/PaymentsPopup";

import { StyledUserPanelParent } from "../../atoms/UserPanelParent/StyledUserPanelParent";
import { StyledLoaderWrapper } from "../../../styles/sharedStyles";
import { StyledUserPanelParentHeading } from "../../atoms/UserPanelParent/StyledUserPanelParentHeading";
import { StyledText } from "../../atoms/Text/StyledText";
import { StyledButtonsWrapper } from "../../atoms/UserPanelParent/StyledButtonsWrapper";
import { StyledButton } from "../../atoms/Button/StyledButton";

import { getLang, getLocale, getString } from "../../../strings";
import { listDues, payDues } from "../../../logic/requests/students.js";
import { cebulaCompare } from "../../../logic/arrays.js";

import {
    StyledGlobalScrollWrapper,
    StyledGlobalHeadingWrapper,
} from "../../../styles/sharedStyles";
import useScrollBar from "../../../logic/hooks/useScrollBar";

const reductor = (hours, add_student_name = false) =>
    hours.reduce((prev, cur) => {
        const date = new Date(cur.date),
            year = date.getFullYear(),
            month = date.getMonth(),
            key = `${year}-${month}_${cur.student.id}`,
            student = `${cur.student.firstname} ${cur.student.lastname}`.toUpperCase(),
            name =
                `${date
                    .toLocaleString(getLocale(getLang()), { month: "long" })
                    .toUpperCase()} ${year}` + (add_student_name ? ` - ${student}` : "");

        if (!prev[key])
            prev[key] = {
                name,
                date: {
                    year,
                    month,
                },
                student: cur.student,
                hours: [],
            };

        prev[key].hours.push(cur);

        return prev;
    }, {});

const handleSubmit = (container, all_hours, show_popup = undefined, all_months = false) => {
    const months = [...container.querySelectorAll('[id^="accept-"]')]
            .filter((checkbox) => checkbox.checked)
            .map(({ id }) => id.split("-").slice(1).join("-")),
        month = months.length === 1 ? months[0].split("-").slice(1).join("-") : undefined,
        hours = all_months
            ? Object.values(all_hours)
                  .map((month) => month.hours.map((hour) => hour.id))
                  .reduce((prev, cur) => prev.concat(cur), [])
            : months
                  .map((id) => all_hours[id].hours.map((hour) => hour.id))
                  .reduce((prev, cur) => prev.concat(Array.isArray(cur) ? cur : [cur]), []),
        data = {
            month,
            hours,
        };

    if (show_popup) {
        const now = new Date();
        if (
            (all_months ? Object.keys(all_hours) : months)
                .map((id) => id.split("_")[0])
                .includes(`${now.getFullYear()}-${now.getMonth()}`)
        )
            return show_popup();
    }

    payDues(data)
        .then((res) => {
            console.log("payDues", res);
            if (res.token) {
                const service = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "uat" ? "secure" : "sandbox";
                window.location = `https://${service}.przelewy24.pl/trnRequest/${res.token}`;
            }
        })
        .catch((err) => {
            console.log("payDues", err);
        });
};

const UserPanelParentSettlements = ({ user }) => {
    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);
    const [isPopup, setIsPopup] = useState(false);

    const [hours, setHours] = useState();
    const [paidHours, setPaidHours] = useState();
    const [err, setErr] = useState(false);

    useEffect(() => {
        listDues()
            .then((res) => {
                console.log("listDues", res);
                setHours(
                    reductor(
                        res.filter((hour) => !hour.paid),
                        Boolean(user.children?.length)
                    )
                );
                setPaidHours(
                    reductor(
                        res.filter((hour) => hour.paid),
                        Boolean(user.children?.length)
                    )
                );
            })
            .catch((err) => {
                console.error("listDues", err);
                setHours([]);
                setPaidHours([]);
                setErr(true);
            });
    }, [setHours, setPaidHours, setErr]);

    const [isSelecting, setIsSelecting] = useState(false);

    return (
        <StyledUserPanelParent>
            {isPopup && (
                <PaymentsPopup
                    onClose={() => {
                        if (typeof isPopup === "function") {
                            isPopup();
                        } else {
                            handleSubmit(scrollElement.current, hours, undefined, !isSelecting);
                        }
                        setIsPopup(false);
                    }}
                    onCancel={() => setIsPopup(false)}
                    closeTag={getString("Trainings_trainings_parent_settlements_kontynuuj")}
                    header={getString("Trainings_trainings_parent_settlements_uwaga")}
                    title={getString("Trainings_trainings_parent_settlements_miesiacJeszczeSie")}
                    cancelTag={getString("Trainings_trainings_parent_settlements_anuluj")}
                />
            )}
            {!(hours && paidHours) ? (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            ) : !(Object.keys(hours).length || Object.keys(paidHours).length) ? (
                <StyledLoaderWrapper>
                    <StyledText
                        hasdeclaredpadding="0px 20px"
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredlineheight="1.4em"
                        as="p"
                    >
                        {getString(err ? "blad_wczytywania" : "UserPanelTrainerPay__no_hours")}
                    </StyledText>
                </StyledLoaderWrapper>
            ) : (
                <>
                    <StyledGlobalHeadingWrapper>
                        <StyledUserPanelParentHeading>
                            <StyledText
                                hasdeclaredfontsize="36px"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                                hasdeclaredlineheight="1.4em"
                                as="h2"
                            >
                                {getString("Trainings_trainings_parent_settlements_rozliczenia")}
                            </StyledText>
                            <StyledText
                                hasdeclaredfontsize="22px"
                                hasdeclaredfontweight="700"
                                hasdeclaredlineheight="1.4em"
                                hasdeclaredpadding="0 25px 0 0"
                                hasdeclaredtextalign="center"
                                as="p"
                            >
                                {getString(
                                    "Trainings_trainings_parent_settlements_jesliPotrzebujeszFaktury"
                                )}{" "}
                                <Link to="/contact">
                                    {getString(
                                        "Trainings_trainings_parent_settlements_skontaktujSieZNami"
                                    )}
                                </Link>
                            </StyledText>
                        </StyledUserPanelParentHeading>
                    </StyledGlobalHeadingWrapper>
                    <StyledGlobalScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                        <div>
                            {Object.keys(hours).length ? (
                                <>
                                    <StyledText
                                        hasdeclaredfontsize="36px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredmargin="0 0 27px"
                                    >
                                        {getString(
                                            "Trainings_trainings_parent_settlements_nieoplacone"
                                        )}
                                    </StyledText>
                                    {Object.entries(hours)
                                        .sort(([_, a], [_2, b]) => {
                                            let ret = 0;
                                            ret = b.date.year - a.date.year;

                                            if (!ret) ret = b.date.month - a.date.month;

                                            if (!ret) {
                                                const student_a = `${a.student.lastname} ${b.student.firstname}`;
                                                const student_b = `${b.student.lastname} ${b.student.firstname}`;
                                                ret = cebulaCompare(student_a, student_b);
                                            }

                                            return ret;
                                        })
                                        .map(([key, { name, hours }], index) => (
                                            <SettlementsDoneElement
                                                key={key}
                                                id={key}
                                                name={name}
                                                hours={hours}
                                                parentIsSelecting={isSelecting}
                                                parentSetIsSelecting={setIsSelecting}
                                                isTrainer={false}
                                                isPaid={false}
                                                showPopup={() => setIsPopup(true)}
                                                triggerParentScroll={trigger}
                                                isFirstChild={index === 0}
                                            />
                                        ))}
                                    <StyledButtonsWrapper checkboxes={isSelecting}>
                                        <StyledButton
                                            hasdeclaredbgcolor={isSelecting ? "#EE745C" : "#6786BE"}
                                            hasdeclaredpadding="0 28px"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredborderradius="25px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredcursor="pointer"
                                            hasdeclaredfontsize="18px"
                                            onClick={() => setIsSelecting((val) => !val)}
                                        >
                                            {isSelecting
                                                ? getString(
                                                      "Trainings_trainings_parent_settlements_anuluj2"
                                                  )
                                                : getString(
                                                      "Trainings_trainings_parent_settlements_wybierzIOplac"
                                                  )}
                                        </StyledButton>
                                        <StyledButton
                                            hasdeclaredbgcolor="#6786BE"
                                            hasdeclaredfontcolor="#fff"
                                            hasdeclaredpadding="0 28px"
                                            hasdeclaredborderradius="25px"
                                            hasdeclaredfontweight="700"
                                            hasdeclaredtextalign="center"
                                            hasdeclaredcursor="pointer"
                                            hasdeclaredfontsize="18px"
                                            whileHover={{ scale: 0.95 }}
                                            onClick={() =>
                                                handleSubmit(
                                                    scrollElement.current,
                                                    hours,
                                                    () => setIsPopup(true),
                                                    !isSelecting
                                                )
                                            }
                                        >
                                            {getString(
                                                "Trainings_trainings_parent_settlements_oplac"
                                            )}{" "}
                                            {isSelecting
                                                ? getString(
                                                      "Trainings_trainings_parent_settlements_zaznaczone"
                                                  )
                                                : getString(
                                                      "Trainings_trainings_parent_settlements_wszystko"
                                                  )}
                                        </StyledButton>
                                    </StyledButtonsWrapper>
                                </>
                            ) : (
                                <StyledText
                                    hasdeclaredfontsize="24px"
                                    hasdeclaredfontweight="700"
                                    hasdeclaredtextalign="center"
                                    hasdeclaredlineheight="1.4em"
                                >
                                    {getString(
                                        "Trainings_trainings_parent_settlements_jestesNaBierzaco"
                                    )}
                                </StyledText>
                            )}
                            {Object.keys(paidHours).length ? (
                                <>
                                    <StyledText
                                        hasdeclaredfontsize="36px"
                                        hasdeclaredfontweight="700"
                                        hasdeclaredtextalign="center"
                                        hasdeclaredlineheight="1.4em"
                                        hasdeclaredmargin="27px 0"
                                    >
                                        {getString(
                                            "Trainings_trainings_parent_settlements_oplacone"
                                        )}
                                    </StyledText>
                                    {Object.entries(paidHours).map(([key, { name, hours }]) => (
                                        <SettlementsDoneElement
                                            key={key}
                                            id={key}
                                            name={name}
                                            hours={hours}
                                            isTrainer={false}
                                            isPaid={true}
                                            triggerParentScroll={trigger}
                                            isFirstChild
                                        />
                                    ))}
                                </>
                            ) : undefined}
                        </div>
                    </StyledGlobalScrollWrapper>
                </>
            )}
        </StyledUserPanelParent>
    );
};

export default UserPanelParentSettlements;
