import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { getString } from '../../../strings';
import GalleryList from '../../molecules/Gallery/GalleryList';

import { StyledMenuElement } from '../../molecules/Series/StyledMenuElement';
import { StyledText } from '../../atoms/Text/StyledText';
import {
    StyledGalleryMenu,
    StyledFavouriteWrapper,
    StyledGalleryMenuWrapperDiv,
    StyledGalleryMenuMobile,
    StyledGalleryMenuWrapper,
    StyledChooseGallery,
} from '../../atoms/Gallery/StyledGallery';
import { StyledArrow } from '../../../styles/sharedStyles';
import useWindowSize from '../../../utils/getWindowSize';
import { FAVORITES_ID } from '../../constants/GallerySite';
import useScrollBar from '../../../logic/hooks/useScrollBar';

const GalleryMenu = ({ user, menuData, loadSeries, hasMore, activeEvent, setActiveEvent }) => {
    const width = useWindowSize();
    const [isOpen, setIsOpen] = useState(null);
    const [isClicked, setIsClicked] = useState(true);

    const { isScrollBar, scrollElement, trigger } = useScrollBar(true);

    useEffect(() => trigger?.(), [trigger, isClicked, activeEvent]);

    const { series, events } = menuData;

    const activeSerie = series.find((serie) => serie.events.includes(activeEvent));
    const eventWithoutSeriesAreActive = events.includes(activeEvent);

    const handleShowMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <StyledGalleryMenu ismobile={width > 767 ? false : true}>
            {width > 767 ? (
                <StyledGalleryMenuWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                    <StyledFavouriteWrapper ismobile={width > 767 ? false : true}>
                        {user ? (
                            <StyledMenuElement
                                hasdeclaredmargin="0 0 44px 0"
                                hasdeclaredbgcolor={
                                    activeEvent?.id === FAVORITES_ID ? '#602B26' : '#ee745c'
                                }
                                iscenterelement={true}
                                onClick={() =>
                                    setActiveEvent(
                                        activeEvent?.id === FAVORITES_ID
                                            ? null
                                            : { id: FAVORITES_ID, name: 'Ulubione' }
                                    )
                                }
                                ismobile={width > 767 ? false : true}
                            >
                                <StyledText
                                    hasdeclaredfontsize="24px"
                                    hasdeclaredfontcolor="#fff"
                                    hasdeclaredfontweight="600"
                                    hasdeclaredtexttransform="uppercase"
                                    hascursor="pointer"
                                    hasdeclaredtextalign="center"
                                >
                                    {getString('gallery_galleryMenu_ulubione')}
                                </StyledText>
                            </StyledMenuElement>
                        ) : null}
                    </StyledFavouriteWrapper>
                    <InfiniteScroll loadMore={loadSeries} hasMore={hasMore} initialLoad={false}>
                        {series.map((serie, idx) => (
                            <GalleryList
                                key={idx}
                                isMobile={width > 767 ? false : true}
                                series={serie}
                                initialOpen={serie === activeSerie}
                                activeEvent={activeEvent}
                                setActiveEvent={setActiveEvent}
                                setActiveClicked={setIsClicked}
                                isClickedValue={isClicked}
                            />
                        ))}
                        {events.length ? (
                            // TODO: podział na lata!
                            <GalleryList
                                key={-1}
                                isMobile={width > 767 ? false : true}
                                series={{ name: '2022', events }}
                                initialOpen={eventWithoutSeriesAreActive}
                                activeEvent={activeEvent}
                                setActiveEvent={setActiveEvent}
                                setActiveClicked={setIsClicked}
                                isClickedValue={isClicked}
                            />
                        ) : undefined}
                    </InfiniteScroll>
                </StyledGalleryMenuWrapper>
            ) : (
                <StyledGalleryMenuWrapper>
                    {user ? (
                        <StyledMenuElement
                            hasdeclaredbgcolor={
                                activeEvent?.id === FAVORITES_ID ? '#602B26' : '#ee745c'
                            }
                            ismobile={true}
                            iscenterelement={true}
                            onClick={() =>
                                setActiveEvent(
                                    activeEvent?.id === FAVORITES_ID
                                        ? null
                                        : { id: FAVORITES_ID, name: 'Ulubione' }
                                )
                            }
                        >
                            <StyledText
                                hasdeclaredfontsize="24px"
                                hasdeclaredfontcolor="#fff"
                                hasdeclaredfontweight="600"
                                hasdeclaredtexttransform="uppercase"
                                hascursor="pointer"
                                hasdeclaredlineheight="1.3em"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="0 10px"
                            >
                                {getString('gallery_galleryMenu_ulubione2')}
                            </StyledText>
                        </StyledMenuElement>
                    ) : null}
                    <StyledChooseGallery onClick={handleShowMenu}>
                        <StyledText
                            hasdeclaredfontsize="26px"
                            hasdeclaredfontweight="600"
                            hasdeclaredtextalign="center"
                            hasdeclaredtexttransform="uppercase"
                            hascursor="pointer"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredfontcolor="#fff"
                            hasdeclaredpadding="0 10px"
                        >
                            {getString('gallery_galleryMenu_wybierzZawody')}
                        </StyledText>
                        <StyledArrow isopen={isOpen} />
                    </StyledChooseGallery>
                    {isOpen && (
                        <StyledGalleryMenuMobile>
                            <StyledGalleryMenuWrapperDiv>
                                <InfiniteScroll
                                    loadMore={loadSeries}
                                    hasMore={hasMore}
                                    initialLoad={false}
                                >
                                    {series.map((serie, idx) => (
                                        <GalleryList
                                            key={idx}
                                            isMobile={width > 767 ? false : true}
                                            series={serie}
                                            initialOpen={serie === activeSerie}
                                            activeEvent={activeEvent}
                                            setActiveEvent={setActiveEvent}
                                            setActiveClicked={setIsClicked}
                                            isClickedValue={isClicked}
                                        />
                                    ))}
                                    {events.length ? (
                                        // TODO: podział na lata!
                                        <GalleryList
                                            key={-1}
                                            isMobile={width > 767 ? false : true}
                                            series={{ name: '2022', events }}
                                            initialOpen={eventWithoutSeriesAreActive}
                                            activeEvent={activeEvent}
                                            setActiveEvent={setActiveEvent}
                                            setActiveClicked={setIsClicked}
                                            isClickedValue={isClicked}
                                        />
                                    ) : undefined}
                                </InfiniteScroll>
                            </StyledGalleryMenuWrapperDiv>
                        </StyledGalleryMenuMobile>
                    )}
                </StyledGalleryMenuWrapper>
            )}
        </StyledGalleryMenu>
    );
};

export default GalleryMenu;
