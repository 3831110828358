import styled from 'styled-components';

export const StyledTournamentSponsors = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 20px 0 0;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        background: transparent;
        width: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        width: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: transparent;
        width: 0;
    }

    > img {
        max-width: 100px;
        max-height: 60px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-right: 12px;
        margin-top: 8px;
    }

    @media only screen and (max-width: 767px) {
        position: relative;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        left: auto;
        bottom: auto;
        margin-top: 30px;
        padding: 20px 0 0 0;
    }
`;
