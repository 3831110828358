import {
    addNonUserParent,
    assignParent,
    togglePreference,
    unassignParent,
    updateStudent,
} from '../../../logic/requests/students.js';
import { getString } from '../../../strings';

export const togglePref = (trainer_id, student, setStudent, pref) => {
    if (!['preferred', 'forbidden'].includes(pref)) {
        console.warn('togglePref', 'got invalid pref value', pref);
        return;
    }
    const numberPref = pref === 'preferred' ? 1 : -1;

    togglePreference({ id: student.id, type: numberPref })
        .then((res) => {
            setStudent({
                ...student,
                preferences: {
                    ...student.preferences,
                    [trainer_id]: student.preferences[trainer_id]
                        ? student.preferences[trainer_id] === numberPref
                            ? 0
                            : numberPref
                        : numberPref,
                },
            });
        })
        .catch((err) => {
            console.error('togglePreference', err);
        });
};

export const getFormData = (refs, defaultValues) =>
    Object.fromEntries(
        Object.entries(refs)
            .map(([key, val]) => {
                const ret = val?.current?.value;
                return ret !== defaultValues[key] && !(!defaultValues[key] && !ret.length)
                    ? [key, ret]
                    : undefined;
            })
            .filter((val) => val)
    );

const validateFields = (fields) => {
    const newErrors = {};
    Object.keys(fields).forEach((key) => {
        if (!fields[key].trim()) {
            newErrors[key] = true;
        }
    });
    return newErrors;
};

export const handleSubmit = (data, student, setStudent, closePanel, setErrors) => {
    const newErrors = validateFields(data);

    setErrors(newErrors);

    if (!Object.keys(data).length) return closePanel();

    if (Object.keys(newErrors).length === 0) {
        updateStudent(student.id, data)
            .then((res) => {
                const student_data = Object.fromEntries(
                    Object.entries(data)
                        .filter(([key]) => key.startsWith('student_'))
                        .map(([key, val]) => [key.replace('student_', ''), val])
                );
                const parent_data = Object.fromEntries(
                    Object.entries(data)
                        .filter(([key]) => key.startsWith('parent_'))
                        .map(([key, val]) => [key.replace('parent_', ''), val?.length ? val : null])
                );

                const new_parent = {
                    ...student.parent,
                    ...parent_data,
                };

                const new_student = {
                    ...student,
                    ...student_data,
                    ...(Object.keys(new_parent).length ? { parent: new_parent } : undefined),
                };

                setStudent(new_student);
                closePanel();
            })
            .catch((err) => {
                console.error(err);
            });
    }
};

export const handleSubmitParent = (student_id, parent, setStudent, onSuccess) => {
    if (
        !student_id ||
        !parent ||
        ['id', 'firstname', 'lastname', 'email', 'phone'].some((val) => !parent[val])
    ) {
        console.warn('handleSubmitParent', 'Got invalid parameters', student_id, parent);
        return;
    }

    // przypisz użytkownika o podanym ID
    assignParent(student_id, parent.id)
        .then(() => {
            setStudent?.((student) => ({
                ...student,
                parent: {
                    ...parent,
                    hasAccount: true,
                },
            }));
            onSuccess?.();
        })
        .catch((err) => {
            console.error('assignParent', err);
        });
};

export const handleSubmitNonParent = (student_id, data, setStudent, onSuccess) => {
    if (!student_id) {
        console.warn('handleSubmitParent', 'No student ID given! Aborting');
        return;
    }

    if (!Object.keys(data).length) {
        // pusty formularz
        onSuccess?.();
        return;
    }
    // brak dodatkowej walidacji - wszystkie pola są opcjonalne, ale musi podać conajmniej jedno

    addNonUserParent(student_id, data)
        .then((res) => {
            setStudent?.((student) => ({
                ...student,
                parent: {
                    id: res.id,
                    ...data,
                    hasAccount: false,
                },
            }));
            onSuccess?.();
        })
        .catch((err) => {
            console.error('addNonUserParent', err);
        });
};

export const handleDeleteParent = (id, setStudent, onSuccess) => {
    if (!window.confirm(getString('UserPanelStudents__delete_parent__confirm'))) return;

    unassignParent(id)
        .then(() => {
            setStudent?.((student) => {
                const ret = { ...student };
                if (ret.parent) delete ret.parent;
                return ret;
            });
            onSuccess?.();
        })
        .catch((err) => {
            console.error('handleDeleteParent', err);
        });
};

export const noneString = '-';

export const defaultValuesParent = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
};
