import styled from 'styled-components';

export const StyledTrainingsInformationsContent = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin-bottom: 40px;

    @media only screen and (min-width: 1441px) {
        max-width: unset;
    }

    @media only screen and (max-width: 768px) {
        max-width: unset;
    }

    @media only screen and (max-width: 420px) {
        > h2 {
            font-size: 28px;
        }
    }
`;
