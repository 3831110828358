import styled from 'styled-components';

export const StyledMapWrapper = styled.div`
    width: 100%;
    background-color: transparent;
    height: 180px;

    @media only screen and (max-width: 796px) {
        width: 90%;
    }
`;
