import React from 'react';
import { useQuery } from '@tanstack/react-query';

import {
    StyledAllTrainersPage,
    StyledScrollWrapper,
    StyledElement,
    StyledImageWrapper,
    StyledTextWrapper,
    StyledTextElement,
} from './AllTrainersPage.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { getString } from '../../../strings';
import PhoneIcon from '../../atoms/UserPanelStudens/PhoneIcon';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import Loader from '../Loader/Loader';

import { aboutTrainers } from '../../../logic/requests/trainings';
import { StyledLoaderWrapper, StyledGlobalHeadingWrapper } from '../../../styles/sharedStyles';

import EnvelopeIcon from '../../atoms/Icons/EnvelopeIcon';
import UserPanelHeadingText from '../../atoms/UserPanel/UserPanelHeadingText';

const AllTrainersPage = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    const { data: trainers, status } = useQuery(['trainers', 'public', 'extended'], aboutTrainers);

    switch (status) {
        case 'loading':
            return (
                <StyledLoaderWrapper>
                    <Loader />
                </StyledLoaderWrapper>
            );
        case 'error':
            return null;
        case 'success':
            break;
        default:
            return null;
    }

    return (
        <StyledAllTrainersPage>
            <StyledGlobalHeadingWrapper>
                <UserPanelHeadingText text={`${getString('User_panel_trainers_trenerzy')}:`} />
            </StyledGlobalHeadingWrapper>
            <StyledScrollWrapper isscrollbar={isScrollBar} ref={scrollElement}>
                {trainers.map(({ id, firstname, lastname, has_avatar, phone, email }) => (
                    <StyledElement $hasAvatar={has_avatar} $hasData={phone || email}>
                        <StyledText
                            as="h3"
                            hasdeclaredfontsize="30px"
                            hasdeclaredfontweight="700"
                            hasdeclaredlineheight="1.4em"
                            hasdeclaredtextalign="center"
                            hasdeclaredtexttransform="uppercase"
                        >
                            {firstname} {lastname}
                        </StyledText>
                        {has_avatar ? (
                            <StyledImageWrapper>
                                <img src={`/api/trainings/trainers/avatar/${id}`} alt="avatar" />
                            </StyledImageWrapper>
                        ) : null}
                        {phone || email ? (
                            <StyledTextWrapper>
                                {phone ? (
                                    <StyledTextElement $hasAvatar={has_avatar} $isFirst>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredlineheight="1.3em"
                                            hasdeclaredfontcolor="#6786be"
                                        >
                                            {getString('User_panel_trainers_telefon')}:
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.3em"
                                        >
                                            <a href={`tel:${phone}`}>
                                                {phone}
                                                <span>
                                                    <PhoneIcon />
                                                </span>
                                            </a>
                                        </StyledText>
                                    </StyledTextElement>
                                ) : null}
                                {email ? (
                                    <StyledTextElement $hasAvatar={has_avatar}>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredtexttransform="uppercase"
                                            hasdeclaredlineheight="1.3em"
                                            hasdeclaredfontcolor="#6786be"
                                        >
                                            {getString('User_panel_trainers_email')}:
                                        </StyledText>
                                        <StyledText
                                            hasdeclaredfontsize="20px"
                                            hasdeclaredfontweight="600"
                                            hasdeclaredlineheight="1.3em"
                                        >
                                            <a href={`mailto:${email}`}>
                                                {email}
                                                <span>
                                                    <EnvelopeIcon />
                                                </span>
                                            </a>
                                        </StyledText>
                                    </StyledTextElement>
                                ) : null}
                            </StyledTextWrapper>
                        ) : null}
                    </StyledElement>
                ))}
            </StyledScrollWrapper>
        </StyledAllTrainersPage>
    );
};

export default AllTrainersPage;
