import React from 'react';

import { getString } from '../../../strings';
import { StyledUPopupCloseButton } from '../../atoms/Payments/StyledUPopupCloseButton';
import { StyledPaymentsPopup } from '../../atoms/Payments/StyledPaymentsPopup';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledButtonsWrapper } from '../../atoms/Payments/StyledButtonsWrapper';

const PaymentsPopup = ({
    title,
    onClose,
    onCancel,
    header,
    closeTag,
    cancelTag,
    children,
    columnReverse,
}) => (
    <StyledPaymentsPopup $columnReverse={columnReverse} $isChildren={children}>
        {children ? (
            <>{children}</>
        ) : (
            <>
                <StyledButtonsWrapper>
                    {cancelTag ? <StyledButton onClick={onCancel}>{cancelTag}</StyledButton> : null}
                    <StyledUPopupCloseButton onClick={onClose}>
                        <StyledText
                            hasdeclaredfontsize="18px"
                            hasdeclaredtextalign="center"
                            hasdeclaredfontweight="700"
                            hasdeclaredfontcolor="#fff"
                            hascursor="pointer"
                        >
                            {closeTag ? closeTag : getString('payments_paymentsPopup_zamknij')}
                        </StyledText>
                    </StyledUPopupCloseButton>
                </StyledButtonsWrapper>
                <StyledText
                    hasdeclaredfontsize="18px"
                    hasdeclaredpadding={header ? '30px 20px 20px 20px' : '50px 20px 50px 20px'}
                    hasdeclaredlineheight="1.4em"
                    hasdeclaredtextalign="center"
                >
                    {title}
                </StyledText>
                {header ? (
                    <StyledText
                        hasdeclaredfontsize="37px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredpadding="20px 20px 0 20px"
                    >
                        {header}
                    </StyledText>
                ) : null}
            </>
        )}
    </StyledPaymentsPopup>
);

export default PaymentsPopup;
