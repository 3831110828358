import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import EmployeeDataCostElement from '../EmployeeDataCostElement/EmployeeDataCostElement';
import EmployeeTableDataElement from '../EmployeeTableDataElement/EmployeeTableDataElement';

import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import {
    StyledUserPanelEmployeeDataElement,
    StyledElement,
    StyledHeader,
    StyledHeaderElement,
    StyledLeftBottomBtn,
    StyledForm,
} from './UserPanelEmployeeDataElement.styles';
import { StyledText } from '../../atoms/Text/StyledText';
import { StyledHeading, StyledArrow, StyledInputWrapper } from '../../../styles/sharedStyles';
import { StyledHeadingCheckbox } from '../../atoms/SettlementsDoneElement/StyledHeadingCheckbox';
import { StyledInputsDivWrapper } from '../../atoms/StyledInputsDivWrapper/StyledInputsDivWrapper';
import { StyledButton } from '../../atoms/Button/StyledButton';
import { StyledButtonsWrapper } from '../../atoms/UserPanelParent/StyledButtonsWrapper';
import { StyledCustomFile } from '../UserPanelReqElementTrainer/UserPanelReqElementTrainer.styles';

import { getString } from '../../../strings';
import useScrollBar from '../../../logic/hooks/useScrollBar';
import PaymentsPopup from '../../organisms/Payments/PaymentsPopup';

const UserPanelEmployeeDataElement = ({
    trainer: {
        id,
        firstname,
        lastname,
        current_contract,
        price,
        bonus_hours,
        bonus_amount,
        subjects,
    },
}) => {
    const { scrollElement, isScrollBar } = useScrollBar(false, true);
    const { scrollElement: scrollElement2, isScrollBar: isScrollBar2 } = useScrollBar(false, true);

    const [isOpen, toggleIsOpen] = useToggle(false);
    const [isOpenForm, setIsOpenForm] = useState(false);

    const [file, setFile] = useState(true);
    const [fileIsSend, setFileIsSend] = useState(false); // todo payment
    const [openTable, setOpenTable] = useState(false);

    return (
        <>
            {fileIsSend ? (
                <PaymentsPopup
                    onClose={() => setFileIsSend(false)}
                    title="Poprawnie przesłano lub nie"
                />
            ) : null}
            <StyledUserPanelEmployeeDataElement isopen={isOpen}>
                <StyledHeading onClick={toggleIsOpen}>
                    <StyledText
                        hasdeclaredfontsize="22px"
                        hasdeclaredfontweight="700"
                        hasdeclaredtextalign="center"
                        hasdeclaredtexttransform="uppercase"
                        hasdeclaredfontcolor="#6786BE"
                    >
                        {firstname} {lastname}
                    </StyledText>
                    <StyledHeadingCheckbox>
                        <StyledArrow isopen={isOpen} />
                    </StyledHeadingCheckbox>
                </StyledHeading>
                {isOpen ? (
                    <StyledElement>
                        <ScrollSync>
                            <>
                                {isOpenForm ? (
                                    <StyledForm>
                                        <StyledInputsDivWrapper>
                                            <StyledInputWrapper hasdeclaredwidth="50%">
                                                <label htmlFor="elo">
                                                    <StyledText
                                                        hasdeclaredfontsize="20px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_data_element_przedmiot'
                                                        )}
                                                    </StyledText>
                                                </label>
                                                <input type="text" />
                                                <StyledText
                                                    as="h4"
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontcolor="#F74017"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    error
                                                </StyledText>
                                            </StyledInputWrapper>
                                            <StyledInputWrapper hasdeclaredwidth="50%">
                                                <label htmlFor="elo">
                                                    <StyledText
                                                        hasdeclaredfontsize="20px"
                                                        hasdeclaredtextalign="center"
                                                        hasdeclaredpadding="8px 0 8px 0"
                                                        hasdeclaredfontweight="600"
                                                    >
                                                        {getString(
                                                            'User_panel_employee_data_element_kwota'
                                                        )}
                                                    </StyledText>
                                                </label>
                                                <input type="text" />
                                                <StyledText
                                                    as="h4"
                                                    hasdeclaredfontsize="16px"
                                                    hasdeclaredfontcolor="#F74017"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredfontweight="700"
                                                    hasdeclaredtexttransform="uppercase"
                                                    hasdeclaredlineheight="1.4em"
                                                >
                                                    error
                                                </StyledText>
                                            </StyledInputWrapper>
                                        </StyledInputsDivWrapper>
                                        <StyledInputsButtonsWrapper hasmargin="18px 0 0 0">
                                            <input
                                                type="submit"
                                                value={getString(
                                                    'User_panel_employee_data_element_zapisz'
                                                )}
                                            />
                                            <input
                                                type="reset"
                                                value={getString(
                                                    'User_panel_employee_data_element_anuluj'
                                                )}
                                            />
                                        </StyledInputsButtonsWrapper>
                                    </StyledForm>
                                ) : (
                                    <>
                                        <ScrollSyncPane>
                                            <StyledHeader
                                                isscrollbar={isScrollBar}
                                                ref={scrollElement}
                                                hasalignself="flex-start"
                                            >
                                                <div>
                                                    <StyledHeaderElement>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_employee_konfiguracja_stawka_godzinowa2'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                    <StyledHeaderElement>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_employee_konfiguracja_wysokosc_premii2'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                    <StyledHeaderElement>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_employee_konfiguracja_godzin_do_premii2'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                    <StyledHeaderElement>
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_employess_conf_element'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                </div>
                                            </StyledHeader>
                                            <EmployeeDataCostElement
                                                price={price}
                                                bonus_hours={bonus_hours}
                                                bonus_amount={bonus_amount}
                                                contract_type={
                                                    current_contract?.type ??
                                                    getString('contract__none')
                                                }
                                            />
                                            <StyledButtonsWrapper bottomsize>
                                                <StyledButton
                                                    hasdeclaredbgcolor={
                                                        openTable ? '#ee745c' : '#6786be'
                                                    }
                                                    hasdeclaredpadding="0 28px"
                                                    hasdeclaredfontcolor="#fff"
                                                    hasdeclaredborderradius="25px"
                                                    hasdeclaredfontweight="600"
                                                    hasdeclaredtextalign="center"
                                                    hasdeclaredcursor="pointer"
                                                    hasdeclaredfontsize="18px"
                                                    hasdeclaredtexttransform="uppercase"
                                                    onClick={() => setOpenTable(!openTable)}
                                                >
                                                    {openTable
                                                        ? getString(
                                                              'User_panel_employee_data_element_ukryj'
                                                          )
                                                        : getString(
                                                              'User_panel_employee_data_element_spersonalizowane_przedmioty'
                                                          )}
                                                </StyledButton>
                                                <StyledCustomFile
                                                    $hasDeclaredWidth="50%"
                                                    $hasDeclaredHeight="50px"
                                                    file={file}
                                                >
                                                    <label htmlFor="upload">
                                                        {file
                                                            ? getString(
                                                                  'User_panel_employee_data_element_edytuj_umowe'
                                                              )
                                                            : getString(
                                                                  'User_panel_employee_data_element_dodaj_umowe'
                                                              )}
                                                    </label>
                                                    <input id="upload" type="file" />
                                                </StyledCustomFile>
                                            </StyledButtonsWrapper>
                                        </ScrollSyncPane>
                                    </>
                                )}
                            </>
                        </ScrollSync>
                        <ScrollSync>
                            <>
                                {!isOpenForm && openTable ? (
                                    <>
                                        <ScrollSyncPane>
                                            <StyledHeader
                                                isscrollbar={isScrollBar2}
                                                ref={scrollElement2}
                                                hasalignself="flex-start"
                                            >
                                                <div>
                                                    <StyledHeaderElement $hasWidth="50%">
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_employee_data_element_przedmiot2'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                    <StyledHeaderElement $hasWidth="50%">
                                                        <StyledText
                                                            hasdeclaredfontsize="16px"
                                                            hasdeclaredfontweight="700"
                                                            hasdeclaredtextalign="center"
                                                            hasdeclaredlineheight="1.4em"
                                                        >
                                                            {getString(
                                                                'User_panel_employee_data_element_kwota_trenera'
                                                            )}
                                                        </StyledText>
                                                    </StyledHeaderElement>
                                                </div>
                                            </StyledHeader>
                                            {subjects.map(({ subject, price }) => (
                                                <EmployeeTableDataElement
                                                    subject={subject}
                                                    price={price}
                                                />
                                            ))}
                                        </ScrollSyncPane>
                                    </>
                                ) : null}
                                <StyledLeftBottomBtn onClick={() => setIsOpenForm(!isOpenForm)}>
                                    <svg
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M33.0105 14.2474L21.7527 14.2474L21.7527 2.98966C21.7527 1.60824 20.6324 0.487935 19.251 0.487936L16.7493 0.487936C15.3679 0.487937 14.2476 1.60824 14.2476 2.98966L14.2476 14.2474L2.98982 14.2474C1.6084 14.2474 0.488099 15.3677 0.488099 16.7491V19.2509C0.488099 20.6323 1.6084 21.7526 2.98982 21.7526L14.2476 21.7526L14.2476 33.0103C14.2476 34.3918 15.3679 35.5121 16.7493 35.5121L19.251 35.5121C20.6324 35.5121 21.7527 34.3918 21.7527 33.0103L21.7527 21.7526L33.0105 21.7526C34.3919 21.7526 35.5122 20.6323 35.5122 19.2509V16.7491C35.5122 15.3677 34.3919 14.2474 33.0105 14.2474Z"
                                            fill="white"
                                        />
                                    </svg>
                                </StyledLeftBottomBtn>
                            </>
                        </ScrollSync>
                    </StyledElement>
                ) : null}
            </StyledUserPanelEmployeeDataElement>
        </>
    );
};

export default UserPanelEmployeeDataElement;
