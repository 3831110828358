import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as Icon } from '../../../images/koperta.svg';

import mobile from '../../../images/mobile/contactMobileShape.png';

import shape from '../../../images/LoginPage/Vector.png';

export const StyledContactForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding-bottom: 20px;

        input {
            height: 45px;
            width: 320px;
            border-radius: 25px;
            margin-top: 0;
            margin-bottom: 0;
            border: 3px solid #6d85ba;
            padding: 0 18px;
            font-size: 20px;
            font-family: 'Poppins';
            outline: none;
        }

        > textarea {
            border-radius: 25px;
            resize: none;
            height: 260px;
            border: 3px solid #6d85ba;
            outline: none;
            width: 302px;
            margin-top: 12px;
            font-size: 20px;
            font-family: 'Poppins';
            padding: 18px;
        }
    }

    @media only screen and (max-width: 370px) {
        > div {
            input {
                width: 260px;
            }

            > textarea {
                width: 260px;
            }
        }
    }
`;

export const StyledContactFormWrapper = styled.div`
    flex-direction: column;
    align-items: center;
`;

export const StyledContactPage = styled(motion.section)`
    background-color: #6d85ba;
    width: 100%;
    height: 100vh;
    min-height: 740px;
    position: relative;
    overflow: hidden;
    display: flex;
    padding-top: 40px;

    @media only screen and (max-width: 1074px) {
        padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? '250px 0 0 0' : '60px 0 0 0')};
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        background-color: #f9ec4b;
        min-height: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? '70vh' : '100vh')};
        height: 100%;
        overflow: visible;

        > svg {
            display: none;
        }
    }

    @media only screen and (max-width: 767px) {
        background-color: #f9ec4b;
    }

    @media only screen and (max-width: 601px) {
        padding: ${({ hasdeclaredpadding }) =>
            hasdeclaredpadding ? '250px 0 0 0' : '120px 0 0 0'};
    }

    @media only screen and (max-width: 504px) {
        padding: ${({ hasdeclaredpadding }) =>
            hasdeclaredpadding ? '250px 0 0 0' : '140px 0 0 0'};
    }
`;

export const StyledEmailSvg = styled(Icon)`
    width: 140px;
    stroke: #fff;
    max-height: 70px;
    fill: transparent;
`;

export const StyledHeaderText = styled.div`
    @media only screen and (max-width: 902px) {
        p {
            padding-bottom: 22px;
        }
    }

    @media only screen and (max-width: 767px) {
        p {
            color: #000;
        }
    }
`;

export const StyledLeftWrapper = styled(motion.div)`
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    padding-bottom: 40px;

    > button {
        transition: background-color 250ms linear;

        &:hover {
            background-color: #602b26;
        }
    }

    a {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    @media only screen and (max-width: 1223px) {
        padding-top: 80px;
    }

    @media only screen and (max-width: 1074px) {
        padding-top: 150px;
        margin-top: 0;
        width: 100%;
        height: 100%;
        background-image: url(${mobile});
        background-size: cover;
    }

    @media only screen and (max-width: 502px) {
        max-width: 100%;
        background-size: fill;
        background-position: -80px 0;

        > button {
            width: 90%;
        }

        > a {
            width: 100%;
            background: #6786be;
        }
    }
`;

export const StyledMobileContactCube = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    > svg {
        width: 220px;
        height: 220px;
        left: 35%;
        transform: translateX(-50%) rotate(45deg);

        &:last-child {
            left: 70%;
            width: 280px;
            height: 280px;
        }
    }
`;

export const StyledRightFAQWrapper = styled(motion.div)`
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    position: relative;

    @media only screen and (max-width: 1074px) {
        width: 100%;
        height: auto;
    }
`;

export const StyledRightVector = styled.div`
    background: url(${shape});
    background-size: cover;
    height: 100%;
    width: 58%;
    position: absolute;
    right: 0;
    top: 0;
`;

export const StyledRightWrapper = styled(motion.div)`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;

    @media only screen and (max-width: 767px) {
        > h2 {
            color: #000;
        }
    }
`;

export const StyledSvgAndTextWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    svg {
        max-height: 50px;
        width: 52px;
        stroke: #fff;
    }

    @media only screen and (max-width: 319px) {
        p {
            font-size: 26px;
        }
    }
`;

export const StyledTextWrapper = styled.div`
    background-color: #fff;
    padding: ${({ hasdeclaredpadding }) => (hasdeclaredpadding ? hasdeclaredpadding : '0')};
    border-radius: 50px;
    width: 370px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1074px) {
        width: 330px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        h3,
        p {
            font-size: 22px;
        }
    }

    @media only screen and (max-width: 437px) {
        padding: 8px 24px;
        width: 300px;

        h3,
        p {
            font-size: 22px;
        }
    }

    @media only screen and (max-width: 316px) {
        width: 260px;
        height: 40px;

        h3,
        p {
            font-size: 14px;
        }
    }
`;
