import styled from 'styled-components';

export const StyledSendFileLabel = styled.label`
    width: 82px;
    height: 82px;
    background: ${({ issend }) => (issend ? 'transparent' : '#fff')};
    border: 2px solid ${({ issend }) => (issend ? ' transparent' : '#6786BE')};
    border-radius: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transform: translateY(14px);
    transition: border 250ms;

    svg {
        width: 100%;
        height: 100%;
        transform: translateX(-2px);
        fill: #6786be;
        transition: fill 250ms;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 21px;
        object-fit: cover;
        border: ${({ issend }) => (issend ? '2px solid #6786BE' : 'none')};
    }
`;
