import styled from 'styled-components';
import { scrollRedStyles } from '../../../styles/sharedStyles';

export const StyledRightPanel = styled.div`
    width: calc(30% - 20px);
    min-width: calc(30% - 20px);
    height: 80vh;
    min-height: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: ${({ isscrollbar }) => (isscrollbar ? 'flex-start' : 'center')};
    padding-top: 20px;
    overflow-y: scroll;
    position: relative;
    align-self: flex-start;
    margin-top: 120px;
    margin-right: 20px;

    ${scrollRedStyles};

    button {
        transition: background-color 250ms linear;

        &:hover {
            background-color: #602b26;
        }
    }

    @media only screen and (max-width: 1150px) {
        width: 42%;
        min-width: 42%;
    }

    @media only screen and (max-width: 1114px) {
        width: 40%;
        min-width: 40%;
    }

    @media only screen and (max-width: 926px) {
        width: 100%;
        height: auto;
        padding-top: 190px;
        margin-top: 0;
        margin-right: 20px;
        overflow: visible;
    }
`;
