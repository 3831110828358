import styled from 'styled-components';

export const StyledSeriesMenu = styled.div`
    width: 25%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 1204px) {
        width: 30%;
    }

    @media only screen and (max-width: 767px) {
        margin-bottom: ${({ ismobile }) => (ismobile ? '40px' : 'none')};
        width: 100%;
        height: auto;
        padding-right: 0;
    }
`;
