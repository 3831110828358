import styled from 'styled-components';

export const StyledSeriesResultsIcon = styled.div`
    min-height: 50px;
    min-width: 50px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
        width: 30px;
        height: 30px;
        fill: #000;
    }
`;
