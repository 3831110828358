import backendRequest from './index.js';

export const listStudents = (former) => backendRequest('GET', '/students/list?former=' + former);

export const listParents = () => backendRequest('GET', '/students/list-parents');

export const listUsers = () => backendRequest('GET', '/students/list-users');

export const listRoles = () => backendRequest('GET', '/students/list-roles');

export const listSubjects = () => backendRequest('GET', '/students/list-subjects');

export const addSubject = (data) => backendRequest('POST', `/students/add-subject`, data);

export const editSubject = (id, data) =>
    backendRequest('POST', `/students/edit-subject/${id}`, data);

export const deleteSubject = (id) => backendRequest('POST', `/students/delete-subject/${id}`);

export const listTrainers = () => backendRequest('GET', '/students/list-trainers');

export const addHours = (data) => backendRequest('POST', '/students/add-hours', data);

export const editHours = (id, data) =>
    backendRequest('POST', '/students/edit-hours', { id, ...data });

export const deleteHours = (id, reason) =>
    backendRequest(reason ? 'POST' : 'GET', `/students/delete-hours/${id}`, { reason });

export const listPay = () => backendRequest('GET', '/students/list-pay');

export const listAllPay = (month) =>
    backendRequest('POST', '/students/list-all-pay', month ? { group_by: month } : undefined);

export const listDues = () => backendRequest('GET', '/students/list-dues');

export const listHours = () => backendRequest('GET', '/students/list-hours');

export const markHourAsPaid = (id) => backendRequest('POST', `/students/mark-hour-as-paid`, { id });

export const paySalary = (data) => backendRequest('POST', '/students/pay-salary', data);

export const payDues = (data) => backendRequest('POST', '/students/pay-dues', data);

export const togglePreference = ({ id, type }) =>
    backendRequest('POST', '/students/toggle-preference', { id, type });

export const updateStudent = (
    id,
    {
        student_firstname,
        student_lastname,
        student_phone,
        parent_firstname,
        parent_lastname,
        parent_phone,
        parent_email,
    }
) =>
    backendRequest('POST', `/students/update/${id}`, {
        student_firstname,
        student_lastname,
        student_phone,
        parent_firstname,
        parent_lastname,
        parent_phone,
        parent_email,
    });

export const getPayData = () => backendRequest('GET', '/students/get-pay-data');

export const assignRole = (user, role) =>
    backendRequest('POST', '/students/assign-role', { user, role });

export const unassignRole = (user, role) =>
    backendRequest('POST', '/students/unassign-role', { user, role });

export const assignParent = (student, parent) =>
    backendRequest('POST', `/students/assign-parent/${student}`, { id: parent });

export const unassignParent = (id) => backendRequest('POST', `/students/unassign-parent/${id}`);

export const addNonUserParent = (id, { firstname, lastname, email, phone }) =>
    backendRequest('POST', `/students/add-parent/${id}`, {
        firstname,
        lastname,
        email,
        phone,
    });

export const listHoursSummary = () => backendRequest('GET', '/students/list-hours-summary');

export const editSummary = (id, data) =>
    backendRequest('POST', `/students/edit-summary/${id}`, data);

export const addStudentPreference = ({ student, trainer, preference }) =>
    backendRequest('POST', '/students/add-preference', { student, trainer, preference });

export const deleteStudentPreference = (student, trainer) =>
    backendRequest('POST', '/students/delete-preference', { student, trainer });

export const addAddition = ({ trainer, amount, date, description }) =>
    backendRequest('POST', '/students/add-addition', { trainer, amount, date, description });

export const editAddition = (id, data) =>
    backendRequest('POST', `/students/edit-addition/${id}`, data);

export const deleteAddition = (id, data) =>
    backendRequest('POST', `/students/delete-addition/${id}`, data);

export const listAdditions = (group_by) =>
    backendRequest('GET', '/students/list-additions' + (group_by ? `?group_by=${group_by}` : ''));
