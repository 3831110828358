import styled from 'styled-components';

export const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 10px;

    > textarea {
        border-radius: 25px;
        resize: none;
        height: 260px;
        border: 3px solid #6d85ba;
        outline: none;
        width: 100%;
        margin-top: 12px;
        font-size: 20px;
        font-family: 'Poppins';
        padding: 18px;
    }

    > button {
        height: 50px;
    }
`;

export const StyledCustomFile = styled.div`
    width: ${({ $hasDeclaredWidth }) => ($hasDeclaredWidth ? $hasDeclaredWidth : '100%')};
    height: ${({ $hasDeclaredHeight }) => ($hasDeclaredHeight ? $hasDeclaredHeight : '70px')};

    > input {
        opacity: 0;
    }

    > label {
        display: flex;
        width: 100%;
        height: 100%;
        font-family: 'Poppins';
        background-color: ${({ file }) => (file ? '#6786be' : '#ee745c')};
        border: 6px solid ${({ file }) => (file ? '#6786be' : '#ee745c')};
        border-radius: 25px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 515px) {
        width: 100%;
    }
`;
