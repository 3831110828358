import styled from 'styled-components';

export const StyledTournamentContentWrapper = styled.div`
    width: 65%;

    @media only screen and (max-width: 922px) {
        margin-top: 20px;
        width: 100%;
    }
`;
