import styled from 'styled-components';

export const StyledQuestionsForm = styled.form`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        padding-bottom: 20px;

        input {
            height: 45px;
            width: 320px;
            border-radius: 25px;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 18px;
            font-size: 20px;
            font-family: 'Poppins';
            outline: none;
        }

        > textarea {
            border-radius: 25px;
            border: 3px solid ${({ error }) => (error ? '#F74017' : '#3474E8')};
            resize: none;
            height: 260px;
            outline: none;
            width: 302px;
            margin-top: 12px;
            font-size: 20px;
            font-family: 'Poppins';
            padding: 18px;
        }
    }

    @media only screen and (max-width: 370px) {
        > div {
            input {
                width: 260px;
            }

            > textarea {
                width: 260px;
            }
        }
    }
`;
