import { StyledText } from '../../atoms/Text/StyledText';
import { StyledInputWrapper } from '../../../styles/sharedStyles';
import { getString } from '../../../strings';
import { Field, Formik } from 'formik';
import { useMemo } from 'react';
import * as yup from 'yup';
import { StyledInputsButtonsWrapper } from '../../atoms/TrackHours/StyledInputsButtonsWrapper';
import { StyledUserPanelAnnouncementsElement } from '../../atoms/UserPanelAnnouncements/StyledUserPanelAnnouncementsElement';

const AnnouncementForm = ({ initialValues, onSubmit, onReset }) => {
    const schema = useMemo(
        () =>
            yup.object({
                title: yup.string().required(getString('User_panel_announcements_title_is_required')),
                text: yup.string().required(getString('User_panel_announcements_text_is_required')),
            }),
        []
    );
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            onReset={onReset}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ errors }) => (
                <StyledUserPanelAnnouncementsElement>
                    <StyledInputWrapper hasdeclaredwidth="100%" error={errors.title}>
                        <label htmlFor="title">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('User_panel_announcements_title')}
                            </StyledText>
                        </label>
                        <Field as="input" type="text" name="title" />
                        <StyledText
                            as="h4"
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredtextalign="center"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.4em"
                        >
                            {errors.title}
                        </StyledText>
                    </StyledInputWrapper>
                    <StyledInputWrapper hasdeclaredwidth="100%" error={errors.text}>
                        <label htmlFor="text">
                            <StyledText
                                hasdeclaredfontsize="20px"
                                hasdeclaredtextalign="center"
                                hasdeclaredpadding="8px 0 8px 0"
                                hasdeclaredfontweight="600"
                            >
                                {getString('User_panel_announcements_text')}
                            </StyledText>
                        </label>
                        <Field as="textarea" type="text" name="text" />
                        <StyledText
                            as="h4"
                            hasdeclaredfontsize="16px"
                            hasdeclaredfontcolor="#F74017"
                            hasdeclaredtextalign="center"
                            hasdeclaredfontweight="700"
                            hasdeclaredtexttransform="uppercase"
                            hasdeclaredlineheight="1.4em"
                        >
                            {errors.text}
                        </StyledText>
                    </StyledInputWrapper>
                    <StyledInputsButtonsWrapper>
                        <input type="submit" value={getString('User_panel_addition_zapisz')} />
                        <input type="reset" value={getString('User_panel_addition_anuluj')} />
                    </StyledInputsButtonsWrapper>
                </StyledUserPanelAnnouncementsElement>
            )}
        </Formik>
    );
};

export default AnnouncementForm;
