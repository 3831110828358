import React, { useEffect } from 'react';
import { sessionService } from 'redux-react-session';
import { useNavigate } from 'react-router-dom';
import { logout as logoutServerSide } from '../../logic/requests/users';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(
        () =>
            (async () => {
                let ret;
                try {
                    ret = await logoutServerSide();
                    console.warn('logged out server-side', ret);
                    // TODO: redux-react-session chyba może coś rzucić - obsługa błędów?
                    await sessionService.deleteUser();
                } catch (err) {
                    switch (err?.data?.code) {
                        case 'no_login':
                            // już wylogowano, wylogowujemy na kliencie
                            await sessionService.deleteUser();
                            break;
                        default:
                            break;
                    }
                }

                navigate('/', { replace: true });
            })(),
        []
    );

    return <></>;
};

export default Logout;
