import styled from 'styled-components';
import { scrollBlueStyles } from '../../../styles/sharedStyles';

export const StyledPreferencesScrollWrapper = styled.div`
    margin-top: 8px;
    overflow-y: scroll;
    height: calc(100% - 120px);
    padding-right: 15px;

    ${scrollBlueStyles};

    @media only screen and (max-width: 926px) {
        padding: 0 20px;
        overflow-y: visible;
        height: auto;
    }
`;
