import React from 'react';

import {
    StyledAboutUs,
    StyledAboutUsImageWrapper,
    StyledAboutUsImageWrapperImage,
} from './AboutUs.styles';
import { StyledText } from '../../atoms/Text/StyledText';

import { getString } from '../../../strings';

import pioter1 from '../../../images/pioter/pioter1.jpeg';
import pioter2 from '../../../images/pioter/pioter2.jpeg';
import pioter3 from '../../../images/pioter/pioter3.jpeg';
import pioter4 from '../../../images/pioter/pioter4.jpeg';

import useScrollBar from '../../../logic/hooks/useScrollBar';

import { StyledAboutUsElement } from '../../../styles/sharedStyles';

const AboutUs = () => {
    const { scrollElement, isScrollBar } = useScrollBar();

    return (
        <StyledAboutUs
            isscrollbar={isScrollBar}
            ref={scrollElement}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <StyledAboutUsElement>
                <StyledText
                    hasdeclaredfontsize="34px"
                    hasdeclaredfontweight="700"
                    hasdeclaredtexttransform="uppercase"
                    hasdeclaredpadding="0 0 10px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="center"
                    as="h2"
                >
                    {getString('Trainings_about_us_czym_jest_cube')}
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 0 20px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="justify"
                >
                    <br />
                    {getString('Trainings_about_us_caly_opis1')}
                    <br />
                    <br />
                    {getString('Trainings_about_us_caly_opis2')}
                    <br />
                    <br />
                    {getString('Trainings_about_us_caly_opis3')}
                    <br />
                    <br />
                    {getString('Trainings_about_us_caly_opis4')}
                    <br />
                    <br />
                </StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="right"
                >{`"${getString('Trainings_about_us_przekonaj_sie_sam')}"`}</StyledText>
                <StyledText
                    hasdeclaredfontsize="16px"
                    hasdeclaredfontweight="600"
                    hasdeclaredpadding="0 0 20px 0"
                    hasdeclaredlineheight="1.3em"
                    hasdeclaredtextalign="right"
                >
                    {getString('Trainings_about_us_piotr_tokarski')}
                </StyledText>
                <StyledAboutUsImageWrapper>
                    <StyledAboutUsImageWrapperImage>
                        <img src={pioter4} alt="Piotr" />
                    </StyledAboutUsImageWrapperImage>
                    <StyledAboutUsImageWrapperImage>
                        <img src={pioter2} alt="Piotr" />
                    </StyledAboutUsImageWrapperImage>
                </StyledAboutUsImageWrapper>
                <StyledAboutUsImageWrapper>
                    <StyledAboutUsImageWrapperImage hasdeclaredheight="auto">
                        <img src={pioter1} alt="Piotr" />
                    </StyledAboutUsImageWrapperImage>
                    <StyledAboutUsImageWrapperImage hasdeclaredheight="auto">
                        <img src={pioter3} alt="Piotr" />
                    </StyledAboutUsImageWrapperImage>
                </StyledAboutUsImageWrapper>
            </StyledAboutUsElement>
        </StyledAboutUs>
    );
};

export default AboutUs;
