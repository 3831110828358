const customLabelsGB = require("./customLabelsGB.json"),
	customLabelsCZ = require("./customLabelsCZ.json"),
	customLabelsPL = require("./customLabelsPL.json"),
	customLabelsUA = require("./customLabelsUA.json")
;

const customLabels = {
	"pl": customLabelsPL,
	"gb": customLabelsGB,
	"ua": customLabelsUA,
	"cz": customLabelsCZ
};

module.exports = customLabels;
