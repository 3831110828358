import styled from 'styled-components';

export const StyledSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: 10px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;
