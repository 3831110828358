import styled from 'styled-components';

export const StyledIconsMobileResultsWrapper = styled.div`
    width: 100%;

    > div {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding: 8px 15px;
        flex-wrap: wrap;
        gap: 8px;
    }

    @media only screen and (max-width: 462px) {
        > p {
            font-size: 18px;
        }
    }
`;
