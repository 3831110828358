import React from 'react';
import { getString } from '../../../strings';
import { StyledIconsMobileResults } from './StyledIconsMobileResults';
import { StyledText } from '../../atoms/Text/StyledText';

import { ReactComponent as ImageCompetition222 } from '../../../images/cubes/222.svg';
import { ReactComponent as ImageCompetition333 } from '../../../images/cubes/333.svg';
import { ReactComponent as ImageCompetition444 } from '../../../images/cubes/444.svg';
import { ReactComponent as ImageCompetition555 } from '../../../images/cubes/555.svg';
import { ReactComponent as ImageCompetition666 } from '../../../images/cubes/666.svg';
import { ReactComponent as ImageCompetition777 } from '../../../images/cubes/777.svg';
import { ReactComponent as ImageCompetitionSkewb } from '../../../images/cubes/skewb.svg';
import { ReactComponent as ImageCompetitionSq1 } from '../../../images/cubes/sq1.svg';
import { ReactComponent as ImageCompetitionPyram } from '../../../images/cubes/pyram.svg';
import { ReactComponent as ImageCompetitionMinx } from '../../../images/cubes/minx.svg';
import { ReactComponent as ImageCompetition333oh } from '../../../images/cubes/333oh.svg';
import { ReactComponent as ImageCompetition333bf } from '../../../images/cubes/333bf.svg';
import { ReactComponent as ImageCompetition333fm } from '../../../images/cubes/333fm.svg';
import { ReactComponent as ImageCompetition333mbf } from '../../../images/cubes/333mbf.svg';
import { ReactComponent as ImageCompetitionClock } from '../../../images/cubes/clock.svg';
import { ReactComponent as ImageCompetition444bf } from '../../../images/cubes/444bf.svg';
import { ReactComponent as ImageCompetition555bf } from '../../../images/cubes/555bf.svg';

const IconsMobileResults = ({ result, icon }) => {
    return (
        <StyledIconsMobileResults>
            {(() => {
                switch (icon) {
                    case '222':
                        return <ImageCompetition222 />;
                    case '555':
                        return <ImageCompetition555 />;
                    case '333':
                        return <ImageCompetition333 />;
                    case '444':
                        return <ImageCompetition444 />;
                    case '666':
                        return <ImageCompetition666 />;
                    case '777':
                        return <ImageCompetition777 />;
                    case 'skewb':
                        return <ImageCompetitionSkewb />;
                    case 'sq1':
                        return <ImageCompetitionSq1 />;
                    case 'pyram':
                        return <ImageCompetitionPyram />;
                    case 'minx':
                        return <ImageCompetitionMinx />;
                    case '333oh':
                        return <ImageCompetition333oh />;
                    case '333bf':
                        return <ImageCompetition333bf />;
                    case '333fm':
                        return <ImageCompetition333fm />;
                    case '333mbf':
                        return <ImageCompetition333mbf />;
                    case 'clock':
                        return <ImageCompetitionClock />;
                    case '444bf':
                        return <ImageCompetition444bf />;
                    case '555bf':
                        return <ImageCompetition555bf />;
                    case 'bonus':
                        return (
                            <StyledText
                                hasdeclaredfontsize="18px"
                                hasdeclaredfontcolor="#000"
                                hasdeclaredfontweight="700"
                                hasdeclaredtextalign="center"
                            >
                                {getString('series_iconsMobileResults_bonus')}
                            </StyledText>
                        );
                    default:
                        return <></>;
                }
            })()}
            <StyledText
                hasdeclaredfontsize="16px"
                hasdeclaredfontweight="700"
                hasdeclaredfontcolor="#000"
                hasdeclaredmargin="6px 0 0 0"
            >
                {result}
            </StyledText>
        </StyledIconsMobileResults>
    );
};

export default IconsMobileResults;
