import styled from 'styled-components';
import { Field } from 'formik';

import { ReactComponent as ButtonSvg } from '../../../images/LoginPage/arrow.svg';

import loginBackgrund from '../../../images/LoginPage/LoginBackground.png';
import loginBackgrund2 from '../../../images/LoginPage/MobileVector.png';

export const StyledInputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledButtonSvg = styled(({ isrotate, ...props }) => <ButtonSvg {...props} />)`
    height: 40px;
    width: 100%;
    transform: ${({ isrotate }) => (isrotate ? 'rotate(-180deg)' : 'none')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledLoginInput = styled(Field)`
    font-family: 'Poppins';
    font-size: 20px;
    border: 3px solid #6786be;
    border-radius: 30px;
    margin-top: 5px;
    padding: 18px 16px;
    color: #6786be;
    outline: #306efa;
    text-indent: 45px;
    position: relative;
    width: 100%;

    &::placeholder {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 26px;
        color: #ccc;
        transform: translateY(3px);
    }

    @media only screen and (max-width: 440px) {
        padding: 8px 12px;
        font-size: 16px;

        &::placeholder {
            font-size: 18px;
            transform: translateY(0);
        }
    }
`;

export const StyledLoginPageImage = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
    top: 0;
    left: 0;

    @media only screen and (max-width: 767px) {
        object-fit: cover;
    }
`;

export const StyledLoginPageShape = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    background: url(${loginBackgrund}) no-repeat;
    background-size: cover;
    background-position: center;
`;

export const StyledLoginPageShapeMobile = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(${loginBackgrund2}) no-repeat;
    background-size: 100% 100%;

    @media only screen and (max-height: 720px) and (max-width: 340px) {
        background-size: cover;
    }
`;

export const StyledRightWrapper = styled.div`
    width: 55%;
    min-height: 100vh;
    height: 100%;
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 1126px) {
        width: 60%;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
        min-height: 65vh;
    }

    @media only screen and (max-width: 430px) {
        padding-top: 20px;
    }

    @media only screen and (max-height: 720px) and (max-width: 600px) {
        min-height: 580px;
        padding-top: 50px;
    }

    @media only screen and (max-height: 720px) and (max-width: 375px) {
        min-height: 580px;
        padding-top: 0;
    }
`;
