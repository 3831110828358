import styled, { css } from 'styled-components';

const childrenStyles = css`
    display: block;
`;

const withoutChildrenStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledPaymentsPopup = styled.div`
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    background-color: #fff;
    border-radius: 25px;
    border: 8px solid #6786be;
    flex-direction: ${({ $columnReverse }) => (!$columnReverse ? 'column-reverse' : 'column')};
    z-index: 1000;
    padding-bottom: 10px;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);

    ${({ $isChildren }) => ($isChildren ? childrenStyles : withoutChildrenStyles)}

    @media only screen and (max-width: 926px) {
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media only screen and (max-width: 520px) {
        width: 98%;
    }
`;
