import styled from 'styled-components';

export const StyledParserWrapper = styled.div`
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 600;
    max-width: 700px;
    text-align: justify;
    padding: 0 20px;

    > a {
        color: #6786be;
        text-decoration: none;
    }
`;
